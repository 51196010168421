import { PUT_ADS_REQUEST, PUT_ADS_SUCCESS, PUT_ADS_FAILURE } from '../../constants/';

type StateTypes = {
  adsIsLoading: boolean,
};

const initialState: StateTypes = {
  adsIsLoading: false,
};

export const advertisement = (state = initialState, action) => {
  switch (action.type) {
    case PUT_ADS_REQUEST:
      return {
        ...state,
        adsIsLoading: true,
      };
    case PUT_ADS_SUCCESS:
      return {
        ...state,
        adsIsLoading: false,
      };
    case PUT_ADS_FAILURE:
      return {
        ...state,
        adsIsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

import React from 'react';
import { withConfig } from '../../config';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { BackToLoginProps } from '../model';
import Button from 'components/UI/Button';
import { getConfig } from 'config/config';

const { theme } = getConfig()
const styles = createStyles({
  container: {
    width: '100%',
    margin: '40px 0 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    fontSize: 15,
    marginLeft: 5,
    fontFamily: 'Poppins',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginBottom: 15
  },
});

interface Props extends WithStyles<typeof styles>, BackToLoginProps { }

const backToLogin: React.FC<Props> = ({ classes, label, buttonText, type, ...props }) => (
  <div className={classes.container} style={{ flexDirection: type === 'button' ? 'column' : 'row' }}>
    <span className={classes.text} style={{ color: theme.TEXT_PRIMARY }}>
      {label}
    </span>
    {type === 'button' ?
      <Button  {...props} label={buttonText} />
      :
      <span className={classes.text} style={{ color: theme.BUTTON_PRIMARY }} {...props}>
        {buttonText}
      </span>
    }

  </div>
);

export default withStyles(styles)(backToLogin);

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../../../config';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getUserFieldProps, imagePath } from '../../../../utilities';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ProfileItemRedirectTypes } from '../../../ProfileDetails/model';
import { useLanguage } from 'languages/languageContext';
import { sendRequestRequest } from 'store/actions/requests';
import { useAppDispatch, useAppSelector } from 'store';
import { requestMessage } from 'utilities/messages';
import Loader from 'components/UI/Loader';
const defaultAvatar = require('../../../../assets/default_avatar.png');

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 40px',
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
    border: '1px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  heading: {
    // maxWidth: 220
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  corps: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
  },
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 40,
    height: 40,
    overflow: 'hidden',
    borderRadius: 20,
    border: '1px solid red',
  },
  addIconWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: -5,
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  status: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: 5,
  },
  '@media(max-width: 650px)': {
    container: {
      padding: '5px 10px',
    },
    name: {
      fontSize: 14,
    },
    corps: {
      fontSize: 12,
    },
    nameWrapper: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    heading: {
      maxWidth: 220,
    },
    status: {
      fontSize: 14,
      marginLeft: 0,
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  user: any;
  filter: string;
  disactivateBackHandler?: () => void;
}

const UserItem: React.FC<Props> = ({ theme, classes, user, filter, history, disactivateBackHandler }) => {
  const dispatch = useAppDispatch();
  const { profileDetails } = useLanguage();
  const { sendRequestIsLoading } = useAppSelector(({ requests }) => requests);

  const userItemProps = useMemo(() => {
    let trimmedRoute = history.location.pathname;
    if (filter) {
      // fix it when we will have corpsid ProfileItemRedirectTypes;
      // trimmedRoute = trimmedRoute.replace('/' + filter, '');
      trimmedRoute = '/suche/name';
    }

    return getUserFieldProps(trimmedRoute as ProfileItemRedirectTypes);
  }, [history.location.pathname]);

  const image = imagePath(null, user.photo);
  const owner = useSelector((state: any) => state.users.user);
  let requestSent, inContacts, isOwner;

  if (owner && owner.name && user && user.name) {
    const requestsIds = owner.sent_contact_request.map((item) => item.id);
    requestSent = requestsIds.includes(user._id);
    inContacts = owner.contact_list.includes(user._id);
    isOwner = owner._id === user._id;
  }

  const toProfileDetailsHandler = () => {
    typeof disactivateBackHandler === 'function' && disactivateBackHandler();
    history.push(`/profile-details/${user._id}`, { userId: user._id });
  };

  const toContactRequestHandler = () => {
    dispatch(
      sendRequestRequest(
        user._id,
        requestMessage({ sender: `${owner.name} ${owner.lastname}`, receiver: user.firstname }),
        profileDetails.sendRequestSuccessLabel,
      ),
    );
  };

  return (
    <div className={classes.container}>
      <Loader showLoader={sendRequestIsLoading} />
      <ButtonBase onClick={toProfileDetailsHandler} style={{ width: '100%' }}>
        <div className={classes.contentWrapper}>
          <div className={classes.imageWrapper} style={{ borderColor: theme.ICON_BORDER }}>
            <img src={image || defaultAvatar} alt=" " style={{ width: image ? '100%' : '100%' }} />
          </div>
          <div className={classes.heading}>
            <div className={classes.nameWrapper}>
              <span className={classes.name} style={{ color: theme.TEXT_PRIMARY }}>
                {user.name}{' '}
              </span>
              {user?.status?.length ? (
                <span className={classes.status} style={{ color: theme.TEXT_PRIMARY }}>
                  ({user.status})
                </span>
              ) : null}
            </div>
            {!!userItemProps?.length
              ? userItemProps.map((prop, idx) => {
                  if (prop === 'beruf') {
                    return !!user[prop]?.[0]?.name?.length ? (
                      <span key={idx} className={classes.corps} style={{ color: theme.TEXT_PRIMARY }}>
                        {user[prop]?.[0]?.name}
                      </span>
                    ) : null;
                  }
                  if (prop === 'corp') {
                    return (
                      <>
                        <span key={idx} className={classes.corps} style={{ color: theme.TEXT_PRIMARY }}>
                          {user.corp[0].corpsname}
                        </span>{' '}
                        <span key={idx} className={classes.corps} style={{ color: theme.TEXT_PRIMARY }}>
                          {user.corp[0]?.charge}
                        </span>
                      </>
                    );
                  }
                  let value = user[prop];
                  if (Array.isArray(value)) value = value.join(', ');
                  return user[prop] ? (
                    <span key={idx} className={classes.corps} style={{ color: theme.TEXT_PRIMARY }}>
                      {value}
                    </span>
                  ) : null;
                })
              : null}
          </div>
        </div>
      </ButtonBase>
      {!requestSent && !inContacts && !isOwner && (
        <ButtonBase onClick={toContactRequestHandler}>
          <div style={{ position: 'relative' }}>
            <div className={classes.iconWrapper} style={{ borderColor: theme.ICON_BORDER }}>
              <img src={defaultAvatar} alt=" " style={{ width: '100%' }} />
            </div>
            <div className={classes.addIconWrapper} style={{ background: theme.BUTTON_PRIMARY }}>
              <PlusIcon />
            </div>
          </div>
        </ButtonBase>
      )}
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(UserItem)));

import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'utilities/utils';

// className = 'outline-primary-btn';

const inputVariants = cva(
  `flex h-10 w-full rounded-md border px-3 py-1 text-sm transition-colors file:border-0 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-btn disabled:cursor-not-allowed disabled:opacity-50`,
  {
    variants: {
      variant: {
        default: `bg-white rounded-full shadow-sm placeholder:text-muted-foreground file:bg-transparent outline-primary-btn`,
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, variant, ...props }, ref) => {
  return <input type={type} className={cn(inputVariants({ variant, className }))} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export { Input, inputVariants };

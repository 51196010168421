import { showResponse } from 'store/actions/response';
import { signOutRequest } from './../store/actions/auth';
import { store } from 'store';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { serverActions } from 'store/reducers/server';
// Auth Header
export const authHeader = () => {
  const token = getLocalStorage('token');

  if (token) {
    return {
      'Content-Type': 'application/json',
      'x-access-token': token,
    };
  }

  return {
    'Content-Type': 'application/json',
  };
};

export const handleResponse = (response) =>
  response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!data.Boolean) {
      if (response.status === 401) {
        store.dispatch(signOutRequest());
        return Promise.reject({ message: 'Error 401. Bitte loggen Sie sich erneut ein.' });
      }

      if (response.status === 502) {
        console.log('server is down!!');
        store.dispatch(showResponse({ message: 'no connection', immediately: true }, '/reconnect'));
        store.dispatch(serverActions.setIsAlive(false));
      }

      return Promise.reject({ message: data.Status || data.message });
    }

    if (!response.ok) {
      const error = data || { message: response.statusText };
      if (response.status >= 400) {
        return Promise.reject(error);
      }
    }
    return data;
  });

export const handleConnectionError = (error) => {
  console.error('Error:', error);
  return Promise.reject(error);
};

export const handleGoogleResponse = (response) =>
  response.json().then((result) => {
    let error;

    if (result.error) {
      switch (response.code) {
        case 'EMAIL_EXISTS':
          error = 'Entschuldigung, diese E-Mail-Adresse ist bereits registriert.';
          break;
        default:
          error = 'Entschuldigung, diese E-Mail-Adresse ist bereits registriert.';
          break;
      }
      return Promise.reject({ message: error });
    }

    return result;
  });

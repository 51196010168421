import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants/';

export const sendMessageNotification = (notification: object) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(notification),
  };

  return fetch(`${API}/v2/message/notification`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

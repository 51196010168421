import { makeStyles } from '@material-ui/styles';
import { getConfig } from 'config/config';
import React, { FC, ReactNode } from 'react';
const defaultUser = require('../../assets/default_avatar.png');

const { theme } = getConfig();

interface IUserListItem {
  image?: string;
  name: string;
  description?: string;
  corpName?: string;
  alt?: string;
  rightBlock?: ReactNode;
  style?: string;
  rightBlockStyle?: string;
  onClick?: () => void;
  onRightClick?: () => void;
}

export const UserListItem: FC<IUserListItem> = ({
  image,
  name,
  description,
  corpName,
  rightBlock,
  style,
  alt,
  onClick,
  rightBlockStyle,
  onRightClick,
}) => {
  const styles = useStyles({});

  return (
    <div className={style}>
      <div className={styles.container}>
        <div className={styles.leftBlock} onClick={onClick}>
          <div className={styles.imageWrapper} style={{ borderColor: theme.ICON_BORDER }}>
            <img src={image || defaultUser} alt={alt || 'userImage'} style={{ width: '100%' }} />
          </div>
          <div className={styles.content}>
            <span className={styles.name} style={{ color: theme.TEXT_PRIMARY }}>
              {name}
            </span>
            <span className={styles.desc} style={{ color: theme.TEXT_SECONDARY }}>
              {description}
            </span>
            {corpName && (
              <span className={styles.desc} style={{ color: theme.TEXT_SECONDARY }}>
                {corpName}
              </span>
            )}
          </div>
        </div>
        <div className={rightBlockStyle} onClick={onRightClick}>
          {rightBlock}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 55,
    height: 55,
    marginRight: 10,
    borderRadius: '50%',
    border: '1px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  content: {
    marginLeft: 10,
  },
  name: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'left',
  },
  desc: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
  },
  '@media(max-width: 650px)': {
    name: {
      maxWidth: 200,
    },
  },
});

import { getConfig } from 'config/config';
import moment from 'moment';
import { useEffect } from 'react';
import { getLocalStorage } from 'utilities/localStorageHandler';

const { config } = getConfig();

export const useFilesCookies = (value: string) => {
  const src = `https://cookie.connact.app/?value=${value}`;
  useEffect(() => {
    if (config.API.includes('.connact.app')) return;

    const filesCookieDate = getLocalStorage('filesCookieDate');

    const currentDate = moment();

    const differenceInDays = currentDate.diff(filesCookieDate, 'days');

    // Check if 7 days have passed
    if (differenceInDays >= 7 || !filesCookieDate) {
      const cookieWindow = window.open(src);
      localStorage.setItem('filesCookieDate', moment().toString());
      setTimeout(() => {
        cookieWindow?.close();
      }, 500);
    }
  });
};

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig } from '../../config';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ProfileBasisProps } from './model';
import BasisAvatar from './components/BasisAvatar';
import ImageLightBox from '../../components/Shared/ImageLightBox/ImageLightBox';
import CropImageHandler from '../../components/Shared/CropImageHandler/';
import ProfileScale from '../../components/UI/ProfileScale';
import { imagePath } from '../../utilities/';
import { EditProfile } from 'scenes/EditProfile/EditProfile';

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 420,
    boxSizing: 'border-box',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, ProfileBasisProps {}

class ProfileBasis extends React.Component<Props> {
  state = {
    value: 1,
    lightBoxIsOpen: false,
    imageCropperIsOpen: false,
    croppedImage: null,
  };

  lightBoxHandler = () => {
    this.setState({ lightBoxIsOpen: !this.state.lightBoxIsOpen });
  };

  openImageCropper = (croppedImage) => {
    this.setState({
      imageCropperIsOpen: true,
      croppedImage: croppedImage,
    });
  };

  closeImageCropper = () => {
    this.setState({
      imageCropperIsOpen: false,
      croppedImage: null,
    });
  };

  render(): JSX.Element {
    const { lightBoxIsOpen, imageCropperIsOpen, croppedImage } = this.state;
    const { classes, user } = this.props;
    let image;

    if (user && user.name) {
      image = imagePath('full', user.photo);
    }

    return (
      <React.Fragment>
        {imageCropperIsOpen && croppedImage && (
          <CropImageHandler
            croppedImage={croppedImage}
            closeImageCropper={this.closeImageCropper}
            imageCropperIsOpen={imageCropperIsOpen}
            type="update"
          />
        )}
        {image && (
          <ImageLightBox showGallery={lightBoxIsOpen} images={new Array(image)} onClose={this.lightBoxHandler} />
        )}
        <div className={classes.container}>
          {user && user.name && (
            <BasisAvatar lightBoxHandler={this.lightBoxHandler} cropImageHandler={this.openImageCropper} />
          )}
          <ProfileScale />
          <EditProfile />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(withConfig(ProfileBasis)));

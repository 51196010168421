import React, { useRef, useState, useEffect } from 'react';
import { Route, Switch, RouteComponentProps, Redirect, RedirectProps, Link } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, Tabs, Tab } from '@material-ui/core';

import { useAppSelector } from 'store';
import { withConfig, Config } from 'config/';
import ScreenTitle from 'components/Shared/ScreenTitle';
import Layout from 'components/UI/Layout';

import { ProfileProps } from './model';
import ProfileBasis from '../ProfileBasis/';
import ProfileCorps from '../ProfileCorps/';
import ProfileBeruf from '../ProfileBeruf/';
import ProfileStudium from '../ProfileStudium/';
import ProfileSonstiges from '../ProfileSonstiges';
import ProfileBenachrichtigungen from '../ProfileBenachrichtigungen';
import { useLanguage } from 'languages/languageContext';
import { ProfilePrivacy } from 'scenes/ProfilePrivacy/ProfilePrivacy';

const borderColor = process.env.REACT_APP_BUTTON_PRIMARY;

const styles = createStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  containerWrapper: {
    width: '100%',
    padding: '70px 50px 20px',
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0 30px 0 30px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    padding: '10px 30px',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 20,
  },
  indicator: {
    background: borderColor,
    height: 4,
  },
  tabsWrapper: {
    width: '100%',
    position: 'fixed',
    top: 60,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: 10,
    zIndex: 10,
    boxShadow: '0 2px 5px 0 #ccc',
    overflowX: 'scroll',
    boxSizing: 'border-box',
  },
  '@media(max-width: 1020px)': {
    containerWrapper: {
      padding: '70px 20px 20px',
    },
  },
  '@media(max-width: 650px)': {
    containerWrapper: {
      padding: '70px 0 20px',
    },
    link: {
      padding: '10px 15px',
      fontSize: 14
    }
  },
});

function tabProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, RedirectProps, ProfileProps {
  config: Config;
}

const Profile: React.FC<Props> = (props) => {
  const { profileTabs, screenTitles } = useLanguage();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const user = useAppSelector((state) => state.users.user);
  const { disable_guest_function } = useAppSelector(state => state.common)
  const [value, setValue] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const { classes, theme, config, history } = props;
  const { path } = props.match;

  const tabNameToIndex = {
    0: 'basis',
    1: 'korporation',
    2: 'beruf',
    3: 'studium',
    4: 'sonstiges',
    5: 'benachrichtigungen',
    6: 'privacy',
  };

  const indexToTabName = {
    basis: 0,
    korporation: 1,
    beruf: 2,
    studium: 3,
    sonstiges: 4,
    benachrichtigungen: 5,
  };

  const setContainerWidthHandler = () => {
    window.addEventListener('resize', setContainerWidthHandler, { passive: false });
    if (container && container.current) {
      setContainerWidth(container.current.offsetWidth);
    }
    return () => window.removeEventListener('resize', setContainerWidthHandler);
  };

  const handleChange = (e: any, value: any) => {
    history.push(`${path}/${tabNameToIndex[value]}`);
    setValue(value);
  };

  const linkComponent = (route: string, title: string) => (
    <Link to={`${path}${route}`} className={classes.link} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </Link>
  );

  useEffect(() => {
    setContainerWidthHandler();
    setValue(0);

    if (props.location.pathname) {
      const { pathname } = props.location;
      const path = pathname.split('/');

      setValue(indexToTabName[path[2]] || 0);
    }
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <ScreenTitle title={screenTitles.profileTitle} rightBlock />
        <div className={classes.container}>
          <div ref={container} style={{ width: '100%' }}>
            {containerWidth && (
              <div className={classes.tabsWrapper} style={{ background: theme.BACKGROUND, width: containerWidth }}>
                <Tabs
                  style={{ padding: '5px 0 0' }}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  classes={{
                    indicator: classes.indicator,
                  }}
                >
                  <Tab label={linkComponent('/basis', profileTabs.basicTab)} {...tabProps(value)} />
                  <Tab label={linkComponent('/korporation', profileTabs.clubsTab)} {...tabProps(value)} />
                  {config.WITH_WORK && <Tab label={linkComponent('/beruf', profileTabs.professionTab)} {...tabProps(value)} />}
                  {config.WITH_EDUCATION && <Tab label={linkComponent('/studium', profileTabs.studyTab)} {...tabProps(value)} />}
                  <Tab label={linkComponent('/sonstiges', profileTabs.miscellaneosTab)} {...tabProps(value)} />
                  <Tab
                    label={linkComponent('/benachrichtigungen', profileTabs.notificationsTab)}
                    {...tabProps(value)}
                  />
                  {!disable_guest_function && <Tab
                    label={linkComponent('/privacy', profileTabs.privacy)}
                    {...tabProps(value)}
                  />}
                </Tabs>
              </div>
            )}
          </div>
          <div className={classes.containerWrapper}>
            <Switch>
              <Route exact path={`${path}`} render={() => <Redirect to={`${path}/basis`} />} />
              <Route path={`${path}/basis`}>
                <ProfileBasis user={user} />
              </Route>
              <Route path={`${path}/korporation`}>
                <ProfileCorps />
              </Route>
              <Route path={`${path}/beruf`}>
                <ProfileBeruf />
              </Route>
              <Route path={`${path}/studium`}>
                <ProfileStudium />
              </Route>
              <Route path={`${path}/sonstiges`}>
                <ProfileSonstiges />
              </Route>
              <Route path={`${path}/benachrichtigungen`}>
                <ProfileBenachrichtigungen />
              </Route>
              {!disable_guest_function && <Route path={`${path}/privacy`}>
                <ProfilePrivacy />
              </Route>}
            </Switch>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default withStyles(styles)(withConfig(Profile));

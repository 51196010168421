import React from 'react';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../../config/';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { imagePath } from '../../../utilities/';
const defaultUser = require('../../../assets/default_avatar.png');

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '5px 20px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
    border: '1px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  content: {
    marginLeft: 10,
  },
  name: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
  },
  desc: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
  },
  '@media(max-width: 650px)': {
    name: {
      maxWidth: 200,
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  user: any;
  onClick: () => void;
}

const RequestItem: React.FC<Props> = ({ classes, theme, user, onClick }) => {
  let image = imagePath(null, user.photo);

  return (
    <ButtonBase onClick={onClick} style={{ width: '100%' }}>
      <div className={classes.container}>
        <div className={classes.imageWrapper} style={{ borderColor: theme.ICON_BORDER }}>
          <img src={image || defaultUser} alt=" " style={{ width: '100%' }} />
        </div>
        <div className={classes.content}>
          <span className={classes.name} style={{ color: theme.TEXT_PRIMARY }}>
            {user.name} {user.lastname}
          </span>
          <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
            {user.wohnort}
          </span>
          <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
            {user.corp[0] && user.corp[0].corpsname}
          </span>
        </div>
      </div>
    </ButtonBase>
  );
};

export default withStyles(styles)(withRouter(withConfig(RequestItem)));

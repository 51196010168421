import { trimValues } from './../../utilities/trimValues';
import { takeEvery, put, call, fork } from 'redux-saga/effects';
import {
  GET_USER_PROFILE_REQUEST,
  EDIT_OWNER_AVATAR_REQUEST,
  GET_USER_BY_ID_REQUEST,
  GET_OWNER_PROFILE_REQUEST,
  UPDATE_USER_REQUEST,
  CLEAR_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_NOTIFICATIONS_REQUEST,
  MUTE_USER_NOTIFICATIONS_REQUEST,
  UNMUTE_USER_NOTIFICATIONS_REQUEST,
} from '../../constants/';
import {
  getUserProfileFailure,
  getUserProfileSuccess,
  editOwnerAvatarSuccess,
  editOwnerAvatarFailure,
  updateUserSuccess,
  updateUserFailure,
  getUserByIdSuccess,
  getUserByIdFailure,
  getOwnerProfileSuccess,
  getOwnerProfileFailure,
  clearUserSuccess,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
  updateUserNotificationsSuccess,
  updateUserNotificationsFailure,
  muteUserNotificationsSuccess,
  muteUserNotificationsFailure,
  unmuteUserNotificationsSuccess,
  unmuteUserNotificationsFailure,
} from '../actions/users';
import { showResponse } from '../actions/response';

import {
  getUserProfile,
  getOwnerProfile,
  editOwnerAvatar,
  updateUser,
  updateUserPassword,
  updateUserNotifications,
  muteUserNotifications,
  unmuteUserNotifications,
} from '../../api/';
import languages from 'languages/languages';
import { getCookies } from 'api/auth';
import { setStorage } from 'utilities';

// CLEAR USER
function* clearUserWorkerSaga() {
  yield put(clearUserSuccess());
}

function* clearUserWatcherSaga() {
  yield takeEvery(CLEAR_USER_REQUEST, clearUserWorkerSaga);
}

// GET OWNER PROFILE SAGAS
function* getOwnerProfileWorkerSaga(action) {
  try {
    const { id } = action;
    const result = yield call(() => getOwnerProfile(id));
    if (result?.Data?.askForBirthday)
      yield put(
        showResponse(
          {
            message:
              'Bitte überprüfe, ob dein Geburtstag korrekt gesetzt ist. Diese Nachricht verschwindet, wenn du dein Profil speicherst.',
            title: 'Geburtstag prüfen',
          },
          '/profile/basis',
        ),
      );
    const { cookie } = result?.Data || {};
    if (cookie) yield call(() => getCookies(cookie));
    setStorage({ userLang: result?.Data?.language });
    yield put(getOwnerProfileSuccess(result.Data));
  } catch (e) {
    yield put(getOwnerProfileFailure(e));
    yield put(showResponse(e));
  }
}

function* getOwnerProfileWatcherSaga() {
  yield takeEvery(GET_OWNER_PROFILE_REQUEST, getOwnerProfileWorkerSaga);
}

// UPDATE USER PROFILE
function* updateUserWorkerSaga(action) {
  let message: string;
  try {
    const { user, withoutMessage } = action;
    const { responseHandler } = languages().find((lang) => lang.key === user.language) || languages()[0];

    const result = yield call(() => updateUser(trimValues(user)));
    yield put(updateUserSuccess(result.Data));
    message = result.Status;

    if (result.InvalidCorps.length > 0) {
      const invalidCorps = result.InvalidCorps.map((corp) => corp);
      message = `${responseHandler.profileUpdatedCorpsError} ${invalidCorps} `;
    }
    if (!withoutMessage) yield put(showResponse({ message }));
  } catch (e) {
    yield put(updateUserFailure(e));
    yield put(showResponse(e));
  }
}

function* updateUserWatcherSaga() {
  yield takeEvery(UPDATE_USER_REQUEST, updateUserWorkerSaga);
}

// EDIT OWNER AVATAR SAGAS
function* editOwnerAvatarWorkerSaga(action) {
  try {
    const { fullphoto, photo } = action;
    const result = yield call(() => editOwnerAvatar(fullphoto, photo));
    yield put(editOwnerAvatarSuccess(result.Data));
  } catch (e) {
    yield put(editOwnerAvatarFailure(e));
    yield put(showResponse(e));
  }
}

function* editOwnerAvatarWatcherSaga() {
  yield takeEvery(EDIT_OWNER_AVATAR_REQUEST, editOwnerAvatarWorkerSaga);
}

// GET USER PROFILE SAGAS
function* getUserProfileWorkerSaga(action) {
  try {
    const { id } = action;
    const result = yield call(() => getUserProfile(id));
    yield put(getUserProfileSuccess(result.Data));
  } catch (e) {
    yield put(getUserProfileFailure(e));
    yield put(showResponse(e));
  }
}

function* getUserProfileWatcherSaga() {
  yield takeEvery(GET_USER_PROFILE_REQUEST, getUserProfileWorkerSaga);
}

// UPDATE USER PASSWORD
function* updateUserPasswordWorkerSaga(action) {
  const { payload, callback } = action;

  try {
    const result = yield call(() => updateUserPassword(payload));
    yield put(updateUserPasswordSuccess());
    callback();
    yield put(showResponse({ message: result.Status }));
  } catch (e) {
    yield put(updateUserPasswordFailure());
    yield put(showResponse(e));
  }
}

function* updateUserPasswordWatcherSaga() {
  yield takeEvery(UPDATE_USER_PASSWORD_REQUEST, updateUserPasswordWorkerSaga);
}

// UPDATE USER NOTIFICATIONS
function* updateUserNotificationsWorkerSaga(action) {
  let message: string;
  try {
    const { mailingNotifyOptions } = action;
    const result = yield call(() => updateUserNotifications(mailingNotifyOptions));

    yield put(updateUserNotificationsSuccess(result.Data));
    yield put(showResponse({ message: 'Profil aktualisiert!' }));
  } catch (e) {
    yield put(updateUserNotificationsFailure(e));
    yield put(showResponse(e));
  }
}

function* updateUserNotificationsWatcherSaga() {
  yield takeEvery(UPDATE_USER_NOTIFICATIONS_REQUEST, updateUserNotificationsWorkerSaga);
}

// MUTE USER NOTIFICATIONS
function* muteUserNotificationsWorkerSaga(action) {
  try {
    const { userId } = action;
    const result = yield call(() => muteUserNotifications(userId));

    yield put(muteUserNotificationsSuccess(result.Data));
    yield put(
      showResponse({
        message: 'Die Benachrichtigungen für Beiträge dieses Nutzers wurden deaktiviert.',
      }),
    );
  } catch (e) {
    yield put(muteUserNotificationsFailure(e));
    yield put(showResponse(e));
  }
}

function* muteUserNotificationsWatcherSaga() {
  yield takeEvery(MUTE_USER_NOTIFICATIONS_REQUEST, muteUserNotificationsWorkerSaga);
}

// UNMUTE USER NOTIFICATIONS
function* unmuteUserNotificationsWorkerSaga(action) {
  const { userId } = action;

  try {
    yield call(() => unmuteUserNotifications(userId));
    yield put(unmuteUserNotificationsSuccess(userId));
    yield put(
      showResponse({
        message: 'Die Benachrichtigungen für Beiträge dieses Nutzers sind nun wieder aktiv.',
      }),
    );
  } catch (e) {
    yield put(unmuteUserNotificationsFailure(e));
    yield put(showResponse(e));
  }
}

function* unmuteUserNotificationsWatcherSaga() {
  yield takeEvery(UNMUTE_USER_NOTIFICATIONS_REQUEST, unmuteUserNotificationsWorkerSaga);
}

const usersSagas = [
  fork(getUserProfileWatcherSaga),
  fork(editOwnerAvatarWatcherSaga),
  fork(clearUserWatcherSaga),
  fork(getOwnerProfileWatcherSaga),
  fork(updateUserWatcherSaga),
  fork(updateUserPasswordWatcherSaga),
  fork(updateUserNotificationsWatcherSaga),
  fork(muteUserNotificationsWatcherSaga),
  fork(unmuteUserNotificationsWatcherSaga),
];

export default usersSagas;

import React, { ReactElement } from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { withConfig } from '../../config/';
import { ScreenTitleProps } from '../model';
import { openMenu } from '../../store/actions/layout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/back_arrow.svg';

const styles = createStyles({
  /*
    Custom style for non fixed positioning
    */
  containerForFilter: {
    boxSizing: 'border-box',
    margin: '0 15px',
  },

  menuButtonWrapperForFilter: {
    width: 50,
    height: 50,
    display: 'block',
    position: 'absolute',
    left: 18,
    top: -2,
    padding: 13,
    cursor: 'pointer',
    borderRadius: '50%',
  },

  /*
    Default styles
    */
  container: {
    top: 0,
    position: 'sticky',
    width: '100%',
    zIndex: 1,
    boxSizing: 'border-box',
    borderTop: '6px solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuButtonWrapper: {
    width: 50,
    height: 50,
    top: -5,
    // padding: 13,
    cursor: 'pointer',
    borderRadius: '50%',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 30,
    textAlign: 'left',
    margin: '0 20px',
    fontWeight: 'bolder',
  },
  '@media(max-width: 1024px)': {
    container: {
      paddingRight: 'unset',
    },
  },
  '@media (max-width: 650px)': {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      padding: '0 10px',
      zIndex: 3,
      textAlign: 'center',
    },
    title: {
      textAlign: 'center',
      margin: '5px 0',
      fontSize: 25,
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, ScreenTitleProps {
  onClick?: () => void;
  isSearch?: boolean;
  isDown?: boolean;
  additionalButton?: ReactElement;
}

const BackButton: React.FC<Props> = ({
  classes,
  theme,
  title,
  history,
  onClick = () => history.goBack(),
  isSearch,
  isDown,
  additionalButton,
}) => {
  return (
    <div
      className={classes[isSearch ? 'containerForFilter' : 'container']}
      style={isSearch ? {} : { background: theme.BACKGROUND_SECONDARY, borderColor: theme.NAV_BAR }}
    >
      <div>
        <ButtonBase
          className={classes[isSearch ? 'menuButtonWrapperForFilter' : 'menuButtonWrapper']}
          style={isDown ? { top: 24 } : {}}
          onClick={onClick}
        >
          <BackIcon />
        </ButtonBase>
        <span className={classes.title} style={{ color: theme.TEXT_SECONDARY }}>
          {title}
        </span>
      </div>
      <div style={{ alignSelf: 'center', alignItems: 'center' }}>{additionalButton}</div>
    </div>
  );
};

export default withStyles(styles)(withConfig(BackButton));

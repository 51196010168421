import React from 'react';
import { Link } from 'react-router-dom';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';

import { useAppDispatch } from 'store';
import { withConfig } from 'config/';
// import { clearSearchQuery } from 'store/actions';

import { ProfileLinkProps } from '../model';

const styles = createStyles({
  contentItem: {
    padding: '10px 0',
  },
  contentTitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  contentSubtitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: '.5px',
  },
  linkWrapper: {
    display: 'flex',
  },
  link: {
    fontFamily: 'Roboto',
    textDecoration: 'none',
    fontSize: 15,
    marginLeft: 5,
    fontWeight: 500,
  },
});

interface Props extends WithStyles<typeof styles>, ProfileLinkProps { }

const ProfileLink: React.FC<Props> = ({ classes, theme, title, value, link, navigationMetadata }) => {
  const dispatch = useAppDispatch();

  let linkToOpen = link;
  if (linkToOpen) {
    if (linkToOpen.slice(0, 11) === 'http://http') {
      linkToOpen = linkToOpen.slice(7);
    }
    if (linkToOpen.slice(0, 11) === 'https://htt') {
      linkToOpen = linkToOpen.slice(8);
    }
  }

  return (
    <div className={classes.contentItem}>
      <span className={classes.contentTitle} style={{ color: theme.TEXT_SECONDARY }}>
        {title}:{' '}
      </span>
      <div className={classes.linkWrapper}>
        <Link to={navigationMetadata.type + `?searchKey=${value}`}>
          <span className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
            {value}
          </span>
        </Link>
        {!!link && (
          <a className={classes.link} href={linkToOpen} target="_blank">
            Website
          </a>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(ProfileLink));

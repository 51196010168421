import moment from 'moment';

export const configDate = (date: any, type: string, inverse = false) => {
  let year, month, day;
  const localDate = date;

  if (date) {
    year = moment(localDate).format('YYYY');
    month = moment(localDate).format('MM');
    day = moment(localDate).format('DD');

    if (day && month && year) {
      if (inverse) {
        return `${year}-${month}-${day}`;
      }
      return type === 'month' ? `${month}.${year}` : `${day}.${month}.${year}`;
    }

    return '';
  }
};

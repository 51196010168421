import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig, Theme } from '../../config';
import defaultImage from '../../assets/default_avatar.png';
import guestAvatar from '../../assets/guest_avatar.png';
import { imagePath } from '../../utilities';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useCustomHistory } from 'hooks/useCustomHistory';
import moment from 'moment';
import { clearPostLikes } from 'store/actions/likes';

const styles = createStyles({
  wrapper: {
    width: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '10px 30px',
    overflowX: 'hidden',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '1px solid',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  content: {
    marginLeft: 15,
  },
  title: {
    display: 'block',
    fontSize: 20,
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginBottom: 5,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  subtitle: {
    display: 'block',
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 5,
    textAlign: 'left',
  },
  '@media(max-width: 650px)': {
    container: {
      padding: '10px 20px',
    },
    title: {
      fontSize: 18,
      maxWidth: 250,
      lineHeight: '18px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  like: any;
  showGuestEmail: boolean;
  date?: string;
}

const PostLike: React.FC<Props> = ({ theme, classes, like, showGuestEmail = false, date }) => {
  const { name, lastname, wohnort, corp, photo } = like;
  const dispatch = useDispatch();
  const { customPush } = useCustomHistory();

  let senderPhoto;
  if (photo) {
    senderPhoto = imagePath(null, photo);
  }

  if (like.invitedEmail) {
    senderPhoto = guestAvatar;
  }

  const toProfileDetailsHandler = () => {
    customPush(`/profile-details/${like._id}`, { userId: like._id });
    dispatch(clearPostLikes());
  };

  return (
    <>
      <ButtonBase className={classes.container} onClick={toProfileDetailsHandler} disabled={!corp}>
        <div className={classes.imageWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
          <img src={senderPhoto || defaultImage} alt=" " className={classes.image} />
        </div>
        <div className={classes.content}>
          <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {`${name} ${lastname ? lastname : ''}`}
          </span>
          <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
            {wohnort}
          </span>

          {corp?.length && (
            <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
              {corp ? corp[0].corpsname : `Eingeladen von ${like.inviter}` || ''}
            </span>
          )}

          {date && (
            <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
              {moment(date).format('DD.MM.YYYY HH:mm') || date}
            </span>
          )}
        </div>
      </ButtonBase>
    </>
  );
};

export default withStyles(styles)(withRouter(withConfig(PostLike)));

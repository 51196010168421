import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useReducer } from 'react';
import { Theme, withConfig } from '../../config';
import { patterns, validateInput } from '../../utilities';
import Button from '../UI/Button';
import Input from '../UI/Input';
import DefaultModal from './DefaultModal';
import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';

const styles = createStyles({
  modalInputsWrapper: {
    padding: '15px 15% 0 15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  desc: {
    fontSize: 12,
    marginBottom: 15,
    textAlign: 'center',
  },
  link: {
    color: '#2671D1',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  email?: string;
  inviter?: string;
  modalHandler: (set: boolean) => {};
  submitHandler: (guest: { guestName: string; guestEmail: string; inviter: string }) => {};
  showModal: boolean;
}

interface State {
  name: {
    value: string;
    isFocused: boolean;
    error: string;
  };
  email: {
    value: string;
    isFocused: boolean;
    error: string;
  };
  inviter: {
    value: string;
    isFocused: boolean;
    error: string;
  };
}

interface Action {
  type: string;
  payload?: any;
}

const initState = (query = { email: '', inviter: '' }): State => {
  const { email, inviter } = query;
  return {
    name: {
      value: '',
      isFocused: false,
      error: '',
    },
    email: {
      value: email || '',
      isFocused: false,
      error: '',
    },
    inviter: {
      value: inviter || '',
      isFocused: false,
      error: '',
    },
  };
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'state': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const ZusagenModal: React.FC<Props> = ({ theme, classes, email, inviter, submitHandler, showModal, modalHandler }) => {
  const [state, dispatch] = useReducer(reducer, { email, inviter }, initState);
  const { eventLabels } = useLanguage();
  const { guest_naming_singular, tou_link, dpd_link } = useAppSelector((state) => state.common);
  const user = useAppSelector((state: any) => state.users.user);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { value } = e.target;

    dispatch({
      type: 'state',
      payload: {
        [name]: {
          ...state[name],
          value,
        },
      },
    });
  };

  const focusHandler = (name: keyof State): void => {
    dispatch({
      type: 'state',
      payload: {
        [name]: {
          ...state[name],
          isFocused: true,
        },
      },
    });
  };

  const blurHandler = (name: keyof State): void => {
    handleValidation(state[name].value, name);
  };

  const onSubmit = () => {
    const name = state.name.value.trim();
    const email = state.email.value.trim();
    const inviter = state.inviter.value.trim();

    const isValid =
      handleValidation(name, 'name') && handleValidation(inviter, 'inviter') && email
        ? handleValidation(email, 'email')
        : true;
    if (isValid) {
      modalHandler(false);
      dispatch({ type: 'state', payload: initState() });
      submitHandler({
        guestName: name,
        guestEmail: email,
        inviter,
      });
    }
  };

  const handleValidation = (value: string, name: keyof State) => {
    let isValid = true;
    let error = '';
    const pattern = name === 'email' ? patterns.email : patterns.required;
    if (!validateInput(value, pattern)) {
      isValid = false;
      error = name === 'email' ? 'Die Angabe Ihrer E-Mail ist nicht korrekt' : 'Dieses Feld wird benötigt.';
    }
    dispatch({
      type: 'state',
      payload: {
        [name]: {
          ...state[name],
          isFocused: false,
          error,
        },
      },
    });
    return isValid;
  };

  return (
    <DefaultModal
      showModal={showModal}
      onClose={() => {
        modalHandler(false);
      }}
      modalName="zusagen"
      modalTitle={eventLabels.linkLabel}
    >
      <div className={classes.modalInputsWrapper}>
        <Input
          placeholder="Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeHandler(e, 'name');
          }}
          autoCapitalize="none"
          value={state.name.value}
          isFocused={state.name.isFocused}
          onBlur={() => {
            blurHandler('name');
          }}
          onFocus={() => {
            focusHandler('name');
          }}
          error={state.name.error}
        />
        <Input
          placeholder="E-Mail (optional)"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeHandler(e, 'email');
          }}
          autoCapitalize="none"
          value={state.email.value}
          isFocused={state.email.isFocused}
          error={!!state.email.value && state.email.error}
        />
        <Input
          placeholder="Eingeladen von"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeHandler(e, 'inviter');
          }}
          autoCapitalize="none"
          value={state.inviter.value}
          isFocused={state.inviter.isFocused}
          onBlur={() => {
            blurHandler('inviter');
          }}
          onFocus={() => {
            focusHandler('inviter');
          }}
          error={state.inviter.error}
        />
        <div className={classes.buttonWrapper}>
          <Button
            label="Speichern"
            disabled={state.name.value && state.inviter.value}
            onClick={() => {
              onSubmit();
            }}
          />
          {!user._id && (
            <>
              <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
                Hinweis: Bitte hier nur als {guest_naming_singular} eintragen! Wenn du einen Account auf der App hast,
                logge dich bitte damit ein und sage offiziell als Mitglied zu. Danke!
              </span>
              <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
                Mit der Eintragung stimmen Sie den{' '}
                <a className={classes.link} href={tou_link} target="_blank" rel="noreferrer">
                  AGB
                </a>{' '}
                und{' '}
                <a className={classes.link} href={dpd_link} target="_blank" rel="noreferrer">
                  DSE
                </a>{' '}
                zu.
              </span>
            </>
          )}
          <Button
            label="Abbrechen"
            background={theme.BUTTON_SECONDARY}
            onClick={() => {
              modalHandler(false);
            }}
          />
        </div>
      </div>
    </DefaultModal>
  );
};

export default withStyles(styles)(withConfig(ZusagenModal));

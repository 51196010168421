import { takeEvery, call, put, fork } from 'redux-saga/effects';
import { GET_CORPS_NAMES_REQUEST, GET_CORPS_SUBS_REQUEST } from '../../constants';
import { getCorpsNamesSuccess, getCorpsNamesFailure, getCorpsSubsSuccess, getCorpsSubsFailure } from '../actions/corps';
import { showResponse } from '../actions/response';

import { getCorpsNames, getCorpsSubs } from '../../api/';

function* getCorpsNamesWorkerSaga(action) {
  const { name, guest } = action;
  try {
    const result = yield call(() => getCorpsNames(name, guest));
    const corpsNames = result.Data;
    yield put(getCorpsNamesSuccess(corpsNames));
  } catch (e) {
    yield put(getCorpsNamesFailure(e));
    yield put(showResponse(e, null));
  }
}

function* corpsNamesWatcherSaga(): Generator<any> {
  yield takeEvery(GET_CORPS_NAMES_REQUEST, getCorpsNamesWorkerSaga);
}

// GET CORPS SUBS
function* getCorpsSubsWorkerSaga() {
  try {
    const result = yield call(getCorpsSubs);
    yield put(getCorpsSubsSuccess(result));
  } catch (e) {
    yield put(getCorpsSubsFailure(e));
    yield put(showResponse(e));
  }
}

function* getCorpsSubsWatcherSaga() {
  yield takeEvery(GET_CORPS_SUBS_REQUEST, getCorpsSubsWorkerSaga);
}

const corpsSagas = [fork(corpsNamesWatcherSaga), fork(getCorpsSubsWatcherSaga)];

export default corpsSagas;

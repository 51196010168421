import { Box, ButtonBase } from "@material-ui/core";
import { AddCircle, Folder } from "@material-ui/icons";
import React, { FC } from "react";
import { getConfig } from "../../../config/config";


export const AddFolder: FC<{ currentPath: any, showFoldersModal: () => void }> = (props) => {

  return <>
    <ButtonBase onClick={props.showFoldersModal}>
      {/* <Icon name='folder' size={32} color={theme.BUTTON_PRIMARY} /> */}
      <Folder style={{ color: theme.BUTTON_PRIMARY, width: 32, height: 32 }} />
      <Box style={{
        position: 'absolute',
        backgroundColor: theme.BACKGROUND_PRIMARY,
        width: 24,
        height: 24,
        borderRadius: 24,
        right: -7,
        top: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <AddCircle style={{ color: theme.BUTTON_PRIMARY, width: 24, height: 24 }} />
      </Box>
    </ButtonBase>

  </>
}
const { config, theme } = getConfig()
import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withConfig } from '../../config';
import Button from '../UI/Button';
import { SearchableModalProps } from '../model';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 20,
  },
  modalWrapper: {
    position: 'relative',
    width: '90%',
    maxWidth: 450,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 20,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontFamily: 'Poppins',
    margin: 0,
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  inputWrapper: {
    padding: 15,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
});

interface Props extends WithStyles<typeof styles>, SearchableModalProps {
  value: string;
  withoutButton?: boolean;
  children?: React.ReactNode;
}

const SearchableModal: React.FC<Props> = ({
  classes,
  modalOpen,
  theme,
  children,
  setValueHandler,
  modalTitle,
  value,
  withoutButton = false,
  ...props
}) => {
  const { btn } = useLanguage();
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modalOpen]);

  return modalOpen ? (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {modalTitle}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase {...props} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.inputWrapper}>{children}</div>
        {!withoutButton && <Button label={btn.select} onClick={setValueHandler} />}
      </div>
      <div {...props}>
        <div className={classes.backdrop}></div>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withConfig(SearchableModal));

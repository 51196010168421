import { takeEvery, call, put, fork, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import firebase from 'firebase/app';
import 'firebase/database';
import {
  GET_CHAT_USERS_REQUEST,
  MARK_MESSAGE_AS_READ_REQUEST,
  GET_DIALOG_REQUEST,
  SEND_MESSAGE_REQUEST,
} from '../../constants/';
import { getChatUsersSuccess, getChatUsersFailure, getDialogSuccess, unreadMessagesSuccess } from '../actions/chat';
import { showResponse } from '../actions/response';

import { getUsersByIds, sendMessageNotification } from '../../api/';
import { getCustomFirebaseToken } from '../../api/auth';
import { signInWithCustomToken } from '../../firebase';

// MARK MESSAGE AS READ
const markMessageAsRead = (receiverUID: string, messageKey: string) => {
  const firebaseId = localStorage.getItem('firebaseID');
  if (firebaseId) {
    const messageRef = firebase.database().ref('messages/' + firebaseId);

    return messageRef.child(receiverUID).child(messageKey).child('read').set(1);
  }
};

const newmarkMessageAsRead = (receiverUID: string) => {
  const firebaseId = localStorage.getItem('firebaseID');
  if (firebaseId) {
    const sentRef = firebase.database().ref('messages/' + receiverUID + '/' + firebaseId);

    sentRef.once('value').then((snapshot) => {
      snapshot.forEach((childSn) => {
        const msgKey = childSn.key;
        sentRef.child(msgKey).update({ read: 1 });
      });
    });
  }
};

// CREATE DIALOGS CHANNEL
const createDialogsChannel = () => {
  const firebaseId = localStorage.getItem('firebaseID');

  if (!firebaseId) return;
  const chatRef = firebase.database().ref('messages/' + firebaseId);

  const listener = eventChannel((emit: any) => {
    chatRef.once('value', (data) => emit(data.val() || {}));
    return () => chatRef.off('value');
  });

  return listener;
};

// CREATE MESSAGES CHANNEL
const createMessagesChannel = (dialogId: string) => {
  const firebaseId = localStorage.getItem('firebaseID');

  if (!firebaseId) return;
  const chatRef = firebase
    .database()
    .ref('messages/' + firebaseId)
    .child(dialogId);

  const listener = eventChannel((emit: any) => {
    chatRef.on('value', (data) => emit({ ...data.val(), key: data.key }));
    return () => chatRef.off('value');
  });

  return listener;
};

// SEND MESSAGE
const sendMessage = (receiverId: string, message: string, attachments: any) => {
  const firebaseId = localStorage.getItem('firebaseID');

  if (firebaseId) {
    const senderRef = firebase
      .database()
      .ref('messages/' + firebaseId)
      .child(receiverId)
      .push();
    const receiverRef = firebase
      .database()
      .ref('messages/' + receiverId)
      .child(firebaseId)
      .push();
    const now = new Date();

    senderRef.set({
      type: 'send',
      msg: message,
      time: now.getTime(),
      read: -1,
      attachments,
    });

    receiverRef.set({
      type: 'receive',
      msg: message,
      time: now.getTime(),
      read: 0,
      attachments,
    });
  }
};

function* getNewFirebaseToken() {
  const token = yield localStorage.getItem('token');
  if (!token) return;
  const customToken = yield call(() => getCustomFirebaseToken());

  yield call(() => signInWithCustomToken(customToken.Data));
}

// INIT CHAT
function* initChatWorkerSaga(action) {
  const { open } = action;

  try {
    yield call(() => getNewFirebaseToken());
    const channel = yield createDialogsChannel();
    let usersArr: Array<any> = [];
    let unreadMessages: Array<any> = [];

    if (channel) {
      if (!open) {
        channel.close('value');
      } else {
        while (true) {
          const messages = yield take(channel);
          usersArr = Object.keys(messages);

          const users = yield call(() => getUsersByIds(usersArr));
          const existingUsers = users.Data.map((user) => user.firebaseID);

          existingUsers.map((key) => {
            for (let key1 in messages[key]) {
              if (messages[key][key1].read == 0) {
                unreadMessages.push({ ...messages[key][key1], key });
              }
            }
          });

          const updatedUsers = users.Data.map((item) => {
            const chat = messages?.[item.firebaseID];
            const lastMsg = Object.values(chat).pop();
            return {
              ...item,
              messages: messages[item.firebaseID],
              lastMessage: lastMsg,
            };
          });

          yield put(getChatUsersSuccess(updatedUsers));
          yield put(unreadMessagesSuccess(unreadMessages, ''));

          unreadMessages = [];
        }
      }
    }
  } catch (e) {
    console.log('CHAT ERROR', e);
    yield put(getChatUsersFailure(e));
    yield put(showResponse(e));
  }
}

function* initChatWatcherSaga() {
  yield takeEvery(GET_CHAT_USERS_REQUEST, initChatWorkerSaga);
}

// MARK AS READ
function* markAsReadWorkerSaga(action) {
  const { receiverUID, unreadMessages } = action;

  try {
    yield call(() => newmarkMessageAsRead(receiverUID));
    for (let i = 0; i < unreadMessages.length; i++) {
      yield call(() => markMessageAsRead(receiverUID, unreadMessages[i]));
    }
  } catch (e) {
    yield put(showResponse(e));
  }
}

function* markAsReadWatcherSaga() {
  yield takeEvery(MARK_MESSAGE_AS_READ_REQUEST, markAsReadWorkerSaga);
}

// SEND NOTIFICATION
function* sendNotification(notification) {
  try {
    yield call(() => sendMessageNotification(notification));
  } catch (e) {}
}

// GET DIALOG
function* getDialogWorkerSaga(action) {
  const { dialogId, mode } = action;

  if (dialogId) {
    try {
      const channel = yield createMessagesChannel(dialogId);

      if (channel) {
        if (mode === 'close') {
          channel.close('value');
        } else {
          while (true) {
            const message = yield take(channel);

            yield put(getDialogSuccess(message));
            yield call(() => newmarkMessageAsRead(dialogId));
            for (let key in message) {
              if (typeof message[key] !== 'string') {
                if (message[key].read == 0) {
                  yield call(() => markMessageAsRead(dialogId, key));
                }
              }
            }

            yield put(unreadMessagesSuccess([], dialogId));
          }
        }
      }
    } catch (e) {
      yield put(showResponse(e));
    }
  }
}

function* getDialogWatcherSaga() {
  yield takeEvery(GET_DIALOG_REQUEST, getDialogWorkerSaga);
}

// SEND MESSAGE SAGA
function* sendMessageWorkerSaga(action) {
  const { receiverId, message, notification, attachments } = action;

  try {
    yield call(() => sendMessage(receiverId, message, attachments));
    yield call(() => sendNotification(notification));
  } catch (e) {
    yield put(showResponse(e));
  }
}

function* sendMessageWatcherSaga() {
  yield takeEvery(SEND_MESSAGE_REQUEST, sendMessageWorkerSaga);
}

const chatSagas = [
  fork(initChatWatcherSaga),
  fork(markAsReadWatcherSaga),
  fork(getDialogWatcherSaga),
  fork(sendMessageWatcherSaga),
];

export default chatSagas;

import { UserType } from 'store/types/usersTypes';
import { API } from '../constants';
import { client } from './client';
import { handleResponse, authHeader, handleConnectionError } from './core';

// PROFILE
export const getUserProfile = (id: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/userprofile/${id}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// EDIT OWNER AVATAR
export const editOwnerAvatar = (fullphoto, photo) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ fullphoto, photo, awsUpload: true }),
  };

  return fetch(`${API}/v2/profile/avatar`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ID
export const getUserByID = (userIds: Array<string>) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(userIds),
  };

  return fetch(`${API}/v2/profileByuid`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ME
export const getOwnerProfile = (userId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };

  return fetch(`${API}/v2/profile/me`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};
// export const getOwnerProfile = (userId: string) => client.get('/v2/profile/me');

// UPDATE USER PROFILE
export const updateUser = (userEntity) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(userEntity),
  };

  return fetch(`${API}/v4/profile`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UPDATE USER PASSWORD
export const updateUserPassword = (payload: object) => {
  const options: any = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(`${API}/v4/profile/password`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UPDATE USER NOTIFICATIONS
export const updateUserNotifications = async (payload: object) => {
  const options: any = {
    method: 'POST',
    headers: await authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(`${API}/v4/profile/mailNotifications`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET USERS BY IDS
export const getUsersByIds = (users: Array<string>) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(users),
  };

  return fetch(`${API}/v2/profileByuid`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// MUTE USERS NOTIFICATIONS
export const muteUserNotifications = async (userId: string) => {
  const options: any = {
    method: 'POST',
    headers: await authHeader(),
  };

  return fetch(`${API}/v4/profile/mute/${userId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UNMUTE USERS NOTIFICATIONS
export const unmuteUserNotifications = async (userId: string) => {
  const options: any = {
    method: 'POST',
    headers: await authHeader(),
  };

  return fetch(`${API}/v4/profile/unmute/${userId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const updateProfileBasic = async (payload: Partial<UserType>): Promise<Partial<UserType>> =>
  client.patch(`/v2/profile/basic`, payload);

export const hideConfirmation = (field: string): Promise<'OK'> => client.put(`/v2/profile/hide-confirmation/${field}`);

export const shownConfirmation = (field: string): Promise<'OK'> =>
  client.put(`/v2/profile/shown-confirmation/${field}`);

import { makeStyles } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { Cross1Icon, Cross2Icon } from '@radix-ui/react-icons';
import { getConfig } from 'config/config';
import { FC, ReactNode, useEffect } from 'react';

export type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  modalTitle?: string;
  message?: string;
};

export const Modal: FC<ModalProps & { children: ReactNode }> = (props) => {
  const { isOpen, handleClose, children, modalTitle, message } = props;
  const styles = useStyles({});
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.backdrop}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <span className={styles.modalTitle}>{modalTitle || config.APP_FULL_NAME}</span>
          <Cross2Icon className={styles.closeButton} onClick={handleClose} />
        </div>
        <div className={styles.modalBody}>
          <p className={styles.messageWrapper}>{message}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

const { theme, config } = getConfig();

const useStyles = makeStyles({
  backdrop: {
    position: 'fixed',
    backgroundColor: '#00000040',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    minWidth: 300,
    maxWidth: 420,
    minHeight: 200,
    maxHeight: '90%',
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 10,
    boxShadow: '1px 0 10px #00000050',
    display: 'flex',
    flexDirection: 'column',
  },
  modalHeader: {
    alignSelf: 'flex-start',
    backgroundColor: theme.BACKGROUND_SECONDARY,
    minHeight: 40,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '10px 0',
  },
  modalTitle: {
    fontSize: 15,
    fontFamily: 'Poppins',
    color: theme.TEXT_PRIMARY,
    fontWeight: 'bold',
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '0 30px 0 40px',
    marginLeft: 30,
    textAlign: 'center',
    width: '100%',
  },
  closeButton: {
    width: 26,
    height: 26,
    borderRadius: 26,
    color: theme.BUTTON_SECONDARY,
    marginRight: 10,
    cursor: 'pointer',
    ':&hover': {
      backgroundColor: '#00000040',
    },
  },
  messageWrapper: {
    padding: '0 15px',
    margin: '6px 0',
    color: theme.TEXT_PRIMARY,
    fontFamily: 'Roboto',
    minWidth: 0,
    fontSize: 15,
  },
  modalBody: {},
});

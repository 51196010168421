import React, { FC, useState } from 'react';
import { withStyles, WithStyles, createStyles, CircularProgress } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { withAuth } from '../../config/';
import Layout from '../../components/UI/Layout';
import BackButton from '../../components/Shared/BackButton';
import RequestHeader from '../../components/Shared/RequestHeader';
import Button from '../../components/UI/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { useEffect } from 'react';
import {
  clearRequest,
  declineRequestRequest,
  getUserRequestsRequest,
  handleRequestRequest,
  setCurrentRequest,
} from 'store/actions/requests';
import { useQuery } from 'hooks/useQuery';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    width: '100%',
  },
  messageWrapper: {
    padding: 20,
    maxWidth: 250,
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  message: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '18px',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '0 20px',
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {}

const RequestDetails: FC<Props> = (props) => {
  const { classes, history } = props;

  const [sender, setSender] = useState(props.history.location.state?.sender || null);
  const { location } = useHistory();
  const query = useQuery();

  const requestId = query.get('id');
  const type = location.pathname.replace('/requests/', '').slice(0, 8);

  const { currentRequest, currentRequestIsLoading, incRequests, outRequests } = useAppSelector(
    (state) => state.requests,
  );
  const { screenTitles, btn } = useLanguage();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!sender) {
      dispatch(getUserRequestsRequest());
      return;
    }

    return () => {
      dispatch(clearRequest());
    };
  }, []);

  useEffect(() => {
    if (sender) return;
    const newSender = [...outRequests, ...incRequests].find((req) => req._id === requestId);
    if (newSender?.id) {
      setSender(newSender.id);
      dispatch(setCurrentRequest(newSender));
    }
  }, [outRequests, incRequests]);

  function handleRequest(type: string) {
    const { history } = props;
    if (type === 'declinesent') {
      dispatch(declineRequestRequest(sender._id));
    } else {
      dispatch(handleRequestRequest(type, sender._id));
    }
    goBack();
  }

  const goBack = () => (history.location.state?.prevRoute ? history.goBack() : history.replace('/requests'));

  return (
    <Layout>
      <BackButton title={screenTitles.reqDetailsTitle} onClick={goBack} />
      {!sender ? null : !currentRequestIsLoading ? (
        <div className={classes.container}>
          <RequestHeader sender={sender} />
          <div className={classes.messageWrapper}>
            <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
              {screenTitles.reqDetailsTitle}
            </span>
            <p className={classes.message} style={{ color: theme.TEXT_PRIMARY }}>
              {currentRequest.message}
            </p>
          </div>
          {type === 'incoming' ? (
            <div className={classes.buttonsWrapper}>
              <Button label={btn.acceptReqBtn} onClick={() => handleRequest('add')} />
              <Button
                label={btn.declineBtn}
                onClick={() => handleRequest('decline')}
                background={theme.BUTTON_SECONDARY}
              />
            </div>
          ) : null}
          {type === 'outgoing' ? (
            <div className={classes.buttonsWrapper}>
              <Button
                label={btn.cancelBtn}
                onClick={() => handleRequest('declinesent')}
                background={theme.BUTTON_SECONDARY}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className={classes.progressWrapper}>
          <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={45} />
        </div>
      )}
    </Layout>
  );
};

export default withStyles(styles)(withAuth(RequestDetails));

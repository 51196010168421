import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Button } from 'components/shadcn/button';
import { Label } from 'components/shadcn/label';
import { useLanguage } from 'languages/languageContext';
import { FC } from 'react';
import { TunverifiedField } from 'store/types/usersTypes';
import { cn } from 'utilities/utils';

export const InputButton: FC<{
  onClick: () => any;
  placeholder: string;
  label?: string;
  touched?: boolean;
  error?: string;
  value?: string;
  unverifiedFields?: TunverifiedField;
}> = ({ onClick, placeholder, label, error, touched, value, unverifiedFields }) => {
  const { profileDetails } = useLanguage();
  return (
    <>
      <Label className="ml-3 mb-1">{label || placeholder}</Label>
      <Button variant="input" size="input" onClick={onClick} asChild>
        <div className="justify-between">
          {value ? <span>{value}</span> : <span className="text-muted-foreground">{placeholder}</span>}
          <ChevronDownIcon />
        </div>
      </Button>
      {unverifiedFields && (
        <div className="flex flex-col relative -z-10 -mt-5 w-full h-11 bg-gray-200 rounded-xl rounded-tr-none rounded-tl-none justify-end">
          <span className="pb-1 px-3 text-txt-secondary text-sm">{profileDetails.inReview}</span>
        </div>
      )}
      <div className={cn('min-h-4 w-full ml-3 text-left text-xs text-red-500')}>{touched && error}</div>
    </>
  );
};

import { makeStyles } from '@material-ui/styles';
import EmptyList from 'components/Shared/EmptyList';
import { UserListItem } from 'components/UI/UserListItem';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { getUserRequestsRequest, setCurrentRequest } from 'store/actions/requests';

const { theme } = getConfig();

export const Requests: FC = () => {
  const history = useHistory();
  const { incRequests, outRequests } = useAppSelector((state) => state.requests);
  const dispatch = useAppDispatch();
  const styles = useStyles({});
  const { requestsLabels } = useLanguage();

  useEffect(() => {
    dispatch(getUserRequestsRequest());
  }, []);

  const toRequestDetails = (type: 'incoming' | 'outgoing', item: { _id: string }) => {
    dispatch(setCurrentRequest(item));
    history.push(`/requests/${type}?id=${item._id}`, { prevRoute: history.location });
  };

  const RequestHeader = ({ title }) => (
    <div className={styles.headerContainer}>
      <span className={styles.headerTitle}>{title}</span>
    </div>
  );

  if (incRequests.length === 0 && outRequests.length === 0) {
    return <EmptyList />;
  }

  return (
    <>
      {incRequests.length > 0 ? (
        <div className={styles.requestsWrapper}>
          <RequestHeader title={requestsLabels.receiveLabel} />
          {incRequests.map((item, index) => (
            <UserListItem
              name={`${item.id?.name} ${item.id?.lastname}`}
              image={item.id?.photo}
              description={item.id?.wohnort}
              corpName={item.id?.corp[0]?.corpsname}
              key={index}
              onClick={() => toRequestDetails('incoming', item)}
            />
          ))}
        </div>
      ) : null}
      {outRequests.length > 0 ? (
        <div className={styles.requestsWrapper}>
          <RequestHeader title={requestsLabels.sentLabel} />
          {outRequests.map((item, index) => (
            <UserListItem
              name={`${item.id?.name} ${item.id?.lastname}`}
              image={item.id?.photo}
              description={item.id?.wohnort}
              corpName={item.id?.corp[0]?.corpsname}
              key={index}
              onClick={() => toRequestDetails('outgoing', item)}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '20px 0',
    borderRadius: 10,
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0 30px 0 30px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  requestsWrapper: {
    marginBottom: 20,
  },
  headerContainer: {
    width: '100%',
    padding: '10px 20px',
  },
  headerTitle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.TEXT_PRIMARY,
  },
});

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';

import { withConfig, Theme } from 'config/';
import { imagePath } from 'utilities/';
import { useCustomHistory } from 'hooks/useCustomHistory';
const defaultImg = require('assets/default_avatar.png');

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '40%',
    padding: '10px',
    borderRadius: 10,
    border: '1px solid',
    boxSizing: 'border-box',
    marginTop: 10,
    marginRight: 10,
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 15,
    border: '1px solid',
    overflow: 'hidden',
  },
  name: {
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  infoWrapper: {},
  nameWrapper: {},
  infoVal: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  '@media(max-width: 1024px)': {
    container: {
      width: '45%',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  organizer: any;
  disabled: boolean;
}

const EventOrg: React.FC<Props> = ({ classes, theme, organizer, history, disabled = false }) => {
  const { customPush } = useCustomHistory()
  let image;

  if (organizer) {
    image = imagePath(null, organizer.photo);
  }

  const toProfileHandler = () => {
    customPush(`/profile-details/${organizer._id}`, {
      userId: organizer._id,
    });
  };

  return (
    <div
      onClick={disabled ? () => { } : toProfileHandler}
      className={classes.container}
      style={{
        borderColor: theme.BACKGROUND_SECONDARY,
        background: theme.BACKGROUND_PRIMARY,
        cursor: disabled ? '' : 'pointer',
      }}
    >
      <div className={classes.imageWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
        <img src={image || defaultImg} alt=" " style={{ width: '100%' }} />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.nameWrapper}>
          <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
            {organizer.name} {organizer.lastname}
          </span>
          {organizer.status ? (
            <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
              ({organizer.status}){' '}
            </span>
          ) : null}
        </div>
        <span className={classes.infoVal} style={{ color: theme.TEXT_SECONDARY }}>
          {organizer.wohnort}
        </span>
        <span className={classes.infoVal} style={{ color: theme.TEXT_SECONDARY }}>
          {organizer.corp[0].corpsname}
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(EventOrg)));

import React, { FC, ReactElement } from 'react';
import { Checkbox, createStyles } from '@material-ui/core';
import { getConfig } from '../../config/config';

import CircleChecked from '@material-ui/icons/CheckBox';
import CircleUnchecked from '@material-ui/icons/CheckBoxOutlineBlank';
type Props = {
  isChecked: boolean;
  setIsChecked: () => void;
  label: string | ReactElement;
  wrapperStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  disabled?: boolean
};

export const CheckboxUI: FC<Props> = ({ isChecked, setIsChecked, label, disabled, wrapperStyle }) => {
  return (
    <div style={{ ...styles.wrapper, ...wrapperStyle }}>
      <Checkbox
        icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
        checkedIcon={<CircleChecked style={{ color: disabled ? theme.ICON_BORDER : theme.BUTTON_SECONDARY }} />}
        checked={isChecked}
        onChange={setIsChecked}
        disabled={disabled}
      />
      {!!label && <span style={{ ...styles.label, color: disabled ? theme.BACKGROUND_SECONDARY : theme.TEXT_SECONDARY }}>{label}</span>}
    </div>
  );
};

const { theme } = getConfig();
//@ts-ignore
const styles: { [key: string]: React.CSSProperties } = createStyles({
  wrapper: {
    marginLeft: 10,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: theme.TEXT_PRIMARY,
    width: '100%',
    textAlign: 'left',
    paddingVertical: 7,
    // color: theme.TEXT_PRIMARY,
    paddingLeft: 5
  },
});

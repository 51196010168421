import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants/';

// GET USER CONTACTS
export const getUserContacts = (userId: string, page: number, limit: number, searchkey = '') => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/contact/${userId}/${page}/${limit}?searchkey=${encodeURIComponent(searchkey)}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET CONTACT LIST
export const getContactsList = (userId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/contact/${userId}/contactlist`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

import moment from 'moment';
import { dateToLocal, setTime } from './setTime';

/**
 *
 * Get an object which consists of the day, month and year.
 *
 * @param rawDate If you need to format your date first, then you should pass a date as a string using
 * 'YYYY-MM-DD' format and set an additional parameter splitString to true.
 * @param splitString Set to true if you need to format a string in 'YYYY-MM-DD' format.
 * @returns object of formatted date enrties { day, month, year }
 */
export const formatCalendarDate = (
  rawDate: string | Date,
  splitString?: boolean,
): { day: string; month: string; year: string } => {
  let day = '',
    month = '',
    year = '',
    localDate = rawDate;

  if (!splitString) {
    // localDate = dateToLocal(rawDate)
    // day = localDate.getDate().toString()
    // month = (localDate.getMonth() + 1).toString()
    // year = localDate.getFullYear().toString()
    day = moment(localDate).format('DD');
    month = moment(localDate).format('MM');
    year = moment(localDate).format('YYYY');
  } else {
    // ;[year, month, day] = localDate.toString().split('-')
    [year, month, day] = moment(localDate).format('YYYY-MM-DD').split('-');
  }

  // const formattedDay = `${+day < 9 ? '0' + +day : day}`
  // const formattedMonth = `${+month < 9 ? '0' + +month : month}`

  return {
    // day: formattedDay,
    // month: formattedMonth,
    day,
    month,
    year,
  };
};

/**
 * @returns string in "'DD.MM.YYYY' um 'HH.MM'" format
 *
 * @param splitString true if you pass a string in 'YYYY-MM-DD' format.
 * @param onlyDate true if you need to return a date string in 'YYYY-MM-DD' format
 */
export default function formatDate(rawDate: string, splitString?: boolean, onlyDate?: boolean) {
  const { day, month, year } = formatCalendarDate(rawDate, splitString);
  //@ts-ignore
  const { hours, minutes } = setTime(rawDate, true);

  if (onlyDate) {
    return `${year}-${month}-${day}`;
  }

  const date = `${day}.${month}.${year}`;
  const time = `${hours}:${minutes}`;

  const formattedDate = `${date} um ${time}`;

  return formattedDate;
}

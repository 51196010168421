import { ReactNodeArray } from 'react';
import { SHOW_RESPONSE, HIDE_RESPONSE } from '../../constants/';

type TResponse = { message: string; title: string; options?: ReactNodeArray; immediately?: boolean };

const initialState = {
  response: null as TResponse,
  route: null,
  showResponse: false,
};

export const response = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_RESPONSE:
      return {
        ...state,
        showResponse: true,
        response: action.response,
        route: action.route,
      };
    case HIDE_RESPONSE:
      return {
        ...state,
        showResponse: false,
        response: null,
        route: null,
      };
    default:
      return {
        ...state,
      };
  }
};

import { client } from 'api/client';
import { API } from 'constants/core';
import { folderType } from 'scenes/Documents/DocumentsScreen';
import { authHeader, handleConnectionError, handleResponse } from './core';

// UPLOAD DOCUMENTS
export const uploadDocuments = async (documents: any) => client.post(`${API}/v4/documents`, documents);

// GET DOCUMENTS
export const downloadDocuments = async (limit = 20, page = 1) => {
  const options = {
    method: 'GET',
    headers: authHeader(),
  };

  // @ts-ignore
  return fetch(`${API}/v4/documents?limit=${limit}&page=${page}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError)
    .catch((error) => error);
};

// DELETE DOCUMENT
export const deleteDocument = async (documentId: string) => {
  const options: any = {
    method: 'DELETE',
    headers: await authHeader(),
  };

  return fetch(`${API}/v4/documents/${documentId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError)
    .catch((error) => error);
};

export const getRootFolder = async (): Promise<folderType> => await client.get('folders');

export const getFolderById = async (id: string): Promise<folderType> => await client.get(`folders/${id}`);

export const createFolder = async (id: string, title: string): Promise<folderType> =>
  await client.post(`folders/${id}`, { title });

export const renameFolder = async (id: string, title: string) => await client.patch(`folders/${id}`, { title });

export const deleteFolder = async (id: string) => await client.delete(`folders/${id}`);

export const renameDocument = async (id: string, filename: string) =>
  await client.put(`v4/documents/${id}`, { filename });

import { setConfig } from 'config';

export const postImagePath = (type: string | null, path: string | any): string | undefined => {
  const { config } = setConfig();
  if (!path) return undefined;
  if (path?.url?.length) path = path.url;
  if (path) {
    if (!path?.includes('http') && path?.trim().length > 0) {
      let serverPath = type === 'full' ? '/feeds/' : '/feeds/thumb/';
      return config.SERVER_PATH + serverPath + path;
    }
    return path;
  }
};

import {
  GET_CHAT_USERS_REQUEST,
  GET_CHAT_USERS_SUCCESS,
  GET_CHAT_USERS_FAILURE,
  CLEAR_CHAT_USERS,
  MARK_MESSAGE_AS_READ_REQUEST,
  MARK_MESSAGE_AS_READ_SUCCESS,
  MARK_MESSAGE_AS_READ_FAILURE,
  GET_DIALOG_REQUEST,
  GET_DIALOG_SUCCESS,
  GET_DIALOG_FAILURE,
  CLEAR_DIALOG,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  UNREAD_MESSAGES_REQUEST,
  UNREAD_MESSAGES_SUCCESS,
  UNREAD_MESSAGES_FAILURE,
  CLEAR_UNREAD_MESSAGES,
} from '../../constants/';
import { dialogType, messageType } from 'store/types/chatTypes';
import { UserType } from 'store/types/usersTypes';

interface State {
  getChatUsersIsLoading: boolean;
  getDialogIsLoading: boolean;
  sendMessageIsLoading: boolean;
  dialog: dialogType;
  chatUsers: UserType[];
  unreadMessages: messageType[];
}

const initialState: State = {
  getChatUsersIsLoading: false,
  getDialogIsLoading: false,
  sendMessageIsLoading: false,
  dialog: {},
  chatUsers: [],
  unreadMessages: [],
};

export const chat = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAT_USERS_REQUEST:
      return {
        ...state,
        getChatUsersIsLoading: true,
      };
    case GET_CHAT_USERS_SUCCESS:
      return {
        ...state,
        getChatUsersIsLoading: false,
        chatUsers: action.users,
      };
    case GET_CHAT_USERS_FAILURE:
      return {
        ...state,
        getChatUsersIsLoading: false,
      };
    case CLEAR_CHAT_USERS:
      return {
        ...state,
        chatUsers: null,
      };
    case MARK_MESSAGE_AS_READ_REQUEST:
      return {
        ...state,
        markAsReadIsLoading: true,
      };
    case MARK_MESSAGE_AS_READ_SUCCESS:
      return {
        ...state,
        markAsReadIsLoading: false,
      };
    case MARK_MESSAGE_AS_READ_FAILURE:
      return {
        ...state,
        markAsReadIsLoading: false,
      };
    case GET_DIALOG_REQUEST:
      return {
        ...state,
        getDialogIsLoading: true,
      };
    case GET_DIALOG_SUCCESS:
      return {
        ...state,
        getDialogIsLoading: false,
        dialog: action.dialog,
      };
    case GET_DIALOG_FAILURE:
      return {
        ...state,
        getDialogIsLoading: false,
        error: action.error,
      };
    case CLEAR_DIALOG:
      return {
        ...state,
        dialog: [],
      };
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageIsLoading: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageIsLoading: false,
      };
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        sendMessageIsLoading: false,
      };
    case UNREAD_MESSAGES_REQUEST:
      return {
        ...state,
      };
    case UNREAD_MESSAGES_SUCCESS:
      const filteredMessages = state.unreadMessages.filter((message) => message.key !== action.messageKey);

      return {
        ...state,
        unreadMessages: action.messageKey ? filteredMessages : action.messages,
      };
    case UNREAD_MESSAGES_FAILURE:
      return {
        ...state,
      };
    case CLEAR_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: [],
      };
    default:
      return {
        ...state,
      };
  }
};

import React, { useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../../config';
import { ReactComponent as LikeButtonIcon } from '../assets/like_button.svg';
import { ReactComponent as LikeIcon } from '../assets/like.svg';
import { useSelector, useDispatch } from 'react-redux';
import { clearPostLikes, likePostRequest, unlikePostRequest } from '../../../store/actions/likes';
import { showResponse } from '../../../store/actions/response';
import PostLikesModal from './PostLikesModal';
import clsx from 'clsx';
import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';
import Like from 'assets/icons/Like';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  likeWrapper: {},
  commentsWrapper: {
    borderRadius: 50,
  },
  likeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftButton: {
    boxSizing: 'border-box',
    width: 50,
    marginRight: 2,
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: '200ms',
    },
  },
  rightButton: {
    padding: '12px 0',
    width: 50,
    borderRadius: '0 50px 50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: '200ms',
    },
  },
  commentsButton: {
    padding: '13px 30px',
    borderRadius: 50,
  },
  commentsLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  likeLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '20px',
    display: 'block',
  },
  loaderWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  post: {
    _id: string;
    comments: Array<any>;
    liked_users: Array<any>;
  };
  toDetailsHandler: (id: string) => void;
}

const PostToolbar: React.FC<Props> = ({ classes, theme, post, toDetailsHandler }) => {
  const language = useLanguage();
  const userId = localStorage.getItem('userId');
  let likedPost = false;

  // REDUX
  const { postLikesCount, postLikesIsLoading } = useSelector((state: any) => state.likes);
  const { isGuest } = useAppSelector((state) => state.users.user);
  const { restrictions } = useAppSelector((state) => state.common);
  const dispatch = useDispatch();

  // STATE
  const [showLikes, setShowLikes] = useState<boolean>(false);
  const [currentLikes, setCurrentLikes] = useState<Array<any>>([]);
  const [likeAnim, setLikeAnim] = useState<boolean>(false);

  // INIT COMPONENT STATE
  useEffect(() => {
    setCurrentLikes(post.liked_users);
  }, []);

  if (currentLikes) {
    likedPost = currentLikes.includes(userId);
  }

  // OPEN MODAL HANDLER
  const onOpenModal = () => {
    if (isGuest) {
      dispatch(showResponse({ message: restrictions.likes || language.privacy.restrictions.likes }));
      return;
    }
    setShowLikes(true);
    document.body.style.overflow = 'hidden';
  };

  // CLOSE MODAL HANDLER
  const onCloseModal = () => {
    setShowLikes(false);
    dispatch(clearPostLikes());
    document.body.style.overflow = 'unset';
  };

  const likePostHandler = () => {
    setLikeAnim(true);
    const userId = localStorage.getItem('userId');

    if (userId && !likedPost) {
      const updatedLikes = [...currentLikes];
      updatedLikes.push(userId);
      setCurrentLikes(updatedLikes);
      dispatch(likePostRequest(userId, post._id));
    } else if (userId && likedPost) {
      const updatedLikes = [...currentLikes];
      const likeIndex = updatedLikes.indexOf(userId);
      const filteredLikes = updatedLikes.filter((like, i) => i !== likeIndex);
      setCurrentLikes(filteredLikes);
      dispatch(unlikePostRequest(userId, post._id));
    }
  };

  return (
    <React.Fragment>
      {currentLikes ? (
        <div className={classes.container}>
          <div className={classes.likeWrapper}>
            <div className={classes.likeButton} style={{ color: theme.BUTTON_PRIMARY }}>
              <ButtonBase
                className={classes.leftButton}
                style={{
                  background: likedPost ? theme.BUTTON_LIGHT : theme.BACKGROUND_PRIMARY,
                }}
                onClick={likePostHandler}
                disabled={postLikesIsLoading}
                onAnimationEnd={() => setLikeAnim(false)}
              >
                {
                  <Like
                    color={likedPost ? theme.BUTTON_PRIMARY : 'transparent'}
                    stroke={likedPost ? 'transparent' : theme.BUTTON_PRIMARY}
                  />
                }
              </ButtonBase>
              <ButtonBase
                className={classes.rightButton}
                style={{
                  background: currentLikes && currentLikes.length === 0 ? theme.BACKGROUND_PRIMARY : theme.BUTTON_LIGHT,
                }}
                onClick={onOpenModal}
                disabled={currentLikes && currentLikes.length === 0}
              >
                <span className={classes.likeLabel} style={{ color: theme.BUTTON_PRIMARY, marginRight: 5 }}>
                  {currentLikes.length}
                </span>
              </ButtonBase>
            </div>
          </div>
          <div className={classes.commentsWrapper} style={{ background: theme.BUTTON_LIGHT }}>
            <ButtonBase className={classes.commentsButton} onClick={() => toDetailsHandler(post._id)}>
              <span className={classes.commentsLabel} style={{ color: theme.BUTTON_PRIMARY }}>
                {language.post.comment} ({post.comments.length})
              </span>
            </ButtonBase>
          </div>
        </div>
      ) : null}
      {showLikes ? (
        <PostLikesModal
          showModal={showLikes}
          post={post}
          onClose={onCloseModal}
          modalTitle={`Likes (${postLikesCount})`}
        />
      ) : null}
    </React.Fragment>
  );
};

export default withStyles(styles)(withConfig(PostToolbar));

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { firebaseInit } from './firebase/';
import * as Sentry from '@sentry/browser';
import { store } from '../src/store/';
import Routes from '../src/routes/';
import OfflineModal from './components/Shared/OfflineModal';
import { LanguageContextProvider } from './languages/languageContext';
import { setS3Prefix } from 'utilities/setS3Prefix';
import { getAppValues } from 'store/reducers/common';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const { REACT_APP_SENTRY_DSN_PROD } = process.env;

Sentry.init({ dsn: REACT_APP_SENTRY_DSN_PROD });

firebaseInit();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

function App() {
  useEffect(() => {
    setS3Prefix();
    // @ts-ignore
    store.dispatch(getAppValues());
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <LanguageContextProvider>
          <OfflineModal />
          <Routes />
          <Toaster />
        </LanguageContextProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;

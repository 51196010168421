import { ButtonBase, makeStyles } from '@material-ui/core';
import { IonIcon } from 'components/UI/IonIcon';
import { getConfig } from 'config/config';
import React, { FC } from 'react';
import { fileType } from 'store/reducers/attachments';
import { downloadFile } from 'utilities/downloadFile';

type Props = Partial<fileType> & { style?: React.CSSProperties; isPdf?: boolean };

const { theme } = getConfig();

export const DownloadButton: FC<Props> = ({ url, fileName, style }) => {
  const styles = useStyles({});

  return (
    <ButtonBase onClick={() => downloadFile(url, fileName)} className={styles.downloadBtn} style={style}>
      <IonIcon
        name="download-outline"
        size={24}
        color={theme.BACKGROUND}
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
      />
    </ButtonBase>
  );
};

const useStyles = makeStyles({
  downloadBtn: {
    position: 'relative',
    padding: 5,
    borderRadius: 30,
    backgroundColor: theme.ACTIVE_INPUT,

    zIndex: 1,
    '&:hover': {
      opacity: 0.9,
    },
  },
});

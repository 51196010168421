import { Box } from '@material-ui/core';
import { mentoringGetProfileById, mentoringSendRequest, mentoringUpdateRequest } from 'api/mentoring';
import RequestHeader from 'components/Shared/RequestHeader';
import Button from 'components/UI/Button';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { showResponse } from 'store/actions/response';
import { getMentoringRequests, mentoringActions, SelectMentoringState } from 'store/reducers/mentoring';

export const MentroingProfileDetailsScreen: FC = () => {
  const { mentoring, responseHandler, btn } = useLanguage();
  const dispatch = useAppDispatch();
  const { requests } = useAppSelector(SelectMentoringState);
  const history = useHistory();
  const userId = history.location.pathname.replace('/mentoring/mentoring-profile/', '');
  const [user, setUser] = useState(history.location.state?.user || null);
  const me = useAppSelector((state) => state.users.user);
  const myMentor =
    requests.mentors.findIndex((item) => item?.receiver?._id === userId && item.status !== 'finished') !== -1;
  const alreadyHaveMentor =
    (config.KAS_MENTORING &&
      (requests.mentors.find((req) => req.status !== 'finished') || requests.outgoingRequests.length)) ||
    myMentor;
  const getUser = async () => {
    const res = await mentoringGetProfileById(userId);
    setUser({ ...res, _id: userId });
  };

  useEffect(() => {
    dispatch(getMentoringRequests());
    getUser();
  }, []);

  const showPdfHandler = () => window.open(user.mentorPdf);

  const mentoringRequestId = requests.outgoingRequests.findIndex((req) => req?.receiver?._id === user?._id);
  const mentoringRequestSent = mentoringRequestId !== -1;

  const mentoringRequestHandler = async (id: string) => {
    try {
      if (mentoringRequestSent) {
        await mentoringUpdateRequest(requests.outgoingRequests[mentoringRequestId]._id, 'cancelled');
        const filteredRequests = requests.outgoingRequests.filter((_, idx) => idx === mentoringRequestId);
        dispatch(mentoringActions.setRequests({ ...requests, outgoingRequests: filteredRequests }));
        await dispatch(getMentoringRequests());
        dispatch(showResponse({ message: mentoring.requests.requestRetracted }));
        return;
      }
      if (alreadyHaveMentor) {
        if (myMentor) return dispatch(showResponse({ message: 'Max. 1 Mentoring-Anfrage gestattet' }));
        return dispatch(showResponse({ message: 'Max. 1 parallele Mentoring Anfrage gestattet' }));

        return;
      }
      if (!mentoringRequestSent) {
        history.push('/mentoring/mentoring-request', {
          user,
          reason: 'send',
        });
      }
    } catch (e) {
      console.log('ERROR ON sendContactRequest', e);
      dispatch(showResponse({ message: responseHandler.messageLabel }));
    }
  };

  return (
    <Box
      style={{
        ...styles.container,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {user?.name?.length && <RequestHeader sender={user} route="MentoringScreen" isMentoring />}
      {!!user?.mentoringRegions?.length && (
        <>
          <h6 style={styles.title}>{mentoring.profile.mentoringRegions}</h6>
          {user.mentoringRegions.map((item) => (
            <span style={styles.name} key={item}>
              - {item}
            </span>
          ))}
        </>
      )}
      {!!user?.topics?.length && (
        <>
          <h6 style={styles.title}>{mentoring.profile.topics}</h6>
          {user.topics.map((item) => (
            <span style={styles.name} key={item}>
              - {item}
            </span>
          ))}
        </>
      )}
      {!!user?.departments?.length && (
        <>
          <h6 style={styles.title}>{mentoring.profile.departments}</h6>
          {user.departments.map((item) => (
            <span style={styles.name} key={item}>
              - {item}
            </span>
          ))}
        </>
      )}

      {!!user?.mentorPdf && (
        <>
          <h6 style={styles.title}>{mentoring.profile.pdf}</h6>
          <span style={styles.name}>
            Öffne hier das{' '}
            <span onClick={showPdfHandler} style={{ cursor: 'pointer', color: '#2671D1' }}>
              {`${mentoring.profile.pdf} (PDF)`}
            </span>
          </span>
        </>
      )}
      <Box height={50} />

      {!me.isMentor && (
        <Button
          label={mentoringRequestSent ? mentoring.requests.cancelSendingRequest : mentoring.requests.sendRequest}
          onClick={() => mentoringRequestHandler(user._id)}
          background={(mentoringRequestSent && theme.ERROR_PRIMARY) || (alreadyHaveMentor && '#ccc') || undefined}
        />
      )}
      <Button
        onClick={() => {
          history.goBack();
          window.close();
        }}
        label={btn.cancelBtn}
      />
    </Box>
  );
};

const { config, theme } = getConfig();
const styles = {
  container: {
    flex: 1,
    marginHorizontal: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: 15,
    paddingBottom: 100,
  },
  name: {
    fontSize: 14,
    color: theme.TEXT_PRIMARY,
    marginBottom: 2,
    marginLeft: 15,
  },
  title: {
    fontSize: 16,
    color: theme.TEXT_PRIMARY,
    marginBottom: 2,
    marginTop: 10,
  },
};

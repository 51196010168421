import { takeEvery, put, call, fork } from 'redux-saga/effects';
import {
  GET_POST_LIKES_REQUEST,
  GET_COMMENTS_LIKES_REQUEST,
  LIKE_POST_REQUEST,
  UNLIKE_POST_REQUEST,
  LIKE_COMMENT_REQUEST,
  UNLIKE_COMMENT_REQUEST,
  GET_EVENT_LIKES_REQUEST,
  LIKE_EVENT_REQUEST,
  UNLIKE_EVENT_REQUEST,
  LIKE_EVENT_COMMENT_REQUEST,
  UNLIKE_EVENT_COMMENT_REQUEST,
  GET_EVENT_COMMENT_LIKES_REQUEST,
} from '../../constants';
import {
  getPostLikesSuccess,
  getPostLikesFailure,
  getCommentsLikesSuccess,
  getCommentsLikesFailure,
  likePostFailure,
  likePostSuccess,
  unlikePostFailure,
  unlikePostSuccess,
  likeCommentFailure,
  likeCommentSuccess,
  unlikeCommentFailure,
  unlikeCommentSuccess,
  getEventLikesSuccess,
  getEventLikesFailure,
  likeEventSuccess,
  likeEventFailure,
  unlikeEventSuccess,
  unlikeEventFailure,
  likeEventCommentSuccess,
  likeEventCommentFailure,
  unlikeEventCommentSuccess,
  unlikeEventCommentFailure,
  getEventCommentLikesSuccess,
  getEventCommentLikesFailure,
} from '../actions/likes';
import { showResponse } from '../actions/response';

import {
  getPostLikes,
  getCommentsLikes,
  likePost,
  unlikePost,
  likeComment,
  unlikeComment,
  getEventLikes,
  likeEvent,
  unlikeEvent,
  likeEventComment,
  unlikeEventComment,
  getEventCommentLikes,
} from '../../api/';

// GET POST LIKES
function* getPostLikesWorkerSaga(action) {
  try {
    const { postId, page, limit } = action;

    const result = yield call(() => getPostLikes(postId, page, limit));
    const { data, total } = result.Data;
    yield put(getPostLikesSuccess(data, total));
  } catch (e) {
    yield put(getPostLikesFailure(e));
    yield put(showResponse(e));
  }
}

function* getPostLikesWatcherSaga() {
  yield takeEvery(GET_POST_LIKES_REQUEST, getPostLikesWorkerSaga);
}

// GET COMMENTS LIKES
function* getCommentsLikesWorkerSaga(action) {
  try {
    const { postId, page, limit } = action;

    const result = yield call(() => getCommentsLikes(postId, page, limit));
    const { data, total } = result.Data;
    yield put(getCommentsLikesSuccess(data, total));
  } catch (e) {
    yield put(getCommentsLikesFailure(e));
    yield put(showResponse(e));
  }
}

function* getCommentsLikesWatcherSaga() {
  yield takeEvery(GET_COMMENTS_LIKES_REQUEST, getCommentsLikesWorkerSaga);
}

// LIKE POST
function* likePostWorkerSaga(action) {
  const { liked_user, post_id } = action;

  try {
    const result = yield call(() => likePost(liked_user, post_id));
    yield put(likePostSuccess(result.Data));
  } catch (e) {
    yield put(likePostFailure(e));
    yield put(showResponse(e));
  }
}

function* likePostWatcherSaga() {
  yield takeEvery(LIKE_POST_REQUEST, likePostWorkerSaga);
}

// UNLIKE POST
function* unlikePostWorkerSaga(action) {
  const { liked_user, post_id } = action;

  try {
    const result = yield call(() => unlikePost(liked_user, post_id));
    yield put(unlikePostSuccess(result.Data));
  } catch (e) {
    yield put(unlikePostFailure(e));
    yield put(showResponse(e));
  }
}

function* unlikePostWatcherSaga() {
  yield takeEvery(UNLIKE_POST_REQUEST, unlikePostWorkerSaga);
}

// LIKE COMMENT
function* likeCommentWorkerSaga(action) {
  const { liked_user, comment_id } = action;

  try {
    const result = yield call(() => likeComment(liked_user, comment_id));
    yield put(likeCommentSuccess(result.Data));
  } catch (e) {
    yield put(likeCommentFailure(e));
    yield put(showResponse(e));
  }
}

function* likeCommentWatcherSaga() {
  yield takeEvery(LIKE_COMMENT_REQUEST, likeCommentWorkerSaga);
}

// UNLIKE COMMENT
function* unlikeCommentWorkerSaga(action) {
  const { liked_user, comment_id } = action;

  try {
    const result = yield call(() => unlikeComment(liked_user, comment_id));
    yield put(unlikeCommentSuccess(result.Data));
  } catch (e) {
    yield put(unlikeCommentFailure(e));
    yield put(showResponse(e));
  }
}

function* unlikeCommentWatcherSaga() {
  yield takeEvery(UNLIKE_COMMENT_REQUEST, unlikeCommentWorkerSaga);
}

// GET EVENT LIKES
function* getEventLikesWorkerSaga(action) {
  const { eventId, page, limit } = action;

  try {
    const result = yield call(() => getEventLikes(eventId, page, limit));
    yield put(getEventLikesSuccess(result.Data.data, result.Data.total));
  } catch (e) {
    yield put(getEventLikesFailure(e));
    yield put(showResponse(e));
  }
}

function* getEventLikesWatcherSaga() {
  yield takeEvery(GET_EVENT_LIKES_REQUEST, getEventLikesWorkerSaga);
}

// LIKE EVENT
function* likeEventWorkerSaga(action) {
  const { eventId } = action;
  const userId = localStorage.getItem('userId');

  try {
    if (userId) {
      const result = yield call(() => likeEvent(eventId, userId));
      yield put(likeEventSuccess(result.Data));
    }
  } catch (e) {
    yield put(likeEventFailure(e));
    yield put(showResponse(e));
  }
}

function* likeEventWatcherSaga() {
  yield takeEvery(LIKE_EVENT_REQUEST, likeEventWorkerSaga);
}

// UNLIKE EVENT
function* unlikeEventWorkerSaga(action) {
  const { eventId } = action;
  const userId = localStorage.getItem('userId');

  try {
    if (userId) {
      const result = yield call(() => unlikeEvent(eventId, userId));
      yield put(unlikeEventSuccess(result.Data));
    }
  } catch (e) {
    yield put(unlikeEventFailure(e));
    yield put(showResponse(e));
  }
}

function* unlikeEventWatcherSaga() {
  yield takeEvery(UNLIKE_EVENT_REQUEST, unlikeEventWorkerSaga);
}

// LIKE EVENT COMMENT
function* likeEventCommentWorkerSaga(action) {
  const { eventId, userId, commentId } = action;

  try {
    const result = yield call(() => likeEventComment(eventId, userId, commentId));

    yield put(likeEventCommentSuccess(result.Data));
  } catch (e) {
    yield put(likeEventCommentFailure(e));
    yield put(showResponse(e));
  }
}

function* likeEventCommentWatcherSaga() {
  yield takeEvery(LIKE_EVENT_COMMENT_REQUEST, likeEventCommentWorkerSaga);
}

// UNLIKE EVENT COMMENT
function* unlikeEventCommentWorkerSaga(action) {
  const { eventId, userId, commentId } = action;
  try {
    const result = yield call(() => unlikeEventComment(eventId, userId, commentId));

    yield put(unlikeEventCommentSuccess(result.Data));
  } catch (e) {
    yield put(unlikeEventCommentFailure(e));
    yield put(showResponse(e));
  }
}

function* unlikeEventCommentWatcherSaga() {
  yield takeEvery(UNLIKE_EVENT_COMMENT_REQUEST, unlikeEventCommentWorkerSaga);
}

// GET EVENT COMMENT LIKES
function* getEventCommentLikesWorkerSaga(action) {
  const { commentId, page, limit } = action;

  try {
    const result = yield call(() => getEventCommentLikes(commentId, page, limit));

    yield put(getEventCommentLikesSuccess(result.Data.data, result.Data.total));
  } catch (e) {
    yield put(getEventCommentLikesFailure(e));
    yield put(showResponse(e));
  }
}

function* getEventCommentLikesWatcherSaga() {
  yield takeEvery(GET_EVENT_COMMENT_LIKES_REQUEST, getEventCommentLikesWorkerSaga);
}

// FORKS
const likesSaga = [
  fork(getPostLikesWatcherSaga),
  fork(getCommentsLikesWatcherSaga),
  fork(likePostWatcherSaga),
  fork(unlikePostWatcherSaga),
  fork(likeCommentWatcherSaga),
  fork(unlikeCommentWatcherSaga),
  fork(getEventLikesWatcherSaga),
  fork(likeEventWatcherSaga),
  fork(unlikeEventWatcherSaga),
  fork(likeEventCommentWatcherSaga),
  fork(unlikeEventCommentWatcherSaga),
  fork(getEventCommentLikesWatcherSaga),
];

export default likesSaga;

import React, { FC, useCallback, useEffect, useState } from 'react';
import Layout from '../../components/UI/Layout';
import { getConfig } from '../../config/config';
import KreiseSubscriber from '../KreisePosts/components/KreiseSubscriber';
import { useAppDispatch, useAppSelector } from '../../store';
import Loader from '../../components/UI/Loader';
import moment from 'moment';
import { useLanguage } from '../../languages/languageContext';
import { setKreiseSettings } from 'api/kreise';
import { hideResponse, showResponse } from 'store/actions/response';
import { getOwnerProfileRequest } from 'store/actions/users';
import {
  addKreiseAdmin,
  addKreiseUser,
  clearKreiseSubscribers,
  deleteKreise,
  editKreise,
  getKreiseById,
  getKreiseSubscribers,
  removeKreiseAdmin,
  removeKreiseUser,
} from 'store/reducers/kreise';
import { IonIcon } from 'components/UI/IonIcon';
import { ButtonBase, CircularProgress, MenuItem, Select, makeStyles } from '@material-ui/core';
import BackButton from 'components/Shared/BackButton';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router';
import { BootstrapInput } from 'components/UI/MUISelect';
import SearchableModal from 'components/Shared/SearchableModal';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import { searchRequest } from 'store/reducers/searchSlice';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import EditIcon from '@material-ui/icons/Edit';
import LightboxGallery from 'components/Shared/ImageLightBox/ImageLightBox';
import { debounce } from 'utilities';
import { Textarea } from 'components/shadcn/textArea';
import Content from 'components/UI/Content';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import { mediaActions, trimMedia } from 'store/reducers/mediaSlice';
import { UploadMedia } from 'components/Shared/UploadMedia';
import toast from 'react-hot-toast';
import { fileType } from 'store/reducers/attachments';
import { checkIfImageExists } from 'utilities/checkIfImageExists';
import PostGallery from 'components/Shared/PostGallery';

export const KreiseInfoScreen: FC = () => {
  const history = useHistory();
  const styles = useStyles({});
  const { kreiseId } = useParams<{ kreiseId?: string }>();
  const { kreiseSettings: LkreiseSettings, circles, searchModal, btn, search: Lsearch, messages } = useLanguage() || {};
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const {
    currentKreise: kreise,
    kreiseSubscribers,
    kreiseSubscribersAmount,
    kreiseSubscribersIsLoading,
  } = useAppSelector((state) => state.kreise);

  const { loading: attachmentsLoading, loadingCounter, images } = useAppSelector((state) => state.media);

  const settings = user.kreiseSettings?.[kreise?.id];
  const kreiseSettings = kreise?.notificationSettings;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [usersPage, setUsersPage] = useState<number>(2);
  const [loading, setLoading] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [membersInput, setMembersInput] = useState('');
  const [showMembersModal, setShowMembersModal] = useState({
    members: false,
    admins: false,
  });

  const [state, setState] = useState({
    name: {
      value: '',
      isFocused: false,
    },
    description: {
      value: '',
      isFocused: false,
    },
    photo: {} as fileType,
  });

  const { data, isLoading, searchKey, count } = useAppSelector((state) => state.search.name);

  const isAdmin = !!kreise?.admins?.find((el) => el._id === user._id);

  const newUserSettings = [...Object.entries(NEW_USER_SETTINGS)].map(([vKey, val]) => ({ vKey, val }));
  const statisticSettings = [...Object.entries(STATISTIC_SETTINGS)].map(([vKey, val]) => ({ vKey, val }));

  const [selectableInNewsfeedModal, setSelectableInNewsfeedModal] = useState({ isOpen: false });
  const [imageExists, setImageExists] = useState(false);

  const { newsfeed_popup } = useAppSelector((state) => state.common);

  const kreiseImages =
    typeof kreise?.photo === 'string' && kreise?.photo?.length
      ? [{ url: kreise.photo }]
      : Array.isArray(kreise?.photo)
      ? kreise?.photo
      : //@ts-ignore
      kreise?.photo?.length
      ? [kreise.photo]
      : null;

  useEffect(() => {
    if (kreiseImages) {
      checkIfImageExists(kreiseImages?.[0]?.url, (exists) => setImageExists(exists));
    } else setImageExists(false);
  }, [kreise?.photo]);

  const focusHandler = (name: 'name' | 'description'): void => {
    setState((c) => {
      const newState = {
        ...c,
        [name]: {
          ...c[name],
          isFocused: !c[name].isFocused,
        },
      };
      if (!newState[name].isFocused && newState[name].value !== kreise.name) {
        dispatch(
          editKreise({
            kreiseId,
            payload: { [name]: newState[name].value },
          }),
        );
      }
      return newState;
    });
  };

  const changeSettings = async (
    period: 9999 | 0 | 12,
    type: 'email' | 'push' | 'newUser' | 'statistic',
    subscriptionType?: keyof typeof SUBSCRIPTION_TYPE,
  ) => {
    try {
      setLoading(true);
      await setKreiseSettings(period, kreise.id, type, subscriptionType);
      dispatch(getOwnerProfileRequest(user._id));
      setLoading(false);
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      dispatch(showResponse({}));
    }
  };

  useEffect(() => {
    getUsers({ clear: true });
    return () => {
      dispatch(clearKreiseSubscribers());
      dispatch(mediaActions.deleteAllAttachments());
    };
  }, []);

  useEffect(() => {
    if (editMode && kreiseImages) {
      dispatch(
        mediaActions.setEditingAttachments({
          // @ts-ignore
          images: kreiseImages,
          documents: [],
          videos: [],
        }),
      );
    }
  }, [editMode]);

  useEffect(() => {
    if (kreiseId) {
      dispatch(getKreiseById(kreiseId));
    }
  }, [kreiseId]);

  useEffect(() => {
    if (editMode && images?.every((el) => el.url)) uploadImages();
  }, [images.every((el) => el?.url), images.length]);

  useEffect(() => {
    if (kreise && pageNumber === 1) {
      setState({
        ...state,
        name: { value: kreise.name, isFocused: false },
        description: {
          value: kreise.description,
          isFocused: false,
        },
      });
      dispatch(
        getKreiseSubscribers({
          kreiseName: kreise.name,
          page: 1,
          limit: 10,
        }),
      );
      setPageNumber(2);
    }
  }, [kreise]);

  const getSubscribersHandler = () => {
    if (!kreiseSubscribersIsLoading) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);

      if (pageNumber > 1 && !kreiseSubscribersIsLoading) {
        dispatch(
          getKreiseSubscribers({
            kreiseName: kreise.name,
            page: pageNumber,
            limit: 10,
          }),
        );
      }
    }
  };

  const addMember: (value: any, IName?: string) => void = (value) => {
    if (showMembersModal.members)
      dispatch(
        addKreiseUser({
          kreiseId,
          userId: value._id,
          user: value,
          adminId: user._id,
        }),
      );
    if (showMembersModal.admins)
      dispatch(
        addKreiseAdmin({
          kreiseId,
          userId: value._id,
          user: value,
        }),
      );
  };

  const removeMember: (value: any, type: 'member' | 'admin') => void = (value, type: 'member' | 'admin') => {
    const username = value.name + ' ' + value.lastname;

    if (type === 'member')
      dispatch(
        showResponse({
          message: `"${username}" ${LkreiseSettings.deleteMemberDescr}`,
          title: LkreiseSettings.deleteKreiseModalTitle,
          cancelBtnLabel: btn.declineReqBtn,
          options: [
            <Button
              label={LkreiseSettings.deleteMemberBtn}
              onClick={() => {
                dispatch(hideResponse());
                dispatch(
                  removeKreiseUser({
                    kreiseId,
                    userId: value._id,
                  }),
                );
              }}
            />,
          ],
        }),
      );

    if (type === 'admin')
      dispatch(
        showResponse({
          // message: `Admin "${username}" ${LkreiseSettings.deleteMemberDescr}`,
          message: `Möchtest du dem Admin "${username}" nur die Admin-Rechte entziehen oder ihn ganz aus der Gruppe entfernen?`,
          title: LkreiseSettings.deleteKreiseModalTitle,
          cancelBtnLabel: btn.declineReqBtn,
          options: [
            // delete admin
            <Button
              label={LkreiseSettings.retractAdminRights}
              onClick={() => {
                dispatch(hideResponse());
                dispatch(
                  removeKreiseAdmin({
                    kreiseId,
                    userId: value._id,
                  }),
                );
              }}
            />,
            // delete user
            <Button
              label={LkreiseSettings.deleteAdminAndUser}
              onClick={() => {
                dispatch(hideResponse());
                dispatch(
                  removeKreiseUser({
                    kreiseId,
                    userId: value._id,
                  }),
                );
              }}
            />,
          ],
        }),
      );
  };

  const SwithComp: FC<{
    type: 'email' | 'push' | 'newUser' | 'statistic';
  }> = ({ type }) => {
    const isTurnedOff = (value) => {
      if (value === 'off' || value === false) return true;
      return false;
    };

    const combined = {
      ...kreiseSettings,
      ...settings,
    };

    let options = [...Object.entries(SUBSCRIPTION_TYPE)].map(([vKey, val]) => ({ vKey, val }));
    if (type === 'newUser') options = newUserSettings;
    if (type === 'statistic') options = statisticSettings;
    if (type === 'push') options.splice(3);
    let selected:
      | keyof typeof SUBSCRIPTION_TYPE
      | keyof typeof NEW_USER_SETTINGS
      | keyof typeof STATISTIC_SETTINGS
      | '' = '';
    const disabled = kreise.notificationSettings?.[type] === 'always_on';
    if (type) {
      if (isTurnedOff(combined?.[type])) selected = 'forever';
      if (combined?.[type] === 'on') selected = 'SINGLE';
      if (combined?.[type] === 'always_on') selected = 'SINGLE';
      if (type === 'newUser') selected = 'OFF';
      if (type === 'statistic') selected = 'off';
    }

    if (combined) {
      const diff = combined?.[type];
      if (diff?.length) {
        if (type === 'statistic') selected = combined?.[type];
        if (type === 'newUser') selected = combined?.[type]?.toUpperCase();
      }

      const diffInYears = moment().diff(combined?.[type], 'years');
      if (type !== 'newUser' && type !== 'statistic') {
        // if (!diff) selected = 'SINGLE';
        if (diffInYears < 0 && diff) selected = 'forever';
        if (diffInYears >= 0 && diff) selected = 'oneYear';
        if (diff === 'summary-once') selected = 'SUMMARY_ONCE';
        if (diff === 'summary-twice') selected = 'SUMMARY_TWICE';
        if (diff === 'summary-day') selected = 'SUMMARY_DAY';
      }
    }

    return (
      <>
        <span className={styles.header}>{LkreiseSettings[type]}</span>
        <Select
          input={<BootstrapInput />}
          value={options.find((el) => el.vKey == selected)?.val || 'single'}
          style={{ width: 280, margin: '10px 0' }}
          onChange={(e: any) => {
            const {
              target: { value },
            } = e;
            // 0 - for nothing (dumb Eugene)
            if (Number.isInteger(Number(value))) changeSettings(value, type);
            if (!Number.isInteger(Number(value))) changeSettings(0, type, value);
          }}
          // disabled={!emailActive}
          disabled={disabled}
        >
          {options.map(({ val, vKey }) => (
            <MenuItem
              key={vKey}
              //@ts-ignore
              value={val}
            >
              {LkreiseSettings[vKey]}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const turnOnEditMode = () => {
    setEditMode((c) => {
      if (c) uploadImages();
      return !c;
    });
    // if (!editMode) dispatch(showResponse({ message: LkreiseSettings.toKreiseEditMessage }))
  };

  const showMembersEditModal = (type: 'admins' | 'members') => {
    if (editMode) {
      setShowMembersModal({
        ...showMembersModal,
        [type]: true,
      });
      return;
    }
  };

  const getUsers = ({ clear = false, query = '' }: { clear?: boolean; query?: string }) => {
    let finalSearchkey = query !== undefined ? query : membersInput;
    dispatch(
      searchRequest({
        filter: 'name',
        page: 1,
        clear,
        limit: 50,
        searchkey: finalSearchkey,
      }),
    );
  };

  const getMoreUsers = () => {
    if (isLoading) return;

    dispatch(
      searchRequest({
        filter: 'name',
        page: usersPage,
        clear: false,
        limit: 50,
      }),
    );

    setUsersPage((prev) => prev + 1);
  };

  const handleDeleteKreise = () => {
    dispatch(hideResponse());
    dispatch(deleteKreise({ kreiseId }));
    history.push('/kreise');
    return;
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setMembersInput(value);
    onChangeDebounce(value);
  };

  const onChangeDebounce = useCallback(
    debounce((value: string) => {
      getUsers({ query: value, clear: true });
    }, 500),
    [],
  );

  const DeleteKreiseButton = () => {
    if (user._id !== kreise.creator) return <></>;

    return (
      <ButtonBase
        className={styles.header}
        onClick={() => {
          if (
            kreiseSubscribersAmount === 0 ||
            (kreiseSubscribersAmount === 1 && kreiseSubscribers[0].id._id === user._id)
          ) {
            dispatch(
              showResponse({
                message: LkreiseSettings.deleteKreiseDescr,
                cancelBtnLabel: btn.declineReqBtn,
                title: LkreiseSettings.toDeleteKreiseBtn + '?',
                options: [<Button label={btn.clearBtn} onClick={handleDeleteKreise} />],
              }),
            );
            return;
          }

          if (kreiseSubscribersAmount !== 0)
            dispatch(
              showResponse({
                message: LkreiseSettings.kreiseDeleteMembersLeft,
                title: LkreiseSettings.kreiseDeleteMembersLeftTitle,
              }),
            );
        }}
      >
        <IonIcon name={'trash-outline'} size={24} color={theme.ERROR_PRIMARY} />
        {LkreiseSettings.toDeleteKreiseBtn}
      </ButtonBase>
    );
  };

  const selectedSuggestions = kreise?.suggestionInNewsfeed
    ? Object.entries(kreise.suggestionInNewsfeed)
        .map(([key, val]) => val && { _id: key, name: LkreiseSettings.suggestionInNewsfeed[key] })
        .filter((val) => val)
    : null;

  const uploadImages = async () => {
    const payload = trimMedia({ images });
    dispatch(editKreise({ kreiseId, payload: { photo: payload.images } }));
    toast.success(messages.changesSaved);
  };

  return (
    <Layout>
      <BackButton
        title={kreise?.name}
        onClick={history.goBack}
        additionalButton={
          isAdmin && (
            <ButtonBase
              className={styles.header}
              onClick={turnOnEditMode}
              disabled={!isAdmin}
              style={{
                color: theme.ACTIVE_INPUT,
                height: 30,
                width: 30,
                borderRadius: 30,
              }}
            >
              {!editMode ? (
                <div
                  style={{
                    color: theme.BACKGROUND_PRIMARY,
                    backgroundColor: theme.ACTIVE_INPUT,
                    width: 30,
                    height: 30,
                    borderRadius: 30,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <EditIcon color="inherit" />
                </div>
              ) : (
                <IonIcon
                  name={'checkmark'}
                  size={20}
                  color={theme.BACKGROUND}
                  style={{
                    backgroundColor: theme.ACTIVE_INPUT,
                    borderRadius: 40,
                    padding: 5,
                  }}
                />
              )}
            </ButtonBase>
          )
        }
      />
      {kreise ? (
        <div className={styles.container}>
          {kreise?.onlyAdminsPost && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: theme.TEXT_SECONDARY,
              }}
            >
              <IonIcon name="information-circle-outline" size={24} />
              <span>{circles.readOnlyKreise}</span>
            </div>
          )}
          {editMode && (
            <>
              <span className={styles.header}>{Lsearch.name}</span>
              <Input
                placeholder={undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState((c) => ({
                    ...c,
                    name: {
                      ...c.name,
                      value: e.target.value,
                    },
                  }))
                }
                isFocused={state.name.isFocused}
                onBlur={() => focusHandler('name')}
                onFocus={() => focusHandler('name')}
                value={state.name.value}
              />
            </>
          )}
          {(!!kreise?.description?.length || editMode) && (
            <>
              <span className={styles.header}>{LkreiseSettings.description}</span>
              {editMode ? (
                <Textarea
                  placeholder={undefined}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setState((c) => ({
                      ...c,
                      description: {
                        ...c.description,
                        value: e.target.value,
                      },
                    }))
                  }
                  // isFocused={state.description.isFocused}
                  onBlur={() => focusHandler('description')}
                  onFocus={() => focusHandler('description')}
                  value={state.description.value}
                  className="min-h-24"
                />
              ) : (
                <Content content={kreise?.description} className={styles.description} />
              )}
            </>
          )}
          {editMode ? (
            <div className="mt-8">
              <RenderStateAttachments
                viewType="column"
                currentAttachments={{
                  videos: [],
                  documents: [],
                  images: images,
                }}
              />
              {!attachmentsLoading && (
                <UploadMedia addPDFDisabled addVideoDisabled viewType="dropzone" multiple={false} />
              )}
            </div>
          ) : imageExists ? (
            <div style={{ padding: '20px 0' }}>
              <PostGallery images={kreiseImages} />
            </div>
          ) : null}
          {kreiseSettings?.push && kreiseSettings?.push !== 'always_on' && <SwithComp type="push" />}
          {kreiseSettings?.email && kreiseSettings?.email !== 'always_on' && <SwithComp type="email" />}

          <SwithComp type="statistic" />
          <SwithComp type="newUser" />
          {/* SUGGESTION IN NEWSFEED, for better times :) */}
          {/* {newsfeed_popup && (
            <div style={{ width: 280, marginTop: 10 }}>
              <span className={styles.header}>Suggestion options</span>
              <div style={{ height: 15 }} />
              <TouchableInput
                value={selectedSuggestions?.map((el) => el.name).join(', ') || 'No values selected'}
                onClick={() => setSelectableInNewsfeedModal((c) => ({ ...c, isOpen: true }))}
              />
            </div>
          )} */}

          {!!kreise?.admins?.length && (
            <>
              <span className={styles.header}>
                Admins: {kreise?.admins?.length}
                {editMode && (
                  <ButtonBase onClick={() => showMembersEditModal('admins')} style={{ marginLeft: 10 }}>
                    <IonIcon name="add-circle" size={24} />
                  </ButtonBase>
                )}
              </span>
              {kreise.admins.map((item, idx) => {
                const userObj = {
                  ...item,
                  //@ts-ignore
                  name: item?.firstname || item.name,
                };

                return (
                  <div
                    key={item._id + idx}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <KreiseSubscriber
                      // @ts-ignore
                      subscriber={userObj}
                      openInNewWindow={editMode}
                      additionalInfo={item._id === kreise.creator && <strong>Ersteller:in dieses Gruppenchats</strong>}
                      renderCity={false}
                      renderCorp={true}
                    />
                    {user._id !== item._id && editMode && (
                      <ButtonBase onClick={() => removeMember(userObj, 'admin')}>
                        <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                      </ButtonBase>
                    )}
                  </div>
                );
              })}
            </>
          )}
          <span className={styles.header}>
            {LkreiseSettings.subscribers}: {kreiseSubscribersAmount}
            {editMode && (
              <ButtonBase onClick={() => showMembersEditModal('members')} style={{ marginLeft: 10 }}>
                <IonIcon name="add-circle" size={24} />
              </ButtonBase>
            )}
          </span>
          {kreiseSubscribers.length >= kreiseSubscribersAmount && kreiseSubscribersIsLoading ? (
            <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
          ) : (
            <InfiniteScroll
              threshold={50}
              initialLoad={false}
              pageStart={0}
              loadMore={getSubscribersHandler}
              hasMore={!(kreiseSubscribers.length >= kreiseSubscribersAmount) && pageNumber !== 1}
              loader={
                <div style={{ textAlign: 'center' }} key={50}>
                  <CircularProgress
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                    size={25}
                  />
                </div>
              }
              useWindow={true}
            >
              {kreiseSubscribers &&
                kreiseSubscribers.length > 0 &&
                kreiseSubscribers.map((subscriber: any, i: number) => {
                  const userObj = {
                    ...subscriber.id,
                    name: subscriber.id.firstname || subscriber.id.name,
                  };

                  return (
                    <div
                      key={subscriber._id + i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <KreiseSubscriber subscriber={userObj} openInNewWindow={editMode} />
                      {user._id !== subscriber.id._id && editMode && (
                        <ButtonBase onClick={() => removeMember(userObj, 'member')}>
                          <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                        </ButtonBase>
                      )}
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
          <DeleteKreiseButton />
        </div>
      ) : (
        <Loader isLoading={true} />
      )}
      <Loader isLoading={loading} />
      <SearchableModal
        onClick={() =>
          setShowMembersModal({
            admins: false,
            members: false,
          })
        }
        modalTitle={searchModal.pleaseSelect}
        setValueHandler={() =>
          setShowMembersModal({
            admins: false,
            members: false,
          })
        }
        modalOpen={showMembersModal.admins || showMembersModal.members}
        value={membersInput}
      >
        <MultipleSearchableInput
          data={data}
          values={
            showMembersModal.members
              ? kreiseSubscribers.map((el) => el.id)
              : showMembersModal.admins
              ? //@ts-ignore
                kreise.admins.map((el) => el)
              : []
          }
          value={membersInput}
          onChange={onChangeInput}
          onSelect={addMember}
          deleteItemHandler={(item) => removeMember(item.id || item, showMembersModal.admins ? 'admin' : 'member')}
          currentName=""
          disableUnselect={true}
          hasMore={data.length < count && !isLoading}
          paginated
          loadMore={getMoreUsers}
          loading={isLoading}
        />
      </SearchableModal>
      {/* SUGGESTION IN NEWSFEED, for better times :) */}
      {/* {selectableInNewsfeedModal.isOpen && (
        <SearchModal
          isOpen={selectableInNewsfeedModal.isOpen}
          handleClose={() => setSelectableInNewsfeedModal((c) => ({ ...c, isOpen: false }))}
          data={SELECTABLE_IN_NEWSFEED.map((el) => ({ _id: el, name: LkreiseSettings.suggestionInNewsfeed[el] }))}
          preselectedValues={selectedSuggestions}
          onConfirm={(c) => {
            let newValue = { admin: false, corp: false, corpAdmin: false, member: false };

            c.forEach((el) => {
              newValue[el._id] = true;
            });

            dispatch(
              editKreise({
                kreiseId,
                payload: { suggestionInNewsfeed: newValue },
              }),
            );
            setSelectableInNewsfeedModal((c) => ({ ...c, isOpen: false }));
          }}
          maxSelectedCount={9999}
          allowEmpty={true}
        />
      )} */}
    </Layout>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontFamily: 'Roboto',
    fontSize: 20,
    color: theme.TEXT_PRIMARY,
    fontWeight: 'bolder',
    padding: '10px 0',
    alignSelf: 'flex-start',
  },
  error: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: theme.TEXT_SECONDARY,
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: 14,
    marginVertical: 10,
    whiteSpace: 'pre-wrap',
    color: theme.TEXT_PRIMARY,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  switchText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: theme.TEXT_PRIMARY,
    marginLeft: 10,
  },
  img: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    backgroundSize: 'cover',
    cursor: 'pointer',
    objectFit: 'cover',
  },
});

const period_type = {
  // active: 0,
  oneYear: 12,
  forever: 9999,
};

const SUBSCRIPTION_TYPE = {
  SINGLE: 'single',
  oneYear: 12,
  forever: 9999,
  SUMMARY_DAY: 'summary-day',
  SUMMARY_TWICE: 'summary-twice',
  SUMMARY_ONCE: 'summary-once',
};

const NEW_USER_SETTINGS = {
  OFF: 'off',
  PUSH: 'push',
  EMAIL: 'email',
  PUSH_AND_EMAIL: 'push_and_email',
};

// I have several question to backend due to namings (camelCase/screaminCase)
const STATISTIC_SETTINGS = {
  off: 'off',
  ownPosts: 'ownPosts',
};

const SELECTABLE_IN_NEWSFEED = ['admin', 'corp', 'corpAdmin', 'member'];

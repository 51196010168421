import React from 'react';
import { Config, withConfig } from '../../../config/';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { DrawerFooterProps } from '../model';
import { ReactComponent as InfoIcon } from '../assets/info_button.svg';
import DrawerLogOut from './DrawerLogOut';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px 20px',
    boxSizing: 'border-box',
    borderRadius: 10,
  },
  logoWrapper: {
    // width: '20px',
  },
  logo: {
    // width: '100%',
  },
  button: {
    borderRadius: '50%',
  },
  icon: {
    fontSize: 25,
  },
  '@media(max-width: 1024px)': {
    logoWrapper: {
      width: '20%',
    },
  },
  '@media(max-width: 650px)': {
    logoWrapper: {
      width: '10%',
    },
  },
});

interface Props extends WithStyles<typeof styles>, DrawerFooterProps, RouteComponentProps<any> {
  config: Config;
  handleLogOut?: () => void;
}

const DrawerFooter: React.FC<Props> = ({ classes, theme, history, config, handleLogOut }) => {
  const logoImg = require(`../../../assets/logo/logo_${config.APP_NAME}.png`);
  return (
    <div className={classes.container} style={{ background: theme.BACKGROUND }}>
      <div className={classes.logoWrapper}>
        <DrawerLogOut handleLogOut={handleLogOut} text=" " />
      </div>
      <ButtonBase className={classes.button} onClick={() => history.push('/app-info')}>
        <InfoIcon style={{ color: theme.BUTTON_ICON }} width={25} height={25} />
      </ButtonBase>
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(DrawerFooter)));

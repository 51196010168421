import { Button } from 'components/shadcn/button';
import { Checkbox } from 'components/shadcn/checkbox';
import { Label } from 'components/shadcn/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/select';
import { FormInput } from 'components/UI/FormInput';
import { IonIcon } from 'components/UI/IonIcon';
import { FormikErrors, FormikTouched } from 'formik';
import { useLanguage } from 'languages/languageContext';
import { useEffect } from 'react';
import { cn } from 'utilities/utils';

export type TEventSurvey = {
  _id?: string;
  type: 'single' | 'multi' | 'freeText' | 'quiz';
  text: string;
  hint?: string;
  isAnonym?: boolean;
  options: {
    correctAnswer?: boolean;
    title: string;
    _id?: string;
    voters?: {
      user:
        | {
            _id: string;
            name: string;
            lastname: string;
            photo: string;
          }
        | string;
    }[];
  }[];
};

type Props = {
  values: TEventSurvey;
  errors: FormikErrors<TEventSurvey>;
  touched: FormikTouched<TEventSurvey>;
  handleChange: any;
  handleBlur: any;
  idx: number;
  setValues: (v: TEventSurvey) => void;
  surveyIdx: number;
  survey: TEventSurvey;
  namePrefix: string; // parts[${idx}]
  addNewOption: () => void;
  parentComponent: 'eventPart' | 'post' | 'comments';
};

const surveyTypes = ['single', 'multi', 'freeText', 'quiz'];

const Survey = (props: Props) => {
  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    setValues,
    surveyIdx,
    survey,
    namePrefix,
    addNewOption,
    parentComponent,
  } = props;
  const { eventLabels, btn } = useLanguage();

  const setCorrectAnswer = (optionIdx: number) => {
    values.options.forEach((opt) => (opt.correctAnswer = false));
    values.options[optionIdx].correctAnswer = true;
    setValues({ ...values });
  };

  useEffect(() => {
    if (parentComponent === 'eventPart') return;

    if (values.type === 'quiz') {
      setValues({ ...values, isAnonym: true });
    } else {
      setValues({ ...values, isAnonym: false });
    }
  }, [values.type]);

  return (
    <div>
      <FormInput
        name={`${namePrefix ? namePrefix + '.' : ''}questions[${surveyIdx}].text`}
        placeholder={eventLabels.parts.question}
        value={survey.text}
        onBlur={handleBlur}
        onChange={handleChange}
        // @ts-ignore
        error={errors?.text}
        touched={touched?.text}
      />

      <Select
        onValueChange={(e: TEventSurvey['type']) => {
          values.type = e;
          setValues({ ...values });
        }}
        value={survey.type}
      >
        <Label className="ml-3 mb-1" htmlFor={'visibility'}>
          {eventLabels.parts.questionnaireType}
        </Label>
        <SelectTrigger className="rounded-full h-10 mb-3 bg-white">
          <SelectValue placeholder={eventLabels.parts.questionnaireType} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {surveyTypes.map((el) => {
              if (!el.length) return <></>;
              if (parentComponent === 'post' && el === 'freeText') return <></>;
              if (parentComponent === 'eventPart' && el === 'quiz') return <></>;
              return (
                <SelectItem key={el} value={el}>
                  {eventLabels.parts[el]}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>

      {survey.type !== 'freeText' && (
        <div className="mb-2">
          {survey.options.map((option, optionIdx) => (
            <div className="relative flex flex-row items-center">
              {survey.type === 'quiz' && (
                <button
                  onClick={() => setCorrectAnswer(optionIdx)}
                  className={cn(
                    'w-7 h-7 rounded-full border-[1.5px] mt-2 mr-2 items-center flex justify-center',
                    option.correctAnswer && 'border-primary-btn',
                  )}
                >
                  {option.correctAnswer && <IonIcon name="checkmark" size={22} />}
                </button>
              )}
              <div className="flex-1">
                <FormInput
                  name={`${namePrefix ? namePrefix + '.' : ''}questions[${surveyIdx}].options[${optionIdx}].title`}
                  placeholder={eventLabels.parts.option + ' ' + (optionIdx + 1)}
                  value={option.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // @ts-ignore
                  error={errors?.options?.[optionIdx]?.title}
                  touched={touched?.options?.[optionIdx]?.title}
                />
                {survey?.options?.length > 2 && (
                  <button
                    onClick={() => {
                      values.options.splice(optionIdx, 1);
                      setValues({ ...values });
                    }}
                    className="absolute right-2 top-8 z-10 duration-75 hover:opacity-50"
                  >
                    <IonIcon name="close-circle" color="#ccc" size={24} />
                  </button>
                )}
              </div>
            </div>
          ))}
          <Button variant="text" size="text" className="justify-start" onClick={addNewOption}>
            {eventLabels.parts.addOption}
          </Button>
        </div>
      )}
      <div className="flex items-center gap-x-2 mt-4">
        <Checkbox
          checked={values.isAnonym}
          onClick={() => {
            values.isAnonym = !values.isAnonym;
            setValues({ ...values });
          }}
        />
        <Label>{values.type === 'quiz' ? eventLabels.parts.isAnonymQuiz : eventLabels.parts.isAnonym}</Label>
      </div>
    </div>
  );
};

export default Survey;

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';

import { withConfig, Theme } from 'config';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
    borderRadius: 10,
    position: 'relative',
    boxSizing: 'border-box',
    marginBottom: 10,
    cursor: 'pointer',
  },
  editIconWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 15,
    borderRadius: '50%',
    zIndex: 9999,
  },
  titleWrapper: {
    maxWidth: '80%',
  },
  descItem: {
    marginBottom: 5,
  },
  descProp: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  descVal: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  title: {
    margin: '10px 0 20px',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  event: any;
}

const BirthdayItem: React.FC<Props> = ({ classes, theme, event: user, history }) => {
  const toProfileDetailsHandler = (user) => {
    history.push(`/profile-details/${user.id}`, { userId: user.id });
  };
  const age = user.age && user.age;

  return user ? (
    <div className={classes.container} style={{ background: theme.BACKGROUND_PRIMARY }}>
      <div className={classes.titleWrapper} onClick={() => toProfileDetailsHandler(user)}>
        <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
          {age}. Geburtstag von {user.name} {user.lastname}
        </h3>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withRouter(withConfig(BirthdayItem)));

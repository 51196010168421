import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import { getConfig } from '../../config/config';
import { useLanguage } from '../../languages/languageContext';
import { CheckboxUI } from './CheckboxUI';
import TouchableInput from './TouchableInput';

type Props = {
  value: string;
  data: { label: string; onPress: () => void; selected: boolean }[];
  disabled?: boolean;
};

export const Dropdown: FC<Props> = (props) => {
  const { btn } = useLanguage();
  const { value = '', data, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles({});

  return (
    <div className={styles.item}>
      <TouchableInput
        placeholder={`Status ${btn.select.toLowerCase()}`}
        label={`Status ${btn.select.toLowerCase()}`}
        onClick={() => setIsOpen(!isOpen)}
        value={value}
        isOpen={isOpen}
        disabled={disabled}
      />
      {isOpen && (
        <div className={styles.dropdown}>
          {data?.map((item, idx) => (
            <CheckboxUI
              label={item.label}
              isChecked={item.selected}
              setIsChecked={() => {
                setIsOpen(false);
                item.onPress();
              }}
              wrapperStyle={{
                //@ts-ignore
                padding: 8,
                border: 'solid',
                borderColor: 'transparent',
                marginBottom: 0,
                borderBottomColor: theme.BUTTON_SECONDARY,
                borderBottomWidth: idx === data.length - 1 ? 0 : 0.5,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  item: {
    marginBottom: 20,
  },
  dropdown: {
    alignSelf: 'flex-end',
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderColor: theme.BACKGROUND_SECONDARY,
    borderWidth: 1,
    border: 'solid',
    position: 'relative',
    overflow: 'visible',
    borderRadius: 8,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    top: -15,
    marginLeft: '30%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 15px -1px rgba(0,0,0,0.5)',
    zIndex: 2,
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
});

import {
  GET_CORPS_NAMES_REQUEST,
  GET_CORPS_NAMES_SUCCESS,
  GET_CORPS_NAMES_FAILURE,
  SET_INITIAL_CORP,
  CLEAR_INITIAL_CORP,
  GET_CORPS_SUBS_REQUEST,
  GET_CORPS_SUBS_SUCCESS,
  GET_CORPS_SUBS_FAILURE,
} from '../../constants';

const initialState = {
  corpsNames: null,
  corpsNamesIsLoading: false,
  corpsError: null,
  initialCorp: null,
  corpsSubs: [],
  corpsSubsIsLoading: false,
};

export const corps = (state = initialState, action) => {
  switch (action.type) {
    case GET_CORPS_NAMES_REQUEST:
      return {
        ...state,
        corpsNamesIsLoading: true,
      };
    case GET_CORPS_NAMES_SUCCESS:
      return {
        ...state,
        corpsNamesIsLoading: false,
        corpsNames: action.corpsNames,
      };
    case GET_CORPS_NAMES_FAILURE:
      return {
        ...state,
        corpsNamesIsLoading: false,
        error: action.error,
      };
    case SET_INITIAL_CORP:
      return {
        ...state,
        initialCorp: action.corp,
      };
    case CLEAR_INITIAL_CORP:
      return {
        ...state,
        initialCorp: null,
      };
    // GET CORPS SUBS
    case GET_CORPS_SUBS_REQUEST:
      return {
        ...state,
        corpsSubsIsLoading: true,
      };
    case GET_CORPS_SUBS_SUCCESS:
      return {
        ...state,
        corpsSubsIsLoading: false,
        corpsSubs: action.corpsSubs,
      };
    case GET_CORPS_SUBS_FAILURE:
      return {
        ...state,
        corpsSubsIsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

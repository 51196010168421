import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { getConfig } from "config/config";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { globalSearchResponseType } from "store/reducers/globalSearch";
const defaultAvatar = require('../../../../../assets/default_avatar.png')


type Props = {
  user: globalSearchResponseType['users']['data']['0']
  query?: string
}

export const UserItem: FC<Props> = (props) => {
  const { user, query } = props
  const { name, lastname } = user
  const history = useHistory()
  const styles = useStyles({})

  return <ButtonBase className={styles.container} onClick={() => history.push(`/profile-details/${user._id}`)}>
    <img src={user.photo || defaultAvatar} alt='Avatar' className={styles.img} />
    <div className={styles.infoWrapper}>
      <span className={styles.name}>{name} {lastname}</span>
    </div>
  </ButtonBase>
}

const { theme } = getConfig()
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    width: `calc(100% - 20px)`,
    justifyContent: 'flex-start'
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: 60,
    border: '2px solid',
    borderColor: theme.ICON_BORDER,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  field: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  foundBy: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  }
})
import React, { useState, useEffect } from 'react';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig } from '../../config/';
import { SelectModalProps } from '../model';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 20,
  },
  modalWrapper: {
    position: 'relative',
    width: 362,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 20,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    margin: 0,
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    height: 400,
    width: '100%',
    overflow: 'scroll',
  },
  scroll: {
    padding: '15px 25px',
    margin: 0,
  },
  scrollItem: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    listStyleType: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '25px',
    cursor: 'pointer',
    padding: '5px 0',
    borderRadius: 5,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, SelectModalProps {}

const SelectModal: React.FC<Props> = ({
  classes,
  theme,
  modalTitle,
  modalIsOpen,
  selectItemHandler,
  modalHandler,
  data,
  modalName,
  multiple,
  ...props
}) => {
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalIsOpen]);

  return modalIsOpen ? (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {modalTitle}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={modalHandler} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
          <ul className={classes.scroll}>
            {data.map((item: any, i) => {
              if (!item) return null;
              const value = item.label || item;
              const id = item.id || item;
              return (
                <div className={classes.item}>
                  {item?.icon}
                  <ButtonBase
                    className={classes.scrollItem}
                    key={`${id}${i}`}
                    onClick={() => {
                      selectItemHandler(value, modalName);
                      if (!multiple) modalHandler();
                    }}
                  >
                    {value}
                  </ButtonBase>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={classes.backdrop} onClick={modalHandler}></div>
    </div>
  ) : null;
};

export default withStyles(styles)(withConfig(SelectModal));

import { RootState } from './../index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mentoringGetData, mentoringGetRequests } from '../../api/mentoring';

export const getMentoringData = createAsyncThunk('mentoring/getMentoringData', async () => await mentoringGetData());

export const getMentoringRequests = createAsyncThunk(
  'mentoring/getMentoringRequests',
  async () => await mentoringGetRequests(),
);

const initialState = {
  data: {
    mentoringRegions: [],
    topics: [],
    departments: [],
    isMentor: false,
    newsKreise: '',
  },
  requests: {
    incomingRequests: [],
    mentees: [],
    mentoringActive: false,
    mentors: [],
    outgoingRequests: [],
  },
  loading: false,
};

export const mentoringSlice = createSlice({
  name: 'mentoring',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMentoringData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMentoringData.fulfilled, (state, action) => {
      state.loading = false;
      // @ts-ignore
      state.data = action.payload;
    });
    builder.addCase(getMentoringData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMentoringRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMentoringRequests.fulfilled, (state, action) => {
      state.loading = false;
      // @ts-ignore
      state.requests = action.payload;
    });
    builder.addCase(getMentoringRequests.rejected, (state) => {
      state.loading = false;
    });
  },
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
  },
});

export const SelectMentoringState = (state: RootState) => state.mentoring;

export const mentoringActions = mentoringSlice.actions;

export const mentoring = mentoringSlice.reducer;

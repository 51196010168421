import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig } from '../../config/';
import { ProfileScaleProps } from '../model';
import { useSelector } from 'react-redux';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '60px 0 40px',
    borderRadius: 5,
  },
  scale: {
    position: 'relative',
    height: 40,
    // border: '1px solid',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#cfcfcf',
  },
  label: {
    position: 'relative',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
    color: '#fff',
    zIndex: 1,
  },
  scaleBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '40px',
    borderRadius: 5,
    zIndex: 0,
    transition: '.5s all',
  },
});

interface Props extends WithStyles<typeof styles>, ProfileScaleProps { }

const ProfileScale: React.FC<Props> = ({ theme, classes }) => {
  const { profileDetails } = useLanguage();
  const user = useSelector((state: any) => state.users.user);

  const checkProgressValue = () => {
    let progress = 0;

    if (user && user.name) {
      if (user.photo) progress += 10
      if (user.name) progress += 9
      if (user.lastname) progress += 9
      if (user.email) progress += 9
      if (user.birthday) progress += 9
      if (user.postcode) progress += 9
      if (user.wohnort) progress += 9
      // Beruf Tab (Total: 25%)
      if (!!user.beruf.length) progress += 9
      if (user.country) progress += 9

      const studiengang = user.education?.find(el => {
        return el?.studiengang?.length
      })
      const hochschule = user.education?.find(el => {
        return el?.hochschule?.length
      })
      if (studiengang) progress += 9
      if (hochschule) progress += 9
    }

    return progress;
  };

  const progressValue = checkProgressValue();

  return (
    <div className={classes.container}>
      <div className={classes.scale} style={{ borderColor: theme.ICON_PRIMARY }}>
        <span className={classes.label}>
          {profileDetails.profileCompletedLabel} {progressValue}% {profileDetails.profileCompletedGLabel}
        </span>
        <div
          className={classes.scaleBackground}
          style={{ background: theme.ICON_PRIMARY, width: `${progressValue}%` }}
        ></div>
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(ProfileScale));

import moment from 'moment';

const convertToString = (date: string | Date, format: 'DD.MM.YYYY' | 'MM.YYYY' = 'DD.MM.YYYY', fallback = '') => {
  const string = moment(date).format(format);
  if (string === 'Invalid date') return fallback;
  return string;
};

const convertToDate = (string: string | Date) => {
  if (typeof string == 'object') return string;
  if (!string.length || !string.includes('/')) return string;
  const [month, year] = string.split('/');

  const date = moment()
    .month(Number(month) - 1)
    .year(Number(year));

  return date;
};

export const dateToString = (
  date: string | Date = '',
  format: 'DD.MM.YYYY' | 'MM.YYYY' = 'DD.MM.YYYY',
  fallback = '',
) => {
  const formattedToDate = convertToDate(date);
  //@ts-ignore
  const formattedToString = convertToString(formattedToDate, format, fallback);
  return formattedToString;
};

import { client } from './client';

// GET COMMENTS
export const getComments = (postId: string, page: number, limit = 10) =>
  client.get(`/v2/comments/${postId}/${page}/${limit}`);

// ADD COMMENT
export const addComment = (comment: object) => client.post(`/v2/post/comment`, comment);

// DELETE COMMENT
export const deleteComment = (commentId: string) => client.delete(`/v2/post/comment/${commentId}`);

// GET EVENT COMMENTS
export const getEventComments = (
  eventId: string,
  page: number,
  limit: number,
): Promise<{ total: number; data: any[]; page: number }> => client.get(`/events/${eventId}/comments/${page}/${limit}`);

// DELETE EVENT COMMENT
export const deleteEventComment = (eventId: string, commentId: string) =>
  client.delete(`/events/${eventId}/comment/${commentId}`);

// ADD EVENT COMMENT
export const addEventComment = (eventId: string, comment: object) => client.post(`/events/${eventId}/comment`, comment);

export const surveyCommentsAddVote = (commentId: string, optionId: string) =>
  client.post(`/v2/post/comment/${commentId}/vote/${optionId}`);

export const surveyCommentsRemoveVote = (commentId: string, optionId: string) =>
  client.delete(`/v2/post/comment/${commentId}/vote/${optionId}`);

export const surveyCommentsGetVotes = (commentId: string, optionId: string, page: number, limit = 5) =>
  client.get(`/v2/post/comment/${commentId}/list-vote/${optionId}?limit=${limit}&page=${page}`);

export const addVoteComments = (commentId: string, questionId: string, optionId: string) =>
  client.post(`v2/post/comment/${commentId}/vote-new/${questionId}/${optionId}`);

export const removeVoteComments = (commentId: string, questionId: string, optionId: string) =>
  client.delete(`v2/post/comment/${commentId}/vote-new/${questionId}/${optionId}`);

export const listVotesComments = (commentId: string, optionId: string): Promise<any[]> =>
  client.get(`v2/post/comment/${commentId}/list-vote-new/${optionId}`);

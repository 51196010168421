import React, { useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { withConfig } from '../../config';
import { postImagePath, eventImagePath } from '../../utilities';
import ImageLightBox from './ImageLightBox/ImageLightBox';
import { getConfig } from 'config/config';
import { VideoPlayer } from './VideoPlayer';
import { ImageGrid } from 'components/UI/ImageGrid';
import { LayoutHook } from 'hooks';

const styles = createStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  galleryWrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
  },
  galleryBackdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    padding: '20px',
    borderRadius: '50px',
    zIndex: 0,
    background: 'rgba(0, 0, 0, .5)',
  },
  backdropTitle: {
    fontSize: 48,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: '800px',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '115%',
    },
  },
});

const { theme } = getConfig();

interface Props extends WithStyles<typeof styles> {
  images: Array<any>;
  commentImage: boolean;
  videos: any[];
}

const PostGallery: React.FC<Props> = ({ classes, images, commentImage = false, videos }) => {
  // const [width] = LayoutHook();
  // dont understand sense of it, replaced with undefined
  // const heightImagesContainer = width < 600 ? 400 : 500;
  const heightImagesContainer = undefined;
  const [showGallery, setShowGallery] = useState(false);
  const [galleryMode, setGalleryMode] = useState(false);
  const [imageIdx, setImageIdx] = useState<number>(0);

  let galleryImagesList: Array<any> = [];
  let imagesList: Array<any> = [];
  let imagesGallery;

  if (images && !commentImage) {
    imagesList = images.map((image) => image?.thumb || image?.url);
    galleryImagesList = images.map((image) => ({ url: postImagePath('full', image.url || image), ...image }));
  } else if (images && commentImage) {
    imagesList = images.map((image) => image?.thumb || image?.url);
    galleryImagesList = images.map((image) => eventImagePath(image));
  }

  const galleryHandler = (galleryMode: boolean, idx?: number) => {
    setShowGallery(!showGallery);
    setGalleryMode(galleryMode);

    setImageIdx(idx);
  };

  const closeGalleryHandler = () => {
    setShowGallery(false);
  };

  if (imagesList.length === 1) {
    imagesGallery = (
      <ButtonBase
        onClick={() => galleryHandler(false)}
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <div className={classes.galleryWrapper}>
          <img src={imagesList[0]} className={classes.image} />
        </div>
      </ButtonBase>
    );
  } else if (imagesList.length > 1) {
    imagesGallery = (
      <div style={{ height: heightImagesContainer, width: '100%', minWidth: 100 }}>
        <ImageGrid images={imagesList} onClick={(image: string, idx: number) => galleryHandler(false, idx)} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <ImageLightBox
        onClose={closeGalleryHandler}
        showGallery={showGallery}
        images={galleryImagesList}
        incomingIndex={imageIdx}
      />
      {imagesGallery}
      {!!videos?.length && videos[0]?.url && <VideoPlayer videos={videos} />}
    </div>
  );
};

export default withStyles(styles)(withConfig(PostGallery));

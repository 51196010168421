import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { BenachrichtigungenItemProps } from '../model';
import { withConfig } from '../../../config';
import CustomSwitch from '../../../components/UI/CustomSwitch';
import { useLanguage } from 'languages/languageContext';
import { IonIcon } from 'components/UI/IonIcon';

const styles = createStyles({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  titleWrapper: {
    flex: 2,
  },
  title: {
    fontSize: 14,
    marginRight: 30,
  },
  handlerWrapper: {
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 5,
  },
  text: {
    fontSize: 14,
  },
  switch: {
    transform: 'rotate(180deg)',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, BenachrichtigungenItemProps {
  handleOpenInfoModal?: () => void;
}

const BenachrichtigungenItem: React.FC<Props> = ({
  title,
  theme,
  optionName,
  notificationHandler,
  mailingNotifyOptionSettings,
  classes,
  disabled,
  handleOpenInfoModal = () => null,
}) => {
  const optionSettings = mailingNotifyOptionSettings;
  const { enabled, disabledTo } = optionSettings || {};
  const { profileNotifications } = useLanguage();

  const disabledDate = new Date(disabledTo).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  let newDisabledDate;

  if (!disabledTo) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    currentDate.setFullYear(currentYear + 999);

    newDisabledDate = new Date(currentDate).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  const toggleSwitch = () => {
    notificationHandler(optionName, { enabled: !enabled });
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <p className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
          {title}
        </p>
      </div>
      <div className={classes.handlerWrapper}>
        <p
          className={classes.text}
          style={
            disabled ? { color: '#ccc' } : enabled ? { color: theme.BUTTON_PRIMARY } : { color: theme.TEXT_PRIMARY }
          }
        >
          {profileNotifications.enableLabel}
        </p>
        <div className={classes.switch}>
          <CustomSwitch value={enabled} checked={enabled} onChange={toggleSwitch} disabled={disabled} />
        </div>
        <p
          className={classes.text}
          style={
            disabled ? { color: '#ccc' } : !enabled ? { color: theme.BUTTON_PRIMARY } : { color: theme.TEXT_PRIMARY }
          }
        >
          {profileNotifications.disableLabel}

          {/* {!enabled && optionName === 'ownPosts'
            ? profileNotifications.disableLabel
            : disabledTo
            ? `${profileNotifications.disableToLabel} ${disabledDate}`
            : !disabledTo && !enabled
            ? `${profileNotifications.disableToLabel} ${newDisabledDate}`
            : profileNotifications.disableLabel} */}
        </p>
        {!enabled && (optionName === 'messages' || optionName === 'comments') && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 10,
              cursor: 'pointer',
            }}
            onClick={handleOpenInfoModal}
          >
            <IonIcon name="warning" size={25} color="orange" />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(BenachrichtigungenItem)));

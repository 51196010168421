import React, { useRef, useEffect, useState, ReactElement } from 'react';
import { getConfig } from '../../config/config';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCommonState } from '../../store/reducers/common';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import { makeStyles } from '@material-ui/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@material-ui/core';
import { showResponse } from 'store/actions/response';

const { theme } = getConfig();

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  labelWrapper: {
    padding: '0 25px 0 25px',
    width: '100%',
    marginBottom: 5,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#353A54',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 9,
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
    marginBottom: 15,
  },
  input: {
    padding: '12px 25px 12px 25px',
    width: '100%',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    outline: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#353A54',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
  scrollWrapper: {
    height: '40vh',
    width: '100%',
    overflow: 'scroll',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    overflowX: 'hidden',
    marginTop: 10,
  },
  scroll: {
    padding: '15px 5px',
    margin: 0,
    // height: '40vh',
    // overflowY: 'auto',
    // width: '90%'
  },
  scrollItem: {
    listStyleType: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '25px',
    cursor: 'pointer',
  },
});

type Props = {
  values: { id: string | number; name: string }[] | { _id: string; name: string }[] | string[];
  deleteItemHandler: (value: any) => void;
  onSelect: (value: { name: string; id: string } | any, IName?: string) => void;
  disabled?: boolean;
  disableWithModal?: boolean;
  inputName?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentName?: string;
  data: any[];
  placeholder?: string;
  value: string;
  alleIsValue?: boolean;
  renderItem?: (item: any) => ReactElement;
  disableUnselect?: boolean;
} & PaginationProps;

type TNoPagination = {
  paginated?: false;
};

type TWithPagination = {
  paginated?: true;
  loadMore: () => void;
  hasMore: boolean;
  loading: boolean;
};

type PaginationProps = TWithPagination | TNoPagination;

export const MultipleSearchableInput: React.FC<Props> = ({
  value = '',
  onSelect,
  data,
  onChange,
  inputName,
  values,
  deleteItemHandler,
  disabled = false,
  disableWithModal = false,
  placeholder,
  alleIsValue,
  renderItem,
  disableUnselect,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const styles = useStyles({});
  const dispatch = useAppDispatch();
  const input = useRef<HTMLInputElement>(null);
  const { maximal_membership_count } = useAppSelector(selectCommonState);

  useEffect(() => {
    focus();
  }, []);

  const focus = () => {
    const elem = input.current!;
    elem.focus();
  };

  let filteredArr: { name: string }[] = [];
  if (!props.paginated && data.length > 0) {
    filteredArr = data.filter((item) => {
      const val = item.name || item;
      return val.toLowerCase().includes(value?.toLowerCase());
    });
  }
  if (props.paginated) filteredArr = data;

  return (
    <div className={styles.container}>
      <input
        ref={input}
        onChange={onChange}
        className={styles.input}
        style={{
          borderColor: active ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY,
          background: theme.BACKGROUND_PRIMARY,
        }}
        placeholder={placeholder}
        value={value}
        onFocus={() => setActive(!active)}
        onBlur={() => setActive(!active)}
      />
      <div
        className={styles.scrollWrapper}
        style={{
          borderColor: theme.BACKGROUND_SECONDARY,
          background: theme.BACKGROUND_PRIMARY,
        }}
        // onScroll={scrollHandler}
      >
        <InfiniteScroll
          threshold={50}
          initialLoad={false}
          pageStart={0}
          loadMore={props.paginated ? props.loadMore : undefined}
          hasMore={props.paginated ? props.hasMore : false}
          loader={
            <div style={{ textAlign: 'center' }} key={50}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
            </div>
          }
          useWindow={false}
        >
          {!!filteredArr.length && (
            <ul className={styles.scroll}>
              {filteredArr?.map((item: any) => {
                //@ts-ignore
                const itemId = item?._id || item?.id || item;
                const val: string = item?.name || item;
                //@ts-ignore
                let isSelected = !!values.find((el: any) => {
                  if (el?._id && el?._id === item?._id) return el;
                  if (el?.id && el?.id === item?.id) return el;
                  if (el?.name === val) return el;
                  if (el === val) return el;
                  // el?._id === item?._id || el?.id === item?.id || el?.name === val || el === val
                });
                const valueDisabled = disabled && !isSelected;
                const isAllSelected = values.length + 1 === data.length;
                if (val === 'Alle' && !alleIsValue) isSelected = isAllSelected;

                const handler =
                  valueDisabled && disableWithModal
                    ? () =>
                        dispatch(
                          showResponse({
                            message: `Bitte maximal ${maximal_membership_count} Mitgliedschaften angeben.`,
                          }),
                        )
                    : isSelected
                    ? () => deleteItemHandler(item)
                    : () => onSelect(item, inputName);

                return (
                  <CheckboxUI
                    key={itemId}
                    disabled={(valueDisabled && !disableWithModal) || (isSelected && disableUnselect)}
                    label={renderItem ? renderItem(item) : val}
                    isChecked={isSelected}
                    setIsChecked={handler}
                    //@ts-ignore
                    wrapperStyle={{ marginBottom: 0 }}
                  />
                );
              })}
            </ul>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

import { setConfig } from 'config';

export const imagePath = (type: string | null, path: string): string | null => {
  const { config } = setConfig();
  if (!path) return null;
  if (path.includes('http')) return path;

  let serverPath = type === 'full' ? '/avatars/full/' : '/avatars/';
  return config.SERVER_PATH + serverPath + path;
};

import React, { ChangeEvent, FC, useEffect } from 'react';
import DefaultModal from './DefaultModal';
import { Refresh, Stop } from '@material-ui/icons';
import Button from 'components/UI/Button';
import { ButtonBase } from '@material-ui/core';
import Loader from 'components/UI/Loader';
import { UserType } from 'store/types/usersTypes';
import { TextField } from 'scenes/Mentoring/components/TextField';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { subjectModalType } from './PostsToolbar';
import { privacyValueTypes } from 'scenes/ProfilePrivacy/ProfilePrivacy';

const { theme } = getConfig();

type Props = {
  subjectModal: subjectModalType;
  setSubjectModal: (c: subjectModalType) => void;
  user: UserType;
  onAddComment: ({ askForGuests }: { askForGuests?: privacyValueTypes }) => void;
  generateSubjectRequest: (force?: boolean) => void;
  abortSubjectSignal: () => void;
  kreiseName?: string;
};

export const SubjectModal: FC<Props> = (props) => {
  const { subjectModal, setSubjectModal, user, onAddComment, abortSubjectSignal, generateSubjectRequest, kreiseName } =
    props;
  const { btn } = useLanguage();

  useEffect(() => {
    if (user.subjectSuggestion === 'optional') setSubjectModal({ ...subjectModal, editable: true });
  }, []);

  const modalMessage =
    user.subjectSuggestion === 'standard' ? (
      'Wir generieren einen Betreff zu deinem Beitrag. Passt dieser Betreff?'
    ) : user.subjectSuggestion === 'optional' ? (
      <>
        Worum geht es in deinem Beitrag? Schreibe einen kurzen Betreff. Mithilfe von KI kannst du dir auch einen
        Vorschlag generieren lassen.
        <p style={{ fontSize: 12 }}>
          Durch die Nutzung dieser Funktion stimmst du den{' '}
          <a
            className="text-blue-500 underline hover:text-blue-600"
            href="https://openai.com/policies/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            AGB von ChatGPT
          </a>{' '}
          zu.
        </p>
      </>
    ) : (
      ''
    );

  return (
    <DefaultModal
      showModal={subjectModal.show}
      modalTitle={'Betreff zum Beitrag'}
      onClose={() => setSubjectModal({ ...subjectModal, show: false, shouldBeShown: true })}
      message={modalMessage}
    >
      <div style={{ padding: '0 12% 5px 15%' }}>
        <TextField
          disabled={!subjectModal.editable}
          withMedia={false}
          value={subjectModal.value}
          uneditableValue={`[${user.name} ${user.lastname}] `}
          onChangeHandler={(e: ChangeEvent<HTMLTextAreaElement>) => {
            e.preventDefault();
            setSubjectModal({
              ...subjectModal,
              value: e.target.value.replace(`[${user.name} ${user.lastname}] `, ''),
              subjectId: '',
            });
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignSelf: 'center' }}>
          <Button
            bottomMargin={3}
            width={100}
            label={!subjectModal.editable ? 'Passt' : btn.sendBtn}
            onClick={() => onAddComment({})}
            disabled={subjectModal.value.length}
          />
          {!subjectModal.withoutGeneration && (
            <ButtonBase
              style={{
                backgroundColor: theme.ACTIVE_INPUT,
                width: 40,
                height: 40,
                borderRadius: 40,
                zIndex: 99,
                alignItems: 'center',
                alignSelf: 'center',
              }}
              onClick={() => {
                if (subjectModal.loading) abortSubjectSignal();
                else generateSubjectRequest(true);
              }}
            >
              {subjectModal.loading ? (
                <Stop
                  // onClick={abortSubjectSignal}
                  style={{ color: theme.COLOR_PRIMARY, width: 45 }}
                />
              ) : (
                <Refresh
                  // onClick={() => generateSubjectRequest(true)}
                  style={{ color: theme.COLOR_PRIMARY, width: 45 }}
                />
              )}
            </ButtonBase>
          )}
          {user.subjectSuggestion === 'optional' && subjectModal.firstStep ? (
            <Button bottomMargin={3} width={100} label={'Generieren'} onClick={() => generateSubjectRequest(true)} />
          ) : !subjectModal.editable ? (
            <Button
              bottomMargin={3}
              width={100}
              label={'Ändern'}
              onClick={() => setSubjectModal({ ...subjectModal, editable: true })}
            />
          ) : (
            <Button
              bottomMargin={3}
              width={150}
              label={'Standard-Betreff'}
              onClick={() =>
                setSubjectModal({ ...subjectModal, value: `Beitrag an “${kreiseName || 'Alle'}”`, subjectId: '' })
              }
            />
          )}
        </div>
      </div>
      <Loader showLoader={subjectModal.loading} className="z-[50] fixed" />
    </DefaultModal>
  );
};

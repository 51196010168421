import React from 'react';
import { withConfig } from '../../config';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  switchBase: {
    color: blue[300],
    '&$checked': {
      color: blue[500],
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
  disabled: {
    color: grey[300],
    '&$checked': {
      color: grey[500],
    },
    '&$checked + $track': {
      backgroundColor: grey[500],
    },
  },
});

const CustomSwitch = ({ ...props }) => {
  const classes = useStyles({});
  return (
    <div
      onClick={() => {
        if (!props.disabled) return
        props.onChange()
      }}>
      <Switch
        disableRipple
        classes={{
          //   root: classes.root,
          switchBase: classes.switchBase,
          //   thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled
        }}
        {...props}
      />
    </div>
  );
};

export default withConfig(CustomSwitch);

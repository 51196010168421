import { FC, useEffect, useRef } from 'react';

export const HtmlPost: FC<{
  html: string;
  checkHeight?: boolean;
  fontStyle?: { fontFamily: string; fontWeight: string };
}> = ({ html, checkHeight, fontStyle }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const divRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const div = divRef.current;
    const handleResize = () => {
      if (iframe && div) {
        try {
          const containerWidth = iframe.parentElement.offsetWidth;
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

          const contentWidth = iframeDocument.documentElement.scrollWidth;
          const newScale = containerWidth / contentWidth;

          const contentHeight = iframeDocument.documentElement.scrollHeight;

          iframe.style.transform = `scale(${newScale})`;
          iframe.style.width = `${100 / newScale}%`;
          iframe.style.height = `${contentHeight}px`;

          if (fontStyle) {
            iframeDocument.documentElement.style.fontFamily = fontStyle.fontFamily;
            iframeDocument.documentElement.style.fontWeight = fontStyle.fontWeight;
          }

          div.style.height = `${contentHeight * newScale}px`;
        } catch (error) {
          console.error('Error accessing iframe content:', error);
        }
      }
    };

    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      iframeDocument.open();
      iframeDocument.write('<meta name="viewport" content="width=device-width, initial-scale=1.0">' + '\n' + html);
      iframeDocument.close();

      iframe.onload = () => {
        handleResize();
      };

      if (checkHeight) {
        handleResize();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [html]);

  return (
    <div ref={divRef} style={{ width: '100%', overflow: 'hidden' }}>
      <iframe
        title="html post"
        ref={iframeRef}
        width="100%"
        style={{
          border: 'none',
          transformOrigin: '0 0',
        }}
        scrolling="no"
      />
    </div>
  );
};

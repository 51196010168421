import React, { FC, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { handleFilterSearchParams } from '../../../../utilities';
import SearchScreen from './SearchScreen';
import { useAppDispatch } from 'store';
import { TSearchFilter, searchActions } from 'store/reducers/searchSlice';

const SearchTab: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      handleFilterSearchParams(history.location.search, (params) => {
        if (params) onSelectFilter(params.filter);
      });
    }
  }, []);

  const saveScrollPos = () => {
    const { pathname } = history.location;
    if (pathname.includes('/users') || pathname.includes('/global')) return;
    // @ts-ignore
    const key: TSearchFilter = pathname.replace('/suche/', '').split('/')[0];
    dispatch(searchActions.setScrollPos({ value: window.scrollY, key }));
  };

  const onSelectFilter = ({ filter, key }: { filter: string; key: TSearchFilter }) => {
    if (!filter || !key) return;
    saveScrollPos();
    dispatch(searchActions.setUsersFilter({ key, value: filter }));

    if (key === 'corpsid') history.push(`corpsid/${filter}`);
    else history.push(`${history.location.pathname}/users`);
  };

  return <SearchScreen key={history.location.pathname} toFilteredScreen={onSelectFilter} />;
};

export default withRouter(SearchTab);

import { LayoutHook } from 'hooks';
import React, { FC } from 'react';

interface IImageGrid {
  images: string[];
  onClick?: (image: string, idx: number) => void;
}

export const ImageGrid: FC<IImageGrid> = ({ images, onClick }) => {
  const [width] = LayoutHook();

  const heightImagesContainer = width < 600 ? 400 : 500;
  const heightImage = width < 600 ? 200 : 250;

  const ImageItem = ({
    image,
    index,
    height = heightImage,
  }: {
    image: string;
    index: number;
    height?: number | string;
  }) => {
    return image ? (
      <div
        style={{ flex: 1, position: 'relative', marginLeft: 2, cursor: 'pointer' }}
        onClick={() => onClick(image, index)}
      >
        <img
          src={image}
          style={{ width: '100%', height: height, backgroundColor: 'grey', objectFit: 'cover', margin: 2 }}
          alt={`Image ${index}`}
        />
      </div>
    ) : (
      <div />
    );
  };

  const TwoImages = ({ images }) => {
    return (
      <>
        <ImageItem image={images[0]} index={0} />
        <ImageItem image={images[1]} index={1} />
      </>
    );
  };

  const renderImages = (start, overflow, height, onClick) => {
    return (
      <>
        <ImageItem image={images[start]} index={start} height={height} />
        {images[start + 1] && (
          <div style={{ position: 'relative', flex: 1 }}>
            <ImageItem image={images[start + 1]} index={start + 1} />
            {overflow && (
              <div
                onClick={() => onClick(images[start + 1], start + 1)}
                style={{
                  width: 'calc(100% - 2px)',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  backgroundColor: 'rgba(52, 52, 52, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: 4,
                }}
              >
                <span style={{ color: 'white', fontSize: 18 }}>{`+${images.length - 5}`}</span>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  if (images.length < 1) return null;

  return (
    <div style={{ display: 'flex', marginLeft: -4 }}>
      {images.length < 3 ? (
        <TwoImages images={images} />
      ) : (
        <ImageItem image={images[0]} index={0} height={heightImagesContainer + 2} />
      )}
      {images.length > 2 && <div style={{ flex: 1 }}>{renderImages(1, false, heightImage, onClick)}</div>}
      {images.length > 3 && (
        <div style={{ flex: 1 }}>
          {renderImages(3, images.length > 5, images.length >= 5 ? heightImage : heightImagesContainer, onClick)}
        </div>
      )}
    </div>
  );
};

import { SET_CURRENT_REQUEST } from 'constants/requests';
import {
  GET_USER_REQUESTS_REQUEST,
  GET_USER_REQUESTS_SUCCESS,
  GET_USER_REQUESTS_FAILURE,
  CLEAR_USER_REQUESTS,
  GET_REQUEST_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
  CLEAR_REQUEST,
  HANDLE_REQUEST_REQUEST,
  HANDLE_REQUEST_SUCCESS,
  HANDLE_REQUEST_FAILURE,
  SEND_REQUEST_REQUEST,
  SEND_REQUEST_SUCCESS,
  SEND_REQUEST_FAILURE,
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_FAILURE,
  DECLINE_REQUEST_REQUEST,
  DECLINE_REQUEST_SUCCESS,
  DECLINE_REQUEST_FAILURE,
} from '../../constants/';

const initialState = {
  outRequests: [],
  incRequests: [],
  currentRequest: {},
  requestsIsLoading: false,
  currentRequestIsLoading: false,
  handleRequestIsLoading: false,
  sendRequestIsLoading: false,
  sendReportIsLoading: false,
  declineRequestIsLoading: false,
  contactRequestIsLoaded: false,
};

export const requests = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUESTS_REQUEST:
      return {
        ...state,
        requestsIsLoading: true,
      };
    case GET_USER_REQUESTS_SUCCESS:
      return {
        ...state,
        outRequests: action.outRequests,
        incRequests: action.incRequests,
        requestsIsLoading: false,
      };
    case GET_USER_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.error,
        requestsIsLoading: false,
      };
    case CLEAR_USER_REQUESTS:
      return {
        ...state,
        outRequests: [],
        incRequests: [],
      };
    case GET_REQUEST_REQUEST:
      return {
        ...state,
        currentRequestIsLoading: true,
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        currentRequestIsLoading: false,
        currentRequest: action.request,
        contactRequestIsLoaded: true,
      };
    case GET_REQUEST_FAILURE:
      return {
        ...state,
        currentRequestIsLoading: false,
        error: action.error,
        contactRequestIsLoaded: true,
      };
    case CLEAR_REQUEST:
      return {
        ...state,
        currentRequest: {},
      };
    case HANDLE_REQUEST_REQUEST:
      return {
        ...state,
        handleRequestIsLoading: true,
      };
    case HANDLE_REQUEST_SUCCESS:
      return {
        ...state,
        handleRequestIsLoading: false,
      };
    case HANDLE_REQUEST_FAILURE:
      return {
        ...state,
        handleRequestIsLoading: false,
      };
    case SEND_REQUEST_REQUEST:
      return {
        ...state,
        sendRequestIsLoading: true,
      };
    case SEND_REQUEST_SUCCESS:
      return {
        ...state,
        sendRequestIsLoading: false,
      };
    case SEND_REQUEST_FAILURE:
      return {
        ...state,
        sendRequestIsLoading: false,
      };
    case SEND_REPORT_REQUEST:
      return {
        ...state,
        sendReportIsLoading: true,
      };
    case SEND_REPORT_SUCCESS:
      return {
        ...state,
        sendReportIsLoading: false,
      };
    case SEND_REPORT_FAILURE:
      return {
        ...state,
        sendReportIsLoading: false,
      };
    case DECLINE_REQUEST_REQUEST:
      return {
        ...state,
        declineRequestIsLoading: true,
      };
    case DECLINE_REQUEST_SUCCESS:
      return {
        ...state,
        declineRequestIsLoading: false,
      };
    case DECLINE_REQUEST_FAILURE:
      return {
        ...state,
        declineRequestIsLoading: false,
      };
    case SET_CURRENT_REQUEST:
      console.log('action', action);
      return {
        ...state,
        currentRequest: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

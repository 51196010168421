import {
  SEND_MESSAGE_NOTIFICATION_REQUEST,
  SEND_MESSAGE_NOTIFICATION_SUCCESS,
  SEND_MESSAGE_NOTIFICATION_FAILURE,
} from '../../constants/';

const initialState = {
  notificationStatus: {},
  notificationError: {},
};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE_NOTIFICATION_REQUEST:
      return {
        ...state,
      };
    case SEND_MESSAGE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        status: action.status,
      };
    case SEND_MESSAGE_NOTIFICATION_FAILURE:
      return {
        ...state,
        status: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withConfig } from '../../config';
import { ProfileStudiumProps } from './model';
import { updateUserRequest } from '../../store/actions/users';
import { showResponse } from '../../store/actions/response';
import SelectModal from '../../components/Shared/SelectModal';
import Button from '../../components/UI/Button';
import Loader from '../../components/UI/Loader';
import ProfileScale from '../../components/UI/ProfileScale';
import { educationLevel } from '../../utilities/';
import EducationItem from './Components/EducationItem';
import { ButtonBase } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';

const styles = createStyles({
  container: {
    width: '420px',
    boxSizing: 'border-box',
  },
  updateButtonWraper: {
    marginTop: 20,
  },
  inputsWrapper: {
    padding: '0 0 10px',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
  addButtonWrapper: {
    position: 'relative',
    width: '100%',
    borderTop: '1px solid',
    height: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    position: 'absolute',
    top: -20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, ProfileStudiumProps {
  language: translationsType;
}
interface State {
  education: any[];
  alertModalIsOpen: boolean;
  abschlussModalIsOpen: boolean;
  index: any;
}

class ProfileStudium extends React.Component<Props> {
  state: State = {
    education: [],
    alertModalIsOpen: false,
    abschlussModalIsOpen: false,
    index: null,
  };

  // LIFECYCLE METHOD
  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps: ProfileStudiumProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.initState();
    }
  }

  // INIT COMPONENT STATE
  initState = () => {
    const { user } = this.props;
    if (user && user.education && user.education.length > 0) {
      const updatedEducation = user.education.map((ed: any) => ({
        ...ed,
        id: Math.random(),
      }));

      this.setState({
        education: [...updatedEducation],
      });
    } else {
      this.setState({
        education: [
          {
            id: Math.random(),
            hochschule: '',
            studiengang: '',
            abschluss: '',
          },
        ],
      });
    }
  };

  // MODAL HANDLER
  modalHandler = (name: string) => {
    let modalName = '';
    switch (name) {
      case 'alert':
        modalName = 'alertModalIsOpen';
        break;
      case 'abschluss':
        modalName = 'abschlussModalIsOpen';
        break;
      default:
        break;
    }
    //@ts-ignore
    this.setState((prevState: State) => ({
      [modalName]: !prevState[modalName],
    }));
  };

  // ON ITEM SELECT
  selectItemHandler = (value: string, name: string, index: number): void => {
    const currentEducation: Array<{ abschluss: string }> = [...this.state.education];

    currentEducation[index].abschluss = value;

    this.setState({
      ...this.state,
      education: [...currentEducation],
    });
  };

  // CHANGE HANDLER
  onChangeHandler = (value: string, i: string, name: string) => {
    const currentEducation: Array<{ value: string; id: string }> = [...this.state.education];

    currentEducation[i][name] = value;

    this.setState({
      ...this.state,
      education: [...currentEducation],
    });
  };

  // UPDATE PROFILE
  updateProfileHandler = () => {
    const { user, updateUserRequest, showResponse } = this.props;
    const { education } = this.state;

    const updatedUser = {
      ...user,
      password: '',
      education,
    };

    if (user && user.name) {
      updateUserRequest(updatedUser);
    } else if (user && !user.name) {
      showResponse({
        message: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      });
    }
  };

  onAddStudium = () => {
    const currentEducation: Array<any> = [...this.state.education];
    const newEducationObj = {
      id: Math.random(),
      hochschule: '',
      studiengang: '',
      abschluss: '',
    };

    currentEducation.push(newEducationObj);

    this.setState({
      education: [...currentEducation],
    });
  };

  onRemoveStudium = (id: number): void => {
    const { user, updateUserRequest } = this.props;
    const currentEducation: Array<object> = [...this.state.education];

    const updatedEducation = currentEducation.filter((ed: any) => ed.id !== id);

    this.setState({
      education: updatedEducation,
    });
  };

  // RENDER METHOD
  render(): JSX.Element {
    const { userIsLoading, theme, classes, config, language } = this.props;
    const { btn, profileStudy } = language;
    let addEducationEnabled = false;
    if (this.state.education?.length) {
      const lastItem = this.state.education[this.state.education.length - 1];
      if (
        lastItem.hochschule?.trim()?.length ||
        lastItem.studiengang?.trim()?.length ||
        lastItem.abschluss?.trim()?.length
      ) {
        addEducationEnabled = true;
      }
    }

    return (
      <React.Fragment>
        <div className={classes.container}>
          <ProfileScale />
          <div className={classes.inputsWrapper}>
            {this.state.education.length > 0 &&
              this.state.education.map((education: any, index: number) => {
                const lastElem = index + 1 === this.state.education.length;

                return (
                  <EducationItem
                    key={index}
                    lastElem={lastElem}
                    hochschule={education.hochschule}
                    studiengang={education.studiengang}
                    abschluss={education.abschluss}
                    educationObj={education}
                    elemIndex={index}
                    onRemoveItem={() => this.onRemoveStudium(education.id)}
                    onChangeHandler={this.onChangeHandler}
                    onSelectItem={this.selectItemHandler}
                    modalHandler={(modalName: string) => {
                      this.setState(
                        {
                          index,
                        },
                        () => this.modalHandler(modalName),
                      );
                    }}
                    modalIsOpen={this.state.alertModalIsOpen}
                  />
                );
              })}
          </div>
          <div className={classes.addButtonWrapper} style={{ borderTopColor: theme.BACKGROUND_SECONDARY }}>
            <div className={classes.addButtonContainer}>
              <ButtonBase
                style={{ borderRadius: 50, fontSize: 40, background: theme.BACKGROUND }}
                onClick={this.onAddStudium}
              >
                <AddCircle style={{ color: theme.BUTTON_PRIMARY }} fontSize="inherit" />
              </ButtonBase>
            </div>
          </div>
          <div className={classes.updateButtonWraper}>
            <Button label={btn.saveBtn} onClick={this.updateProfileHandler} disabled={addEducationEnabled} />
          </div>
        </div>
        <SelectModal
          modalIsOpen={this.state.abschlussModalIsOpen}
          modalTitle={profileStudy.degreeLabel}
          modalHandler={() => this.modalHandler('abschluss')}
          selectItemHandler={(value: string) => {
            this.selectItemHandler(value, 'abschluss', this.state.index);
          }}
          data={educationLevel}
          modalName="abschluss"
          index={this.state.index}
        />
        <Loader showLoader={userIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  user: users.user,
  userIsLoading: users.userIsLoading,
});

const mapDispatchToProps = {
  updateUserRequest,
  showResponse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withLanguage(withConfig(ProfileStudium))));

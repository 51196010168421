import React, { useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig, Theme } from '../../config/';
import { imagePath, profileImagePath } from '../../utilities/';
import ImageLightBox from './ImageLightBox/ImageLightBox';
import { withRouter, RouteComponentProps } from 'react-router-dom';
const defaultUser = require('../../assets/default_avatar.png');

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '15px 20px',
    boxSizing: 'border-box',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
    border: '1px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  content: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  name: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
  },
  desc: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
    textAlign: 'left',
  },
  '@media(max-width: 650px)': {
    name: {
      maxWidth: 200,
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  sender: any;
  history: any;
  prevPath?: string;
}

const RequestHeader: React.FC<Props> = ({ theme, classes, sender, history, prevPath }) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [largeImage, setLargeImage] = useState('');

  useEffect(() => {
    if (!largeImage) getFullPhoto();
  }, []);

  async function getFullPhoto() {
    if (sender?.photo) {
      const res = await profileImagePath('full', sender.photo);
      setLargeImage(res);
    }
  }

  const toProfileHandler = () => {
    history.push(`/profile-details/${sender._id}`, { userId: sender._id, prevPath });
  };

  let image = imagePath(null, sender.photo);
  return (
    <div className={classes.container} style={{ background: theme.BACKGROUND_PRIMARY }}>
      <ImageLightBox
        showGallery={openLightbox}
        onClose={() => setOpenLightbox(false)}
        images={[{ url: largeImage, fileName: largeImage, size: null, mimetype: '' }]}
      />
      <div
        className={classes.imageWrapper}
        style={{ borderColor: theme.ICON_BORDER }}
        onClick={() => {
          if (image) {
            setOpenLightbox(!openLightbox);
          }
        }}
      >
        <img src={image || defaultUser} alt=" " style={{ width: '100%' }} />
      </div>
      <div className={classes.content} onClick={toProfileHandler}>
        <span className={classes.name} style={{ color: theme.TEXT_PRIMARY }}>
          {sender.firstname ? sender.firstname : sender.name} {sender.lastname}
        </span>
        <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
          {sender?.corp?.[0]?.corpsname}
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(RequestHeader)));

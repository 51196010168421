import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, ButtonBase } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store';
import { getEventByIdRequest, getEventByMailRequest } from 'store/actions/events';
import BackButton from 'components/Shared/BackButton';
import Layout from 'components/UI/Layout';
import EditIcon from '@material-ui/icons/Edit';

import EventContent from './components/EventContent';
import Comment from './components/EventComment';
import AddEventComment from './components/EventToolbar';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { clearEventComments } from 'store/reducers/commentsSlice';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { addEventComment, getEventComments } from 'api/comments';
import { mediaActions } from 'store/reducers/mediaSlice';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';

const { theme } = getConfig();
interface Props extends RouteComponentProps<any> {}

const Event: React.FC<Props> = (props) => {
  const {
    eventLabels,
    privacy: { restrictions },
  } = useLanguage();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const userId = getLocalStorage('userId');
  const currentEvent = useAppSelector((state) => state.events.currentEvent);
  const [stateEvent, setStateEvent] = useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { history, location } = props;
  const eventId = history.location.pathname.replace('/calendar/', '');
  const { loading, loadingCounter, ...attachments } = useAppSelector((state) => state.media);
  const [addedComments, setAddedComments] = useState([]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['eventComments', eventId],
    queryFn: () => getEventComments(eventId, pageNumber, 10),
    initialPageParam: 0,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      const count = allPages?.reduce((acc, page) => [...acc, ...page.data], []);
      if (count?.length >= lastPage?.total || !lastPage?.data?.length) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const sendComment = async ({ content, sendEmailComment }: { content: string; sendEmailComment: string }) => {
    const owner = userId === stateEvent?.sender;

    const commentEntity = {
      event_id: eventId,
      commenter: userId,
      content,
      me: userId,
      notificationType: owner ? 'all' : sendEmailComment,
      ...attachments,
    };

    const res = await addEventComment(eventId, commentEntity);
    setAddedComments((c) => [res, ...c]);
    dispatch(mediaActions.deleteAllAttachments());
    return;
  };

  const comments = data?.pages?.reduce((acc, page) => [...acc, ...page.data], []) || [];

  const commentsCount = data?.pages?.[0]?.total + addedComments.length;
  const squashedComments = [...addedComments, ...comments];

  const initState = (event: any) => {
    setStateEvent(event);
  };

  useEffect(() => {
    if (!data?.pages?.length) return;
    setPageNumber((c) => c + 1);
  }, [data?.pages?.length]);

  useEffect(() => {
    initState(currentEvent);
  }, [currentEvent]);

  useEffect(() => {
    let evId, mail;
    if (location?.state) {
      const { eventId } = location.state;
      evId = eventId;
    } else {
      evId = props.match.params.id;
      mail = props.location.search.replace('?email=', '');
    }

    if (evId) {
      if (userId) {
        dispatch(getEventByIdRequest(evId));
      } else {
        dispatch(getEventByMailRequest(evId, mail));
      }
    }

    return () => {
      dispatch(clearEventComments());
    };
  }, []);

  const toEditEvent = () => {
    history.replace('/edit-event/' + eventId, { event: currentEvent });
  };

  const EditButton = () =>
    (currentEvent?.sender === user?._id ||
      currentEvent?.organizers?.some((organizer) => organizer._id === user?._id)) && (
      <ButtonBase
        onClick={toEditEvent}
        className={'absolute right-4 top-5 p-14 rounded-full z-10'}
        style={{ position: 'absolute' }}
      >
        <EditIcon style={{ color: theme.BUTTON_SECONDARY }} />
      </ButtonBase>
    );

  return (
    <Layout>
      <BackButton title="Veranstaltung" onClick={history.goBack} />

      <EditButton />
      <EventContent event={stateEvent} guest={!user._id} />
      {user._id && (
        <>
          <div
            className={`w-full items-center flex px-4 py-2 text-lg font-semibold`}
            style={{ backgroundColor: theme.BACKGROUND_PRIMARY, color: theme.TEXT_PRIMARY }}
          >
            {user.isGuest
              ? restrictions.commentsOnlyForMembers
              : commentsCount === 0
              ? eventLabels.noCommentsLabel
              : `${eventLabels.commentsLabel} (${commentsCount})`}
          </div>
          {!user.isGuest && (
            <>
              <InfiniteScroll
                threshold={500}
                initialLoad={false}
                pageStart={0}
                loadMore={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <div style={{ textAlign: 'center' }} key="9999">
                    <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                  </div>
                }
                useWindow={true}
              >
                {squashedComments?.length > 0 &&
                  squashedComments.map((comment) => (
                    <Comment
                      comment={comment}
                      commenter={comment.commenter}
                      key={comment._id}
                      date={comment.time}
                      content={comment.content}
                      likedUsers={comment.liked_users}
                      event={stateEvent}
                    />
                  ))}
              </InfiniteScroll>
              <AddEventComment event={stateEvent} sendComment={sendComment} />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default withRouter(Event);

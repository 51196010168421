import { FC } from 'react';
import AlertModal from 'components/Shared/AlertModal';
import { useLanguage } from 'languages/languageContext';
import { RECURRING_MODIFY_TYPE } from '../CreateEvent';
import { RadioGroup, RadioGroupItem } from 'components/shadcn/radioGroup';
import { Label } from 'components/shadcn/label';
import { Button } from 'components/shadcn/button';
import { eFrequency, TRRule } from 'utilities/configRRules';

type Props = {
  show: boolean;
  onClose: () => void;
  isEventHaveMembers: boolean;
  recurringRules: TRRule;
  removeEventHandler: () => void;
  handleChangeCancelEvent: (v: string) => void;
  cancelType: string;
  rRulesChanged: boolean;
  onValueChange: (v: string) => void;
};

export const RemoveEventModal: FC<Props> = (props) => {
  const { eventLabels, btn, rrEventLabels } = useLanguage();
  const {
    show,
    onClose,
    isEventHaveMembers,
    recurringRules,
    removeEventHandler,
    handleChangeCancelEvent,
    cancelType,
    rRulesChanged,
    onValueChange,
  } = props;

  return (
    <AlertModal
      showModal={show}
      title={isEventHaveMembers ? eventLabels.cancelModal.title : eventLabels.deleteEventTitle}
      modalHandler={onClose}
      message={isEventHaveMembers ? eventLabels.cancelModal.message : eventLabels.deleteEventLabel}
      onClose={onClose}
      showCloseBtn={true}
    >
      {recurringRules?.freq === eFrequency.SINGLE || recurringRules?.freq === undefined ? (
        !isEventHaveMembers ? (
          <Button onClick={removeEventHandler}>OK</Button>
        ) : (
          <div className="flex flex-col gap-5">
            <RadioGroup onValueChange={handleChangeCancelEvent}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="" id="r1" className="text-primary-btn border-primary-btn hover:scale-105" />
                <Label htmlFor="r1">{eventLabels.cancelModal.optionNone}</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="all" id="r2" className="text-primary-btn border-primary-btn hover:scale-105" />
                <Label htmlFor="r2">{eventLabels.cancelModal.optionAll}</Label>
              </div>
            </RadioGroup>
            <Button onClick={removeEventHandler} className="bg-secondary-btn">
              {!!cancelType ? btn.cancel2 : btn.clearBtn}
            </Button>
          </div>
        )
      ) : (
        <div>
          <RadioGroup onValueChange={onValueChange} className="mb-6">
            {!rRulesChanged && (
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value={RECURRING_MODIFY_TYPE.SINGLE}
                  id="update-rec-1"
                  className="text-primary-btn border-primary-btn hover:scale-105"
                />
                <Label htmlFor="update-rec-1">{rrEventLabels.thisDate}</Label>
              </div>
            )}
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value={RECURRING_MODIFY_TYPE.FOLLOWING}
                id="update-rec-2"
                className="text-primary-btn border-primary-btn hover:scale-105"
              />
              <Label htmlFor="update-rec-2">{rrEventLabels.thisDateAndFollowing}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value={RECURRING_MODIFY_TYPE.ALL}
                id="update-rec-3"
                className="text-primary-btn border-primary-btn hover:scale-105"
              />
              <Label htmlFor="update-rec-3">{rrEventLabels.allDates}</Label>
            </div>
          </RadioGroup>
          <Button onClick={removeEventHandler}>{btn.saveBtn}</Button>
        </div>
      )}
    </AlertModal>
  );
};

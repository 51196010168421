import React, { FC, useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import SearchBar from '../../../../components/Shared/SearchBar';
import UserItem from '../SearchedItems/UserItem';
import EmptyList from '../../../../components/Shared/EmptyList';
import { useHistory, useParams } from 'react-router-dom';
import { getConfig } from 'config/config';
import { useAppDispatch, useAppSelector } from 'store';
import { TSearchFilter, searchActions, searchFilteredRequest } from 'store/reducers/searchSlice';
import { CorpsScreen } from 'scenes/CorpsScreen/CorpsScreen';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
    paddingTop: 100,

    '@media(max-width: 650px)': {
      paddingTop: 170,
    },
  },
  inputWrapper: {
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    zIndex: 10,
    position: 'sticky',
    paddingTop: 25,
    top: 130,

    '@media(max-width: 1024px)': {
      width: 'calc(75% - 72px)',
    },
    '@media(max-width: 650px)': {
      width: 'calc(100% - 20px)',
    },
  },
  content: {
    width: '100%',
    paddingTop: 15,

    '@media(max-width: 650px)': {
      paddingTop: 10,
    },
  },
  listWrapper: {
    boxSizing: 'border-box',
    width: '100%',
  },
  '@media(max-width: 650px)': {
    inputWrapper: {
      padding: '0 10px',
      paddingTop: 25,
    },
  },
});

interface Props {
  isParamsAvaliable: boolean;
  toInitialScreen: () => void;
  entityValue: string;
  entity: string;
}

const FilteredTab: FC<Props> = ({ toInitialScreen }) => {
  const history = useHistory();

  const queryData = useParams<{ id: string }>();
  // for now used only for corps
  const searchableId = queryData?.id;

  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const queryFilter = params.searchKey || searchableId;

  //@ts-ignore
  const selectedPage: TSearchFilter = history.location.pathname
    .replace('/suche/', '')
    .replace('/users', '')
    .split('/')[0];

  const {
    isLoading = false,
    users = [],
    count = 0,
    filter,
  } = useAppSelector((state) => state.search[selectedPage]?.filtered) || {};
  const dispatch = useAppDispatch();
  const classes = useStyles({});
  const [state, setState] = useState({
    query: '',
    pageNumber: 1,
    filter: '',
    value: '',
  });

  const getUsersHandler = () => {
    dispatch(
      searchFilteredRequest({
        filter: selectedPage,
        entity: filter || queryFilter,
        page: 1,
        searchkey: state.query,
        clear: true,
      }),
    );
  };

  useEffect(() => {
    if (queryFilter?.length) clearSearchHandler({ searchkey: queryFilter });
    else getUsersHandler();
    window.scrollTo({ top: 0 });
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(
      searchFilteredRequest({
        filter: selectedPage,
        entity: filter,
        page: 1,
        searchkey: value,
        clear: true,
      }),
    );
    dispatch(searchActions.setFilteredSearchQuery({ key: selectedPage, value }));
    setState({ ...state, pageNumber: 2, query: value });
  };

  const clearSearchHandler = ({ searchkey = '' }: { searchkey?: string }) => {
    setState({
      ...state,
      pageNumber: 1,
    });

    dispatch(searchActions.setFilteredSearchQuery({ key: selectedPage, value: searchkey }));

    dispatch(
      searchFilteredRequest({
        filter: selectedPage,
        entity: searchkey || filter,
        page: 1,
        clear: true,
      }),
    );
  };

  const goBack = () => {
    history.goBack();
  };
  const getMoreUsersHandler = () => {
    if (!isLoading) {
      const nextPage = state.pageNumber + 1;
      setState({
        ...state,
        pageNumber: nextPage,
      });
      dispatch(
        searchFilteredRequest({
          filter: selectedPage,
          entity: filter,
          page: nextPage,
          searchkey: state.query,
        }),
      );
    }
  };

  const mappedItems = (
    <div className={classes.listWrapper}>
      {users?.length > 0 ? users.map((item, i) => <UserItem user={item} key={i} filter={filter} />) : <EmptyList />}
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.inputWrapper} style={{ background: theme.BACKGROUND }}>
        <SearchBar
          toInitialScreen={toInitialScreen}
          enableBackButton
          query={state.query}
          onChange={onChangeHandler}
          clearSearch={goBack}
          placeholder={selectedPage === 'corpsid' && 'Suche nach Mitgliedern'}
        />
      </div>
      <div className={classes.content} style={state.query.length ? { paddingTop: 40 } : {}}>
        {selectedPage === 'corpsid' && <CorpsScreen groupId={searchableId} />}

        <InfiniteScroll
          threshold={50}
          initialLoad={false}
          pageStart={0}
          loadMore={getMoreUsersHandler}
          hasMore={users.length < count}
          useWindow={true}
          loader={
            isLoading && (
              <div style={{ textAlign: 'center' }} key={52}>
                <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
              </div>
            )
          }
        >
          {mappedItems}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FilteredTab;

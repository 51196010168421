import { Config } from 'config';

export function parseBoolean(value: string, defaultValue = false) {
  return value === 'true' ? true : value === 'false' ? false : defaultValue;
}

export const setConfig = () => {
  const {
    REACT_APP_COLOR_PRIMARY,
    REACT_APP_COLOR_SECONDARY,
    REACT_APP_TEXT_SECONDARY,
    REACT_APP_TEXT_PRIMARY,
    REACT_APP_BUTTON_PRIMARY,
    REACT_APP_BUTTON_SECONDARY,
    REACT_APP_BUTTON_ICON,
    REACT_APP_BUTTON_LIGHT,
    REACT_APP_ICON_PRIMARY,
    REACT_APP_BACKGROUND,
    REACT_APP_BACKGROUND_SECONDARY,
    REACT_APP_BACKGROUND_PRIMARY,
    REACT_APP_ERROR_PRIMARY,
    REACT_APP_ACTIVE_INPUT,
    REACT_APP_API_DEV,
    REACT_APP_API_PRODUCTION,
    REACT_APP_NAV_BAR,
    REACT_APP_SERVER_PATH_PROD,
    REACT_APP_SERVER_PATH_DEV,
    REACT_APP_EVENT_CONTENT_PATH_DEV,
    REACT_APP_EVENT_CONTENT_PATH_PROD,
    REACT_APP_ICON_BORDER,
    REACT_APP_ICON_BACKGROUND,
    REACT_APP_ADD_BACKGROUND,
    REACT_APP_WITH_BIERJUNGE,
    REACT_APP_NAME,
    REACT_APP_FULL_NAME,
    REACT_APP_EVENT_DEFAULT_KREISE,
    REACT_APP_DOCUMENTS_NAME,
    REACT_APP_CORPS_FUNCTION_NAME,
    REACT_APP_POSTS_NAME,
    REACT_APP_CORPS_EDIT_DATE,
    REACT_APP_WEB_LINK,
    REACT_APP_WITHOUT_CONTACTS,
    REACT_APP_WITH_TELEPHONE,
    REACT_APP_WITH_ADRESS,
    REACT_APP_WITH_PARTNER,
    REACT_APP_WITH_MENTOR,
    REACT_APP_SHOW_MOBILE,
    REACT_APP_SHOW_EMAIL,
    REACT_APP_WITH_CORPS_STATUS,
    REACT_APP_APP_INFO,
    REACT_APP_SHOW_AGE,
    REACT_APP_WITH_EDUCATION,
    REACT_APP_WITH_WORK,
    REACT_APP_BVS_MENTORING,
    REACT_APP_KAS_MENTORING,
    REACT_APP_VERSION,
  } = process.env;

  const config: Config = {
    API: REACT_APP_API_PRODUCTION,
    SERVER_PATH: REACT_APP_SERVER_PATH_PROD,
    EVENT_CONTENT_PATH: REACT_APP_EVENT_CONTENT_PATH_PROD,
    WITH_BIERJUNGE: parseBoolean(REACT_APP_WITH_BIERJUNGE),
    //@ts-ignore
    APP_NAME: REACT_APP_NAME,
    APP_FULL_NAME: REACT_APP_FULL_NAME,
    DOCUMENTS_NAME: REACT_APP_DOCUMENTS_NAME || 'Dokumente',
    EVENT_DEFAULT_KREISE: parseBoolean(REACT_APP_EVENT_DEFAULT_KREISE),
    CORPS_FUNCTION_NAME: REACT_APP_CORPS_FUNCTION_NAME,
    POSTS_NAME: REACT_APP_POSTS_NAME,
    CORPS_EDIT_DATE: REACT_APP_CORPS_EDIT_DATE || 'Beitrittszeitpunkt',
    WEB_LINK: REACT_APP_WEB_LINK,
    WITHOUT_CONTACTS: parseBoolean(REACT_APP_WITHOUT_CONTACTS),
    WITH_TELEPHONE: parseBoolean(REACT_APP_WITH_TELEPHONE),
    WITH_ADRESS: parseBoolean(REACT_APP_WITH_ADRESS),
    WITH_PARTNER: parseBoolean(REACT_APP_WITH_PARTNER),
    WITH_MENTOR: parseBoolean(REACT_APP_WITH_MENTOR),
    SHOW_MOBILE: parseBoolean(REACT_APP_SHOW_MOBILE),
    SHOW_EMAIL: parseBoolean(REACT_APP_SHOW_EMAIL),
    WITH_CORPS_STATUS: parseBoolean(REACT_APP_WITH_CORPS_STATUS),
    APP_INFO: JSON.parse(REACT_APP_APP_INFO || ''),
    SHOW_AGE: parseBoolean(REACT_APP_SHOW_AGE),
    WITH_EDUCATION: parseBoolean(REACT_APP_WITH_EDUCATION, true),
    WITH_WORK: parseBoolean(REACT_APP_WITH_WORK, true),
    BVS_MENTORING: parseBoolean(REACT_APP_BVS_MENTORING),
    KAS_MENTORING: parseBoolean(REACT_APP_KAS_MENTORING),
    APP_VERSION: REACT_APP_VERSION,
  };

  const theme = {
    COLOR_PRIMARY: REACT_APP_COLOR_PRIMARY,
    COLOR_SECONDARY: REACT_APP_COLOR_SECONDARY,
    TEXT_PRIMARY: REACT_APP_TEXT_PRIMARY,
    TEXT_SECONDARY: REACT_APP_TEXT_SECONDARY,
    BUTTON_PRIMARY: REACT_APP_BUTTON_PRIMARY,
    BUTTON_SECONDARY: REACT_APP_BUTTON_SECONDARY,
    BUTTON_ICON: REACT_APP_BUTTON_ICON,
    BUTTON_LIGHT: REACT_APP_BUTTON_LIGHT,
    ICON_PRIMARY: REACT_APP_ICON_PRIMARY,
    BACKGROUND: REACT_APP_BACKGROUND,
    BACKGROUND_SECONDARY: REACT_APP_BACKGROUND_SECONDARY,
    BACKGROUND_PRIMARY: REACT_APP_BACKGROUND_PRIMARY,
    ERROR_PRIMARY: REACT_APP_ERROR_PRIMARY,
    ACTIVE_INPUT: REACT_APP_ACTIVE_INPUT,
    NAV_BAR: REACT_APP_NAV_BAR,
    ICON_BORDER: REACT_APP_ICON_BORDER,
    ICON_BACKGROUND: REACT_APP_ICON_BACKGROUND,
    ADD_BACKGROUND: REACT_APP_ADD_BACKGROUND,
  };

  return {
    theme,
    config,
  };
};

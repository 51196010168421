import React, { FC, useState } from 'react';
import { withConfig, withAuth, Theme } from '../../config/';
import { makeStyles } from '@material-ui/styles';
import Layout from '../../components/UI/Layout';
import ScreenTitle from '../../components/Shared/ScreenTitle';
import InfoSection from './components/InfoSection';
import Logo from '../../components/UI/TextLogo';
import { getConfig } from '../../config/config';
import Linkify from 'react-linkify';
import linkDecorator from 'components/UI/linkDecorator';
import { additionalContacts } from './contacts';
import { useLanguage } from 'languages/languageContext';
import AlertModal from 'components/Shared/AlertModal';
import Button from 'components/UI/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteOwnerRequest, signOutRequest } from 'store/actions/auth';
import { useHistory } from 'react-router';
import { clearKreise, clearUserKreise } from 'store/reducers/kreise';
import { clearAds } from 'store/reducers/news';
import { clearResults } from 'store/reducers/globalSearch';
import { deleteOwnerProfile } from 'api/auth';
import Input from 'components/UI/Input';
import { clearUnreadMessages } from 'store/actions/chat';
import { clearUserRequest } from 'store/actions/users';
import { clearUserContacts } from 'store/actions/contacts';
import { clearUserRequests } from 'store/actions/requests';
import { TextareaAutosize } from '@material-ui/core';
import Content from 'components/UI/Content';
import { IonIcon } from 'components/UI/IonIcon';
import { HtmlPost } from '../Post/components/HtmlPost';

const { config, theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '30px 0 30px 0',
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0 30px 0 30px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  logoWrapper: {
    width: '512px',
    maxWidth: '30%',
    margin: '0 auto',
  },
  infoWrapper: {
    padding: 20,
  },
  infoText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    maxWidth: '95%',
    marginBottom: '10px',
  },
  text: {
    display: 'block',
    fontFamily: 'Roboto',
    color: theme.TEXT_SECONDARY,
    marginBottom: 3,
  },
  link: {
    fontFamily: 'Roboto',
    // marginBottom: 5,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  deleteButton: {
    display: 'flex',
  },
  deleteAccModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteButtons: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mailIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
  input: {
    background: theme.BACKGROUND_PRIMARY,
    color: theme.TEXT_PRIMARY,
    padding: '10px',
    border: '1px solid',
    borderRadius: 10,
    outline: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    resize: 'none',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: 20,
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
});

const AppInfo: FC = () => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { appInfo, logoutAlert } = useLanguage();
  const [deleteAccountModal, setDeleteAccountModal] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [focusFeedbackInput, setFocusFeedbackInput] = useState<boolean>(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const { dpd_link, tou_link, support_email, info_section_data } = useAppSelector((state) => state.common);

  const deleteUserHandler = async () => {
    try {
      setDeleteUserLoading(true);
      await deleteOwnerProfile(feedback);
      dispatch(clearUnreadMessages());
      dispatch(signOutRequest());
      dispatch(clearUserRequest());
      dispatch(clearKreise());
      dispatch(clearUserContacts());
      dispatch(clearUserRequests());
      dispatch(clearUserKreise());
      dispatch(clearAds());
      dispatch(clearResults());
      setDeleteUserLoading(false);
      history.push('/sign-in');
    } catch (e) {
      setDeleteUserLoading(false);
      console.log('ERROR DELETE ACC: ', e);
    }
  };

  const LinkedContact = (
    item:
      | {
          id: string;
          text: string;
        }
      | string,
  ) => {
    let text = typeof item !== 'object' ? item : item.text;
    const id = typeof item !== 'object' ? item : item.id;
    if (!text?.length) return <div style={{ height: 10 }} />;

    if (text.includes('info@connact.app')) {
      text = text.replace('info@connact.app', support_email);
    }

    let url = '';

    // const subject = `?subject=${encodeURIComponent(`Fragen/Anmerkungen zur ${config.APP_FULL_NAME} App`)}`
    if (text?.includes('Tel.:')) url = text.replace('Tel.: ', 'tel:');

    if (text?.includes('Mail:')) url = text.replace('Mail: ', 'mailto:').replace(' ', '');
    if (text?.includes('E-Mail:')) url = text.replace('E-Mail: ', 'mailto:').replace(' ', '');

    let p1 = '',
      p2 = '';
    if (url.length) {
      p1 = text.slice(0, 5);
      p2 = text.slice(5);
    }

    return url.length ? (
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        {/* <span key={id} className={classes.text}> */}
        <div className={classes.mailIcon}>
          <IonIcon
            name={text?.includes('Tel.:') ? 'call-outline' : 'mail-outline'}
            color={theme.ICON_BORDER}
            size={20}
          />
        </div>
        {/* </span> */}
        <a href={url} className={classes.text} style={{ textDecoration: 'none', color: '#2671D1', marginLeft: 3 }}>
          {p2}
        </a>
      </div>
    ) : (
      <span key={id} className={classes.text}>
        {text}
      </span>
    );
  };

  return (
    <Layout>
      <ScreenTitle title="Über" />
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <Logo />
        </div>

        <div className={classes.container}>
          <InfoSection title={appInfo.contactsLabel}>
            {!info_section_data ? (
              <Linkify componentDecorator={linkDecorator}>{config.APP_INFO.contact?.map(LinkedContact)}</Linkify>
            ) : (
              <HtmlPost html={info_section_data} />
            )}
          </InfoSection>

          {/* {!!additionalContacts?.length && (
            <InfoSection title="Inhaltlicher Ansprechpartner:">
              <Linkify componentDecorator={linkDecorator}>{additionalContacts.map(LinkedContact)}</Linkify>
            </InfoSection>
          )} */}
        </div>
        <InfoSection title={'Datenschutzinformationen'}>
          <div className={classes.infoText}>
            Hier finden Sie die
            <a
              href={tou_link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.text}
              style={{
                textDecoration: 'none',
                fontWeight: 'bolder',
                color: '#2671D1',
                marginLeft: 3,
                display: 'inline-block',
              }}
            >
              {' '}
              Nutzungsbedingungen{' '}
            </a>{' '}
            und{' '}
            <a
              href={dpd_link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.text}
              style={{
                textDecoration: 'none',
                color: '#2671D1',
                fontWeight: 'bolder',
                marginLeft: 3,
                display: 'inline-block',
              }}
            >
              {' '}
              Datenschutzinformationen{' '}
            </a>
            .
          </div>
        </InfoSection>
        <InfoSection title={appInfo.deleteUserTitle}>
          <div className={classes.infoText}>{appInfo.deleteUserDesc}</div>
          <div className={classes.deleteButton}>
            <Button label={appInfo.deleteUserTitle} onClick={() => setDeleteAccountModal(true)} />
          </div>
        </InfoSection>
        <InfoSection title={appInfo.versionLabel}>
          <div className={classes.infoText}>{config.APP_VERSION}</div>
        </InfoSection>
      </div>
      <AlertModal
        showModal={deleteAccountModal}
        modalHandler={setDeleteAccountModal}
        title={appInfo.deleteUserTitle}
        message={appInfo.deleteFeedback}
      >
        <div className={classes.deleteAccModal}>
          <TextareaAutosize
            rowsMax={6}
            style={{ borderColor: focusFeedbackInput ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY }}
            className={classes.input}
            value={feedback}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.target.value)}
            onFocus={() => setFocusFeedbackInput(true)}
            onBlur={() => setFocusFeedbackInput(false)}
          />
          <div className={classes.deleteButtons}>
            <Button
              onClick={deleteUserHandler}
              label={appInfo.deleteUserTitle.slice(0, -1)}
              width={150}
              background={theme.BUTTON_SECONDARY}
              disabled={!deleteUserLoading}
            />
            <Button onClick={() => setDeleteAccountModal(false)} label={logoutAlert.dismiss} width={140} />
          </div>
        </div>
      </AlertModal>
    </Layout>
  );
};

export default withAuth(AppInfo);

import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import ScreenTitle from 'components/Shared/ScreenTitle';
import Layout from 'components/UI/Layout';
import { useLanguage } from 'languages/languageContext';
import React from 'react';
import Contacts from './Contacts';
import { Requests } from './Request';
import { useAppSelector } from 'store';

enum ContactsRoute {
  Contacts = 'contacts',
  Requests = 'requests',
}

const BadgeCounter = ({ count }: { count: number | string }) => {
  if (!count) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        height: 20,
        width: 20,
        background: 'red',
        color: 'white',
        borderRadius: '50%',
      }}
    >
      {count}
    </div>
  );
};

const ContactsRequests = () => {
  const { drawer } = useLanguage();
  const { user } = useAppSelector((state) => state.users);
  return (
    <Layout>
      <ScreenTitle title={drawer.contacts + '-' + drawer.requests} />
      <Tabs defaultValue={ContactsRoute.Contacts} className="h-[50px]">
        <TabsList className="grid w-full h-full grid-cols-2">
          <TabsTrigger className="h-full w-full relative" value={ContactsRoute.Contacts}>
            {drawer.contacts}
          </TabsTrigger>

          <TabsTrigger className="h-full w-full relative" value={ContactsRoute.Requests}>
            <BadgeCounter count={user?.pending_contact_request?.length} />
            {drawer.requests}
          </TabsTrigger>
        </TabsList>

        <TabsContent value={ContactsRoute.Contacts}>
          <Contacts />
        </TabsContent>

        <TabsContent value={ContactsRoute.Requests}>
          <Requests />
        </TabsContent>
      </Tabs>
    </Layout>
  );
};

export default ContactsRequests;

import { Box } from "@material-ui/core";
import { UploadMedia } from "components/Shared/UploadMedia";
import { getConfig } from "config/config";
import React, { FC, useEffect, useRef } from "react";
import { RenderStateAttachments } from "scenes/DialogPanel/components/RenderStateAttachments";
import { useAppDispatch, useAppSelector } from "store";
import { mediaActions, selectMediaState } from "store/reducers/mediaSlice";

type Props = {
  onChangeHandler: (e: any) => void
  value: string
  disabled?: boolean
  withMedia?: boolean
  uneditableValue?: string
}
const { theme } = getConfig()

export const TextField: FC<Props> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const { videos, images, documents } = useAppSelector(selectMediaState)

  const { onChangeHandler, value, disabled, withMedia = true, uneditableValue = '' } = props

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }))
  }
  useEffect(() => {
    if (!disabled) ref?.current.focus()
  }, [disabled])

  useEffect(() => {
    ref.current.style.height = "0px";
    const scrollHeight = ref.current.scrollHeight;
    ref.current.style.height = scrollHeight + "px";
  }, [props.value]);

  return <Box style={{
    width: '100%',
    marginLeft: 10,
    minHeight: 50,
    maxHeight: 350,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }}>
    <textarea
      ref={ref}
      onChange={onChangeHandler}
      style={{
        width: '100%',
        // minHeight: 60,
        height: 20,
        borderRadius: 5,
        border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
        backgroundColor: theme.BACKGROUND_PRIMARY,
        color: theme.TEXT_PRIMARY,
        fontFamily: 'Roboto',
        resize: 'none',
        padding: 15,
      }}
      value={uneditableValue + value}
      disabled={disabled}
      onKeyDown={(e: any) => {
        const isBackspace = e.key === 'Backspace'
        const isDelete = e.key === 'Delete'
        if (e.target.value === uneditableValue)
          if (isBackspace || isDelete)
            e.preventDefault()
      }}
    />

    <div style={{
      position: 'absolute',
      marginTop: 5,
      marginRight: 5
    }}>
      {!disabled && withMedia && <UploadMedia slideToBottom />}
    </div>
    <div style={{ height: 20 }} />
    {!disabled && withMedia && <RenderStateAttachments viewType="column" currentAttachments={{ videos, images, documents }} deleteAttachment={deleteAttachment} />}
  </Box>
}
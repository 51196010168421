import React, { FC, useEffect, useState } from 'react';
import { mentoringMarkAsRead, mentoringUpdateRequest } from '../../api/mentoring';
import { getConfig } from '../../config/config';
import { MenteeSearchItem } from './components/MenteeSearchItem';
import EmptyList from '../../components/Shared/EmptyList';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMentoringRequests, mentoringActions, SelectMentoringState } from '../../store/reducers/mentoring';
import { useLanguage } from '../../languages/languageContext';
import { EmptyProfile } from './components/EmptyProfile';
import RequestHeader from '../Requests/components/RequestHeader';
import { Box } from '@material-ui/core';
import AlertModal from 'components/Shared/AlertModal';
import Button from 'components/UI/Button';
import { showResponse } from 'store/actions/response';
import { useHistory } from 'react-router';

export const MentoringRequestsScreen: FC = () => {
  const { mentoring, logoutAlert, btn } = useLanguage();
  const dispatch = useAppDispatch();
  const { setRequests } = mentoringActions;
  const { requests, loading } = useAppSelector(SelectMentoringState);
  const user = useAppSelector((state) => state.users.user);
  const isMentor = user.isMentor || false;
  const outgoingRequest = requests.outgoingRequests?.[0] || null;
  const currentMentor = requests.mentors?.[0] || null;
  const [retractModal, setRetractModal] = useState({ show: false, item: {} as any });
  const [stopMentoringModal, setStopMentoringModal] = useState({ show: false, item: {} as any });
  const mentees = requests.mentees.filter((req) => req.status !== 'finished');
  const stopMentoringMessage = isMentor
    ? `Mentorat mit ${stopMentoringModal?.item?.sender?.name} ${stopMentoringModal?.item?.sender?.lastname} beenden?`
    : `Mentorat mit ${stopMentoringModal?.item?.receiver?.name} ${stopMentoringModal?.item?.receiver?.lastname} beenden?`;
  const history = useHistory();

  const getRequest = async () => {
    dispatch(getMentoringRequests());
  };

  useEffect(() => {
    markAsRead();
  }, [requests]);

  const markAsRead = async () => {
    let haveUncheckedRequests: any = false;
    let message = '';
    if (!isMentor) {
      const uncheckedAcceptedRequest = requests.mentors.find((req) => !req.isInformed && req.status === 'accepted');
      const uncheckedMentorRequest = requests.mentors.find((req) => !req.isInformed && req.status !== 'accepted');
      const uncheckedOutgoingRequest = requests.outgoingRequests.find(
        (req) => !req.isInformed && req.status !== 'sent',
      );

      if (uncheckedAcceptedRequest) haveUncheckedRequests = uncheckedAcceptedRequest;

      if (uncheckedMentorRequest) {
        haveUncheckedRequests = uncheckedMentorRequest;
        message = `Der Mentor ${haveUncheckedRequests.receiver.name} ${haveUncheckedRequests.receiver.lastname} hat das Mentorat beendet.`;
      } else if (uncheckedOutgoingRequest) {
        haveUncheckedRequests = uncheckedOutgoingRequest;
        message = `Mentor ${haveUncheckedRequests.receiver.name} ${haveUncheckedRequests.receiver.lastname} hat deine Anfrage abgelehnt.`;
      }
    }
    if (isMentor) {
      const uncheckedMenteeRequest = requests.mentees.find((req) => !req.isInformed && req.status !== 'accepted');
      const uncheckedIncomingRequest = requests.incomingRequests.find(
        (req) => !req.isInformed && req.status !== 'sent',
      );

      if (uncheckedMenteeRequest) {
        haveUncheckedRequests = uncheckedMenteeRequest;
        message = `Der Mentee ${haveUncheckedRequests.sender.name} ${haveUncheckedRequests.sender.lastname} hat das Mentorat beendet.`;
      } else if (uncheckedIncomingRequest) {
        haveUncheckedRequests = uncheckedIncomingRequest;
        message = `Mentee ${haveUncheckedRequests.sender.name} ${haveUncheckedRequests.sender.lastname} hat die Anfrage zurückgezogen.`;
      }
    }
    if (haveUncheckedRequests) {
      if (haveUncheckedRequests.finisherId === user._id) return;
      if (message.length) dispatch(showResponse({ message, title: 'Mentorat' }));
      await mentoringMarkAsRead(haveUncheckedRequests._id);
      getRequest();
    }
  };

  useEffect(() => {
    getRequest();
  }, []);

  const acceptRequest = async (id: string) => {
    try {
      await mentoringUpdateRequest(id, 'accepted');
      let newMentees = requests.mentees;
      const filtered = requests.incomingRequests.filter((el) => {
        if (el._id === id) {
          let newEl = { ...el, status: 'accepted' };
          newMentees = [...newMentees, newEl];
        }
        return el._id !== id;
      });
      dispatch(setRequests({ ...requests, incomingRequests: filtered, mentees: newMentees }));
    } catch (error) {
      console.log('ERROR ON ACCEPT MENTORING REQUEST', error);
    }
  };

  const declineRequest = async (id: string, type: 'declined' | 'finished' | 'cancelled') => {
    await mentoringUpdateRequest(id, type);

    // if (type === 'declined') {
    const filteredRequests = requests.incomingRequests.filter((el) => el._id !== id);
    const filteredOutgoing = requests.outgoingRequests.filter((el) => el._id !== id);
    const filteredMentees = requests.mentees.filter((el) => {
      return el._id !== id;
    });
    const filteredMentor = requests.mentors.filter((el) => {
      return el._id !== id;
    });
    dispatch(
      setRequests({
        ...requests,
        incomingRequests: filteredRequests,
        outgoingRequests: filteredOutgoing,
        mentees: filteredMentees,
        mentors: filteredMentor,
      }),
    );
    // }
  };

  const handleDeclineRequest = async (request: any, type: 'declined' | 'finished' | 'cancelled') => {
    try {
      const { _id: id } = request;

      declineRequest(request._id, type);
      setRetractModal({ show: false, item: {} });
      setStopMentoringModal({ show: false, item: {} });
    } catch (error) {
      console.log('ERROR ON DECLINE MENTORING REQUEST', error);
    }
  };

  const handleStopMentoring = (item) => setStopMentoringModal({ show: true, item });

  const handleRetractMentoringRequest = (item) => setRetractModal({ show: true, item });

  return (
    <>
      <EmptyProfile>
        <Box
          style={styles.container}
          // refreshControl={<RefreshControl refreshing={loading} onRefresh={getRequest} />}
        >
          {isMentor ? (
            <>
              {requests.incomingRequests?.length ? <RequestHeader title={mentoring.requests.mentorIncoming} /> : null}
              {requests.incomingRequests?.map((item) => (
                <MenteeSearchItem
                  key={item._id}
                  request={item}
                  user={item.sender}
                  acceptRequest={() => acceptRequest(item._id)}
                  declineRequest={() => handleDeclineRequest(item, 'declined')}
                />
              ))}
              {mentees?.length ? <RequestHeader title={mentoring.requests.mentorCurrent} /> : null}
              {mentees?.map(
                (item) =>
                  item.status !== 'finished' && (
                    <MenteeSearchItem
                      key={item._id}
                      request={item}
                      user={item.sender}
                      acceptRequest={() => acceptRequest(item._id)}
                      declineRequest={() => handleStopMentoring(item)}
                    />
                  ),
              )}
              {!requests.incomingRequests?.length && !mentees?.length && <EmptyList />}
            </>
          ) : (
            <>
              {requests?.outgoingRequests?.length ? <RequestHeader title={mentoring.requests.menteeOutgoing} /> : null}
              {requests?.outgoingRequests?.map((item) => (
                <MenteeSearchItem
                  outgoing
                  request={item}
                  user={item.receiver}
                  acceptRequest={() => {}}
                  declineRequest={() => handleRetractMentoringRequest(item)}
                />
              ))}

              {requests?.mentors?.findIndex((el) => el.status !== 'finished') !== -1 ? (
                <RequestHeader title={mentoring.requests.menteeCurrent} />
              ) : null}
              {requests?.mentors?.map(
                (item) =>
                  item.status !== 'finished' && (
                    <MenteeSearchItem
                      onClick={() => history.push(`/mentoring/mentoring-profile/${item.receiver._id}`)}
                      request={item}
                      user={item.receiver}
                      acceptRequest={() => {}}
                      // declineRequest={() => handleDeclineRequest(currentMentor, 'finished')}
                      declineRequest={() => handleStopMentoring(item)}
                    />
                  ),
              )}

              {!outgoingRequest && (!currentMentor || currentMentor.status === 'finished') && <EmptyList />}
            </>
          )}
        </Box>
      </EmptyProfile>
      <AlertModal
        message={mentoring.requests.sureRetractRequest}
        onClose={() => setRetractModal({ show: false, item: {} })}
        showModal={retractModal.show}
        title={'Mentoring-Anfrage'}
      >
        <Button label={btn.cancelBtn} onClick={() => setRetractModal({ show: false, item: {} })} width={140} />
        <Button
          label={logoutAlert.confirm}
          onClick={() => handleDeclineRequest(retractModal.item, 'cancelled')}
          width={140}
        />
      </AlertModal>

      <AlertModal
        message={stopMentoringMessage}
        onClose={() => setStopMentoringModal({ show: false, item: {} })}
        showModal={stopMentoringModal.show}
        title={'Mentorat'}
      >
        <Button label={btn.cancelBtn} onClick={() => setStopMentoringModal({ show: false, item: {} })} width={140} />
        <Button
          label={logoutAlert.confirm}
          onClick={() => handleDeclineRequest(stopMentoringModal.item, 'finished')}
          width={140}
        />
      </AlertModal>
    </>
  );
};

const { theme } = getConfig();
const styles = {
  container: {
    flex: 1,
    marginHorizontal: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: 15,
  },
};

import {
  GET_USER_CONTACTS_REQUEST,
  GET_USER_CONTACTS_SUCCESS,
  GET_USER_CONTACTS_FAILURE,
  CLEAR_USER_CONTACTS,
  GET_CONTACTS_LIST_REQUEST,
  GET_CONTACTS_LIST_SUCCESS,
  GET_CONTACTS_LIST_FAILURE,
} from '../../constants/';

export const getUserContactsRequest = (
  search: boolean,
  userId: string,
  page: number,
  limit: number,
  searchkey?: string,
) => ({
  type: GET_USER_CONTACTS_REQUEST,
  search,
  userId,
  page,
  limit,
  searchkey,
});

export const getUserContactsSuccess = (contacts: Array<any>, total: number, search: boolean) => ({
  type: GET_USER_CONTACTS_SUCCESS,
  contacts,
  total,
  search,
});

export const getUserContactsFailure = (error: object) => ({
  type: GET_USER_CONTACTS_FAILURE,
  error,
});

// CLEAR USER CONTACTS
export const clearUserContacts = () => ({
  type: CLEAR_USER_CONTACTS,
});

// GET CONTACTS LIST
export const getContactsListRequest = () => ({
  type: GET_CONTACTS_LIST_REQUEST,
});

export const getContactsListSuccess = (contacts: Array<object>) => ({
  type: GET_CONTACTS_LIST_SUCCESS,
  contacts,
});

export const getContactsListFailure = (error: object) => ({
  type: GET_CONTACTS_LIST_FAILURE,
  error,
});

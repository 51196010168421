import { takeEvery, call, put, fork } from 'redux-saga/effects';
import { PUT_ADS_REQUEST } from '../../constants/';
import { putAdsSuccess, putAdsFailure } from '../actions/advertisement';
import { showResponse } from '../actions/response';
import { putAds } from '../../api/';

// PUT ADS
function* putAdsWorkerSaga(action) {
  const { adsId, activity } = action;

  try {
    const result = yield call(() => putAds(adsId, activity));

    yield put(putAdsSuccess(result));
  } catch (e) {
    yield put(showResponse(e));
    yield put(putAdsFailure(e));
  }
}

function* putAdsWatcherSaga() {
  yield takeEvery(PUT_ADS_REQUEST, putAdsWorkerSaga);
}

const advertisementSaga = [fork(putAdsWatcherSaga)];

export default advertisementSaga;

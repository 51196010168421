export const countries = [
  'Deutschland',
  'Österreich',
  'Schweiz',
  'Luxemburg',
  'Afghanistan',
  'Ägypten',
  'Åland',
  'Albanien',
  'Algerien',
  'Amerikanisch-Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarktis',
  'Antigua/Barbuda',
  'Äquatorialguinea',
  'Argentinien',
  'Armenien',
  'Aruba',
  'Aserbaidschan',
  'Äthiopien',
  'Australien',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belgien',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivien',
  'Bosnien/Herzegowina',
  'Botsuana',
  'Bouvetinsel',
  'Brasilien',
  'Brunei Darussalam',
  'Bulgarien',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Chile',
  'China',
  'Cookinseln',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Dominikanische Republik',
  'Dschibuti',
  'Dänemark',
  'Ecuador',
  'El Salvador',
  'Elfenbeinküste',
  'Eritrea',
  'Estland',
  'Falklandinseln',
  'Fidschi',
  'Finnland',
  'Frankreich',
  'Französisch-Guayana',
  'Französisch-Polynesien',
  'Französische Südpolarterritorien',
  'Färöer',
  'Gabun',
  'Gambia',
  'Georgien',
  'Ghana',
  'Gibraltar',
  'Grenada',
  'Griechenland',
  'Großbritannien',
  'Grönland',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea-Bissau',
  'Guinea',
  'Guyana',
  'Haiti',
  'Heard und McDonaldinseln',
  'Honduras',
  'Hong Kong',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Irland',
  'Island',
  'Isle Of Man',
  'Israel',
  'Italien',
  'Jamaika',
  'Japan',
  'Jemen',
  'Jersey',
  'Jordanien',
  'Jungferninseln, Amerikanische',
  'Jungferninseln, Britische',
  'Kaimaninseln',
  'Kambodscha',
  'Kamerun',
  'Kanada',
  'Kasachstan',
  'Kenia',
  'Kirgisistan',
  'Kiribati',
  'Kokosinseln',
  'Kolumbien',
  'Komoren',
  'Kongo',
  'Kroatien',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Lettland',
  'Libanon',
  'Liberia',
  'Libyen',
  'Liechtenstein',
  'Litauen',
  'Macao',
  'Madagaskar',
  'Malawi',
  'Malaysia',
  'Maldiven',
  'Mali',
  'Malta',
  'Marshallinseln',
  'Martinique',
  'Mauretanien',
  'Mauritius',
  'Mayotte',
  'Mazedonien',
  'Mexiko',
  'Mikronesien',
  'Moldawien',
  'Monaco',
  'Mongolei',
  'Montenegro',
  'Montserrat',
  'Morokko',
  'Mosambik',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Neukaledonien',
  'Neuseeland',
  'Nicaragua',
  'Niederlande',
  'Niederländische Antillen',
  'Nigeria',
  'Niger',
  'Niue',
  'Nordkorea',
  'Norfolkinsel',
  'Norwegen',
  'Nördliche Marianen',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestina',
  'Panama',
  'Papua-Neuguinea',
  'Paraguay',
  'Peru',
  'Philippinen',
  'Pitcairninseln',
  'Polen',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Ruanda',
  'Rumänien',
  'Russland',
  'Réunion',
  'Salomonen',
  'Sambia',
  'Samoa',
  'San Marino',
  'Saudi-Arabien',
  'Schweden',
  'Senegal',
  'Serbien',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Singapur',
  'Slowakische Republik',
  'Slowenien',
  'Somalia',
  'Spanien',
  'Sri Lanka',
  'St. Barthélemy',
  'St. Helena',
  'St. Kitts/Nevis',
  'St. Lucia',
  'St. Martin',
  'St. Pierre/Miquelon',
  'St. Vincent/Die Grenadinen',
  'Sudan',
  'Surinam',
  'Svalbard/Jan Mayen',
  'Swasiland',
  'Syrien',
  'São Tomé/Príncipe',
  'Südafrika',
  'Südgeorgien/Südlichen Sandwichinseln',
  'Südkorea',
  'Tadschikistan',
  'Taiwan',
  'Tansania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad und Tobago',
  'Tschad',
  'Tschechien',
  'Tunisien',
  'Turkmenistan',
  'Turks- und Caicosinseln',
  'Tuvalu',
  'Türkei',
  'Uganda',
  'Ukraine',
  'Ungarn',
  'United States Minor Islands',
  'Uruguay',
  'USA',
  'Usbekistan',
  'Vanuatu',
  'Vatikanstadt',
  'Venezuela',
  'Vereinigte Arabische Emirate',
  'Vietnam',
  'Wallis/Futuna',
  'Weihnachtsinsel',
  'Weißrussland',
  'Westsahara',
  'Zentralafrikanische Republik',
  'Zypern',
];

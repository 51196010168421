import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';

import { useAppDispatch } from 'store';
// import { clearSearchQuery } from 'store/actions';
import { Config, withConfig } from 'config';

import { ProfileCorpsProps } from '../model';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';

const { theme } = getConfig();

const styles = createStyles({
  contentItem: {
    padding: '10px 0',
  },
  contentTitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  contentSubtitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: '.5px',
  },
  link: {
    // textDecoration: 'none',
  },
  func: {
    color: theme.TEXT_SECONDARY,
    fontSize: 14,
  },
});

interface Props extends WithStyles<typeof styles>, ProfileCorpsProps, RouteComponentProps<any> {
  config: Config;
}

const ProfileCorps: React.FC<Props> = ({ classes, theme, corpsList, navigationMetadata, config }) => {
  const dispatch = useAppDispatch();
  const { profileDetails } = useLanguage();
  return (
    <div className={classes.contentItem}>
      <span className={classes.contentTitle} style={{ color: theme.TEXT_SECONDARY }}>
        {profileDetails.clubLabel}:{' '}
      </span>
      {corpsList &&
        corpsList.map((item) => {
          if (!item.verified) return <></>;
          const charge = !!item?.charge?.length && item.charge !== 'keine' && `(${item.charge})`;

          return (
            <Link
              key={item._id}
              className={classes.link}
              // to={navigationMetadata.type + `?searchKey=${item.corpsname}`}
              to={`/suche/corpsid/${item._id}`}
              // to={'/suche/corpsid/64fee753cc7c27c23d4443c2'}
              // to={`/groups/${item._id}`}
            >
              <span key={item.corpsname} className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
                {item.corpsname} <span className={classes.func}>{charge}</span>
              </span>
            </Link>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(withConfig(withRouter(ProfileCorps)));

import React from 'react';
import { withConfig } from '../../../config/';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import defaultImg from '../../../assets/default_avatar.png';
import { BasisAvatarProps } from '../model';
import { imagePath } from '../../../utilities';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0 0',
  },
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    outline: 'none',
    cursor: 'pointer',
  },
  imgWrapper: {
    width: 140,
    height: 140,
    borderRadius: 70,
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 0,
  },
});

interface Props extends WithStyles<typeof styles>, BasisAvatarProps {}

const BasisAvatar: React.FunctionComponent<Props> = ({ classes, theme, lightBoxHandler, cropImageHandler }) => {
  const { user, userIsLoading } = useSelector((state: any) => state.users);
  let photo;

  if (user && user.photo) {
    photo = imagePath(null, user.photo);
  }

  return (
    <div className={classes.container}>
      <Dropzone
        accept={['image/jpeg', 'image/png']}
        onDrop={(acceptedFiles: object) => {
          cropImageHandler(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className={classes.dropzoneWrapper} {...getRootProps()}>
              <input type="file" {...getInputProps()} />
              <div
                style={{ borderColor: theme.BACKGROUND_SECONDARY }}
                className={classes.imgWrapper}
                onClick={() => {
                  if (photo) {
                    lightBoxHandler();
                  }
                }}
              >
                {userIsLoading ? (
                  <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                ) : (
                  <img src={photo || defaultImg} className={classes.img} alt=" " />
                )}
              </div>
              <h3 className={classes.title} style={{ color: theme.BUTTON_PRIMARY }}>
                Foto editieren
              </h3>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default withStyles(styles)(withConfig(BasisAvatar));

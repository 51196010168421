import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import InputRange from 'react-input-range';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { CropImageHandlerProps } from '../../model';
import 'react-input-range/lib/css/index.css';
import { editOwnerAvatarRequest } from 'store/actions/users';

const styles = createStyles({
  container: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,.3)',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '50px 0',
    boxSizing: 'border-box',
  },
  wrapper: {
    position: 'relative',
    width: '500px',
    background: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '30px 30px',
    boxSizing: 'border-box',
    zIndex: 10,
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rangeWrapper: {
    width: 200,
    margin: '30px 0 70px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    fontFamily: 'Poppins',
    color: '#fff',
    padding: '20px',
    border: 'none',
    cursor: 'pointer',
    background: 'transparent',
    fontSize: 18,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    outline: 'none',
    border: 'none',
  },
  '@media(max-width: 650px)': {
    container: {
      padding: 0,
    },
    wrapper: {
      width: '100%',
      padding: 0,
      height: '100%',
      justifyContent: 'space-between',
    },
  },
});

interface Props extends WithStyles<typeof styles>, CropImageHandlerProps {}

class CropImageHandler extends React.Component<Props> {
  editor: any;

  state = {
    value: 1,
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  onClickSave = () => {
    if (this.editor) {
      const { editOwnerAvatarRequest, closeImageCropper, croppedImage, type, getImagesHandler } = this.props;
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const cropped = canvasScaled.toDataURL('image/jpeg');

      const reader = new FileReader();
      reader.readAsDataURL(croppedImage);

      reader.onloadend = () => {
        const result = reader.result;
        let formattedBase = '';

        if (result) {
          formattedBase = result.toString().replace(/data:image\/png;/, 'data:image/jpeg;');
        }

        if (type === 'update') {
          if (formattedBase) {
            editOwnerAvatarRequest(formattedBase, cropped);
          }
        } else if (type === 'save' && getImagesHandler) {
          if (formattedBase) {
            getImagesHandler(formattedBase, cropped);
          }
        }

        closeImageCropper();
      };
    }
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.topWrapper}>
            <AvatarEditor
              ref={(editor: any) => (this.editor = editor)}
              image={this.props.croppedImage}
              width={300}
              height={300}
              borderRadius={150}
              color={[0, 0, 0, 1]} // RGBA
              style={{ background: '#000', border: 'none' }}
              scale={this.state.value}
              rotate={0}
            />
            <div className={classes.rangeWrapper}>
              <InputRange
                formatLabel={() => ''}
                maxValue={2.1}
                minValue={1}
                step={0.1}
                value={value}
                onChange={(value) => this.setState({ value })}
              />
            </div>
          </div>
          <div className={classes.buttonsWrapper}>
            <button className={classes.button} onClick={this.props.closeImageCropper}>
              Abbrechen
            </button>
            <button className={classes.button} onClick={this.onClickSave}>
              Zuschneiden
            </button>
          </div>
        </div>
        <div onClick={this.props.closeImageCropper}>
          <div className={classes.backdrop}></div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  editOwnerAvatarRequest,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(CropImageHandler));

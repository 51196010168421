import React, { FC, useEffect, useState } from 'react';
import RequestHeader from '../../../components/Shared/RequestHeader';
import BackButton from '../../../components/Shared/BackButton';
import Layout from '../../../components/UI/Layout';
import { getConfig } from '../../../config/config';
import { useLanguage } from '../../../languages/languageContext';
import { useAppDispatch, useAppSelector } from '../../../store';
import { sendMessageRequest } from '../../../store/actions/chat';
import { SelectMentoringState, mentoringActions, getMentoringRequests } from '../../../store/reducers/mentoring';
import { Box } from '@material-ui/core';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { useHistory } from 'react-router';
import { mentoringRepetEmail, mentoringSendRequest, mentoringUpdateRequest } from 'api/mentoring';
import { showResponse } from 'store/actions/response';
import { TextField } from './TextField';
import { mediaActions, selectMediaState } from 'store/reducers/mediaSlice';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import AlertModal from 'components/Shared/AlertModal';
import { DescriptionText } from 'components/UI/DescriptionText';

const { config, theme } = getConfig();
type reason = 'send' | 'alreadySent' | 'declined';
export const MentoringRequestDetailsScreen: FC = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user, reason, message, request } = history.location.state || {};
  const [retractModal, setRetractModal] = useState({ show: false as any });

  const { videos, images, documents, loading: mediaIsLoading } = useAppSelector(selectMediaState);
  const { mentoring, btn, logoutAlert } = useLanguage();
  const { requests } = useAppSelector(SelectMentoringState);

  const alreadySentRequest = config.KAS_MENTORING && requests.outgoingRequests.length;
  const myMentee = !!requests.mentees.find((el) => el.sender._id === user._id && el.status !== 'finished');
  const myMentor = !!requests.mentors.find((item) => item?.receiver?._id === user._id && item.status !== 'finished');

  const me = useAppSelector((state) => state.users.user);
  const isMentor = me.isMentor || false;

  const receivedAttachments = { images: request?.images, videos: request?.videos, documents: request?.documents };

  const defaultMessage = message || `\n\n\n(...)\n\nMit freundlichen Grüßen\n\n${me.name} ${me.lastname}`;
  const defaultRejection = `Hallo!\n\nLeider muss ich die Mentorenfrage ablehnen. Auf Rückfrage kann ich gerne die Gründe dafür erläutern.\n\nMit freundlichen Grüßen\n\n${me.name} ${me.lastname}`;
  const [newMessage, setMessage] = useState(defaultMessage);
  const [rejectionMessage, setRejectionMessage] = useState(defaultRejection);
  useEffect(() => {
    setMessage(defaultMessage);
    setRejectionMessage(defaultRejection);
  }, [history.location.state]);

  const onChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  if (!history.location.state) {
    history.push('/mentoring/requests');
    return <></>;
  }

  const acceptRequest = async (id: string) => {
    try {
      await mentoringUpdateRequest(id, 'accepted');
      let newMentees = requests.mentees;
      const filtered = requests.incomingRequests.filter((el) => {
        if (el._id === id) {
          let newEl = { ...el, status: 'accepted' };
          newMentees = [...newMentees, newEl];
        }
        return el._id !== id;
      });
      history.goBack();
      dispatch(mentoringActions.setRequests({ ...requests, incomingRequests: filtered, mentees: newMentees }));
    } catch (error) {
      console.log('ERROR ON ACCEPT MENTORING REQUEST', error);
    }
  };

  const sendMentoringRequest = async (id: string, text: string) => {
    const mentoringRegions = JSON.parse(localStorage.getItem('mentoringRegions'));
    await mentoringSendRequest(id, text, mentoringRegions, images, videos, documents);
    await dispatch(getMentoringRequests());
    dispatch(mediaActions.deleteAllAttachments());
    history.push('/mentoring/requests');
    localStorage.setItem('mentoringRegions', '');
    dispatch(showResponse({ message: mentoring.requests.requestSuccess, title: 'Mentoring-Anfrage' }));
  };

  const declineRequest = async (id: string, type: 'declined' | 'finished' | 'cancelled') => {
    await mentoringUpdateRequest(id, type);

    // if (type === 'declined') {
    const filteredRequests = requests.incomingRequests.filter((el) => el._id !== id);
    const filteredOutgoing = requests.outgoingRequests.filter((el) => el._id !== id);
    const filteredMentees = requests.mentees.filter((el) => {
      return el._id !== id;
    });
    const filteredMentor = requests.mentors.filter((el) => {
      return el._id !== id;
    });
    dispatch(
      mentoringActions.setRequests({
        ...requests,
        incomingRequests: filteredRequests,
        outgoingRequests: filteredOutgoing,
        mentees: filteredMentees,
        mentors: filteredMentor,
      }),
    );
    // }
  };

  const sendRejectionMessage = () => {
    const notification = {
      uid: user.firebaseID,
      //@ts-ignore
      sender_name: me.name + ' ' + me.lastname,
      //@ts-ignore
      sender: me._id,
      msg_content: rejectionMessage,
    };
    dispatch(mediaActions.deleteAllAttachments());
    dispatch(sendMessageRequest(user.firebaseID, rejectionMessage, notification, { videos, documents, images }));
  };

  const handleReject = () => {
    sendRejectionMessage();
    declineRequest(request._id, 'declined');
    // deleteHandler()
    history.push('/mentoring');
    // navigation.navigate('MentoringScreen')
  };

  return (
    <>
      <Box
        style={{
          flex: 1,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: theme.BACKGROUND,
          padding: 15,
        }}
      >
        <RequestHeader sender={user} route="MentoringScreen" isMentoring />
        {!isMentor && (
          <span
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'Roboto',
              color: theme.TEXT_PRIMARY,
              alignSelf: 'center',
              marginLeft: '3%',
              marginTop: 15,
            }}
          >
            <span style={{ textDecoration: 'underline' }}>Anfrage starten:</span>
            <span style={{ fontWeight: 'bolder' }}>
              Bitte schildere Dein Anliegen für ein Mentorat möglichst klar und präzise – dies ist Grundlage für den
              Mentor / die Mentorin, Deine Anfrage besser einzuschätzen und schnell zu beantworten.
            </span>
            <br />
            <span>
              <span style={{ textDecoration: 'underline' }}>Hinweis:</span>
              <span>
                {' '}
                Du solltest dem Mentor / der Mentorin für eine Antwort bis zu 2 Wochen Zeit geben, bevor Du eine
                Erinnerung schickst. Bevor Du einen anderen Mentor kontaktierst, ziehe bitte Deine laufende Anfrage
                zurück.
              </span>
            </span>
          </span>
        )}
        <Box style={{ width: '90%', margin: '10px 5%' }}>
          <br />
          <span style={styles.description}>{mentoring.requests.invitaionText}</span>
          <TextField onChangeHandler={onChangeHandler} value={newMessage} disabled={!(reason === 'send')} />
          <AttachmentsGallery {...receivedAttachments} />
        </Box>

        <Box style={styles.buttonsWrapper}>
          {isMentor && reason === 'accept' && !myMentee && (
            <Button
              label={mentoring.requests.approveRequest}
              onClick={() => acceptRequest(request._id)}
              background={theme.BUTTON_PRIMARY}
            />
          )}
          {!isMentor && reason === 'declined' && (
            <Button
              label={mentoring.requests.cancelSendingRequest}
              // onClick={() => handler()}
              background={theme.BUTTON_PRIMARY}
            />
          )}
          {reason === 'send' && !alreadySentRequest && (
            <Button
              label={mentoring.requests.sendRequest}
              onClick={() => sendMentoringRequest(user._id, newMessage)}
              background={theme.BUTTON_PRIMARY}
              disabled={!mediaIsLoading}
            />
          )}
          {isMentor && reason === 'accept' && !myMentee && (
            <Button
              label={mentoring.requests.rejectRequest}
              onClick={() =>
                history.replace(`/mentoring/mentoring-request/${request._id}`, {
                  ...history.location.state,
                  reason: 'declined',
                })
              }
              background={theme.ERROR_PRIMARY}
            />
          )}
          {!myMentor && !isMentor && reason === 'accept' && (
            <>
              <Button
                label="Anfrage zurückziehen"
                onClick={() => {
                  setRetractModal({ show: true });
                }}
                background={theme.ERROR_PRIMARY}
              />
              <Button
                label="Erinnerung senden"
                onClick={async () => {
                  await mentoringRepetEmail(request._id);
                  dispatch(
                    showResponse({ message: 'Erinnerung wird gesendet (+ Kopie an dich)', title: 'Mentoring-Anfrage' }),
                  );
                }}
                background={theme.BUTTON_PRIMARY}
              />
            </>
          )}
          {(!isMentor || reason === 'accept') && (
            <Button
              label={btn.cancelBtn}
              onClick={() => history.push('/mentoring/requests')}
              background={theme.BUTTON_SECONDARY}
            />
          )}
        </Box>
        {reason === 'declined' && isMentor && (
          <>
            <Box style={{ width: '90%', margin: '10px 5%' }}>
              <span style={styles.description}>{mentoring.requests.rejectionText}</span>
              <TextField onChangeHandler={(e) => setRejectionMessage(e.target.value)} value={rejectionMessage} />
            </Box>
            <Box style={styles.buttonsWrapper}>
              <Button label={'Anfrage ablehnen'} onClick={handleReject} background={theme.BUTTON_PRIMARY} />
              <Button
                label={btn.cancelBtn}
                onClick={() => {
                  history.push('/mentoring/requests');
                }}
                background={theme.BUTTON_SECONDARY}
              />
            </Box>
          </>
        )}
      </Box>
      <AlertModal
        message={mentoring.requests.sureRetractRequest}
        onClose={() => setRetractModal({ show: false })}
        showModal={retractModal.show}
        title={'Mentoring-Anfrage'}
      >
        <Button label={btn.cancelBtn} onClick={() => setRetractModal({ show: false })} width={140} />
        <Button
          label={logoutAlert.confirm}
          onClick={async () => {
            const mentoringRequestId = requests.outgoingRequests.findIndex((req) => req?.receiver?._id === user?._id);
            await mentoringUpdateRequest(requests.outgoingRequests[mentoringRequestId]._id, 'cancelled');
            const filteredRequests = requests.outgoingRequests.filter((_, idx) => idx === mentoringRequestId);
            dispatch(mentoringActions.setRequests({ ...requests, outgoingRequests: filteredRequests }));
            await dispatch(getMentoringRequests());
            dispatch(showResponse({ message: mentoring.requests.requestRetracted }));
            history.goBack();
          }}
          width={140}
        />
      </AlertModal>
    </>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonsWrapper: {
    margin: 20,
  },
  description: {
    marginLeft: 12,
    marginVertical: 10,
    fontSize: 16,
    color: theme.TEXT_SECONDARY,
  },
};

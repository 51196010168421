import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { withConfig } from '../../config';
import { TouchableInputProps } from '../model';

const styles = createStyles({
  container: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  labelWrapper: {
    padding: '0 25px 0 25px',
    width: '100%',
    marginBottom: 5,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 9,
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  input: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    outline: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#353A54',
    boxSizing: 'border-box',
    minHeight: 50,
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
  button: {
    width: '100%',
    borderRadius: 50,
  },
  placeholder: {
    fontFamily: 'Roboto',
    lineHeight: '12px',
    textAlign: 'left',
  },
  errorWrapper: {
    width: '100%',
    display: 'flex',
    margin: '10px 0 10px 0',
    padding: '0 25px 0 25px',
    boxSizing: 'border-box',
  },
  error: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
});

interface Props extends WithStyles<typeof styles>, TouchableInputProps {
  isOpen?: boolean;
}

const Input: React.FC<Props> = ({
  label,
  value,
  theme,
  placeholder,
  classes,
  disabled,
  error,
  isOpen = false,
  ...props
}) => {
  const formatValue = () => {
    let newValue = '';

    if (value && value.length > 30) {
      const str = value.slice(0, 29);
      newValue = `${str}...`;
      return newValue;
    }

    return value;
  };

  const formattedValue = formatValue();

  return (
    <div className={classes.container}>
      {value && value.trim().length > 0 ? (
        <div className={classes.labelWrapper}>
          <span className={classes.label} style={{ color: theme.TEXT_SECONDARY }}>
            {placeholder}
          </span>
        </div>
      ) : null}
      <ButtonBase
        disabled={disabled}
        className={classes.button}
        style={{ cursor: !disabled ? 'pointer' : 'default' }}
        {...props}
      >
        <div
          className={classes.input}
          style={{
            padding: '8px 25px 8px 25px',
            borderColor: theme.BACKGROUND_SECONDARY,
            background: theme.BACKGROUND_PRIMARY,
            borderBottomRightRadius: isOpen ? 0 : 50,
          }}
        >
          <span
            className={classes.placeholder}
            style={{ color: disabled ? theme.ICON_BORDER : !formattedValue && theme.TEXT_SECONDARY }}
          >
            {formattedValue || placeholder}
          </span>
          {!disabled && (
            <div>
              {!disabled && isOpen ? (
                <ArrowDropUp style={{ color: theme.BUTTON_SECONDARY, marginTop: 2 }} />
              ) : (
                <ArrowDropDown style={{ color: theme.BUTTON_SECONDARY, marginTop: 2 }} />
              )}
            </div>
          )}
        </div>
      </ButtonBase>
      {error ? (
        <div className={classes.errorWrapper}>
          <span className={classes.error} style={{ marginRight: 5, color: theme.ERROR_PRIMARY }}>
            •
          </span>
          <span className={classes.error} style={{ color: theme.ERROR_PRIMARY }}>
            {error}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(withConfig(Input));

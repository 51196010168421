import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';

import { withConfig, Theme } from 'config';

import Like from 'assets/icons/Like';

const styles = createStyles({
  likeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  leftButton: {
    boxSizing: 'border-box',
    width: 50,
    marginRight: 2,
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: '200ms',
    },
  },
  rightButton: {
    padding: '12px 0',
    width: 50,
    borderRadius: '0 50px 50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '200ms',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: '200ms',
    },
  },
  likeLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '20px',
    display: 'block',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  likedUsers: Array<string>;
  likedPost: boolean;
  showLikesHandler: () => void;
  likeHandler: () => void;
}

const CommentsLikeButton: React.FC<Props> = ({
  classes,
  theme,
  likedUsers,
  likedPost,
  showLikesHandler,
  likeHandler,
}) => {
  return (
    <div className={classes.likeButton} style={{ color: theme.BUTTON_PRIMARY }}>
      <ButtonBase
        className={classes.leftButton}
        style={{ background: likedPost ? theme.BUTTON_LIGHT : theme.BACKGROUND_PRIMARY }}
        onClick={likeHandler}
      >
        {
          <Like
            color={likedPost ? theme.BUTTON_PRIMARY : 'transparent'}
            stroke={likedPost ? 'transparent' : theme.BUTTON_PRIMARY}
          />
        }
        {/* {likedPost ? (
          <LikeIcon style={{ marginLeft: 6, marginBottom: 1 }} />
        ) : (
          <LikeButtonIcon style={{ marginLeft: 6, marginBottom: 1 }} />
        )} */}
      </ButtonBase>
      <ButtonBase
        className={classes.rightButton}
        style={{ background: likedUsers.length === 0 ? theme.BACKGROUND_PRIMARY : theme.BUTTON_LIGHT }}
        onClick={showLikesHandler}
        disabled={likedUsers.length === 0}
      >
        <span className={classes.likeLabel} style={{ color: theme.BUTTON_PRIMARY, marginRight: 5 }}>
          {likedUsers.length || '0'}
        </span>
      </ButtonBase>
    </div>
  );
};

export default withStyles(styles)(withConfig(CommentsLikeButton));

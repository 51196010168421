import React, { FC } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { withConfig } from '../../config/';
import Dropzone from 'react-dropzone';
import Button from 'components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';

const { theme } = getConfig();

const styles = createStyles({
  wrapper: {
    // marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  addButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  backdrop: {
    width: '100%',
    height: '10rem',
    borderRadius: 20,
    borderWidth: 1,
    border: 'dashed',
    borderColor: theme.ICON_BORDER,
    backgroundColor: `${theme.ICON_BORDER}30`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragover: {
    backgroundColor: `${theme.ICON_BORDER}50`,
  },
});

interface Props extends WithStyles<typeof styles> {
  [key: string]: any;
  formats: Array<string>;
  icon: any;
  onDrop: any;
  viewType?: 'default' | 'button' | 'dropzone';
  multiple?: boolean;
}

const CustomDropzone: FC<Props> = (props) => {
  const { mentoring } = useLanguage();
  const { classes, onDrop, formats, icon, viewType = 'default', multiple } = props;

  return (
    <Dropzone
      // accept={["image/jpeg", "image/png", "application/pdf"]}
      accept={formats}
      onDrop={(acceptedFile: any) => {
        onDrop(acceptedFile);
      }}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) =>
        viewType === 'dropzone' ? (
          <div
            {...getRootProps()}
            className={classes.backdrop}
            onDragEnterCapture={(e) => {
              e.currentTarget.className = classes.backdrop + ' ' + classes.dragover;
            }}
            onDragLeaveCapture={(e) => {
              e.currentTarget.className = classes.backdrop;
            }}
          >
            <input type="file" {...getInputProps()} />
            <label
              htmlFor="file"
              style={{ alignSelf: 'center', justifySelf: 'center', color: theme.ICON_BORDER, textAlign: 'center' }}
            >
              Drop files here <br />
              <p style={{ fontSize: 12 }}>or click here to select</p>
            </label>
          </div>
        ) : viewType === 'button' ? (
          <div {...getRootProps()}>
            <input type="file" {...getInputProps()} />
            <Button label={mentoring.profile.uploadPdf} width={300} />
          </div>
        ) : (
          <section>
            <div className={classes.wrapper} style={{ background: theme.BACKGROUND_SECONDARY }} {...getRootProps()}>
              <input type="file" {...getInputProps()} />
              <ButtonBase className={classes.addButton}>{icon}</ButtonBase>
            </div>
          </section>
        )
      }
    </Dropzone>
  );
};

export default withStyles(styles)(withConfig(CustomDropzone));

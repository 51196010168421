import { setConfig } from 'config';

export const profileImagePath = (type: string | null, path: any): any => {
  const { config } = setConfig();
  if (!path) return null;
  if (path?.url?.length) path = path.url;
  if (path?.includes('http')) {
    return path;
  }

  if (path.trim().length > 0) {
    let serverPath = type === 'full' ? '/avatars/full/' : '/avatars/';
    return checkImage(config.SERVER_PATH + serverPath + path).then((res: any) => {
      if (res.status === 'ok') {
        return (config.SERVER_PATH + serverPath + path).toString();
      } else {
        return (config.SERVER_PATH + '/avatars/' + path).toString();
      }
    });
  }
  return null;
};

const checkImage = (path) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: 'ok' });
    img.onerror = () => resolve({ path, status: 'error' });

    img.src = path;
  });
};

export const trimValues = (values: string | string[] | any) => {
  if (typeof values === 'string') {
    return values.trim();
  }
  if (Array.isArray(values)) {
    return values.map((val) => (typeof val === 'string' ? val?.trim() : val));
  }
  if (typeof values === 'object') {
    updateValuesInObject(values);
  }
  return values;
};
const updateValuesInObject = (values: any) => {
  if (!values) return values;
  let updatedValues = values;
  for (const [key, value] of Object.entries(values)) {
    if (typeof value === 'string') updatedValues[key] = value?.trim();
    if (typeof value === 'object') updateValuesInObject(value);
  }
  return updatedValues;
};

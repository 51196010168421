// GET USER CONTACTS
export const GET_USER_CONTACTS_REQUEST = 'GET_USER_CONTACTS_REQUEST';
export const GET_USER_CONTACTS_SUCCESS = 'GET_USER_CONTACTS_SUCCESS';
export const GET_USER_CONTACTS_FAILURE = 'GET_USER_CONTACTS_FAILURE';

// CLEAR USER CONTACTS
export const CLEAR_USER_CONTACTS = 'CLEAR_USER_CONTACTS';

// GET CONTACT LIST
export const GET_CONTACTS_LIST_REQUEST = 'GET_CONTACTS_LIST_REQUEST';
export const GET_CONTACTS_LIST_SUCCESS = 'GET_CONTACTS_LIST_SUCCESS';
export const GET_CONTACTS_LIST_FAILURE = 'GET_CONTACTS_LIST_FAILURE';

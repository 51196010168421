import React, { useState, memo, FC, FocusEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import Autocomplete from 'react-autocomplete';
import { getConfig } from 'config/config';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import { Label } from 'components/shadcn/label';
import { LayoutHook } from 'hooks';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 99,
    boxSizing: 'border-box',
  },
  listItem: {
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 5,
    color: theme.TEXT_PRIMARY,
  },
  label: {
    marginBottom: 8,
    padding: '0 20px',
    display: 'block',
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  errorWrapper: {
    width: '100%',
    display: 'flex',
    margin: '10px 0 10px 0',
    padding: '0 25px 0 25px',
    boxSizing: 'border-box',
  },
  error: {
    fontSize: 15,
    fontFamily: 'Roboto',
    color: theme.ERROR_PRIMARY,
  },
  autocompleteStyle: {
    width: '100%',
    border: '1px solid',
    padding: '4px 12px',
    height: '2.5rem',
    borderRadius: 50,
    boxSizing: 'border-box',
    color: theme.TEXT_PRIMARY,
    fontFamily: 'Roboto',
    fontSize: 14,
    outline: 'none',
  },
});

interface IDataAutocompleteInput {
  label: string;
  value: string;
}

interface IAutocomleteInputProps {
  data: IDataAutocompleteInput[];
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (value: string) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  placeholder: string;
  name?: string;
}

const AutocomleteInput: FC<IAutocomleteInputProps> = ({
  value = '',
  label,
  onChange,
  onSelect,
  onFocus,
  onBlur,
  error,
  touched,
  data,
  placeholder,
  name,
}) => {
  const styles = useStyles({});

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [width] = LayoutHook();

  const filteredData = data.filter((item: IDataAutocompleteInput) =>
    item?.value?.trim()?.toLowerCase()?.includes(value?.toLowerCase()),
  );

  const focusHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (!isFocused) {
      setIsFocused(true);
      if (typeof onFocus === 'function') onFocus(e);
    } else {
      setIsFocused(false);
      if (typeof onBlur === 'function') onBlur(e);
    }
  };

  return (
    <>
      <Label className="ml-3 mb-1" htmlFor={name}>
        {label}
      </Label>
      <Autocomplete
        wrapperStyle={{
          width: '100%',
          boxSizing: 'border-box',
        }}
        menuStyle={{
          borderRadius: '3px',
          position: 'absolute',
          left: 0,
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          fontSize: '90%',
          overflow: 'auto',
          maxHeight: '30%',
          padding: '2px 20px',
          marginLeft: '30px',
          background: 'white',
          boxSizing: 'border-box',
          zIndex: 2,
          marginTop: width > 700 ? 0 : -50,
        }}
        inputProps={{
          onFocus: focusHandler,
          onBlur: focusHandler,
          className: styles.autocompleteStyle,
          style: { borderColor: isFocused ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY },
          placeholder,
          name,
        }}
        getItemValue={(item) => item.value}
        items={filteredData}
        renderItem={(item, isHighlighted) => (
          <div key={item.value} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            <span className={styles.listItem}>{item.value}</span>
          </div>
        )}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
      />
      <ErrorLabel>{touched && error}</ErrorLabel>
    </>
  );
};

export default memo(AutocomleteInput);

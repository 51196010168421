import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { addVoteComments, listVotesComments, removeVoteComments } from 'api/comments';
import { getVotes, removeVote, addVote } from 'api/news';
import { Button } from 'components/shadcn/button';
import { Modal } from 'components/Shared/Modal/Modal';
import { TEventSurvey } from 'components/Shared/Survey/Survey';
import { IonIcon } from 'components/UI/IonIcon';
import Loader from 'components/UI/Loader';
import { useLanguage } from 'languages/languageContext';
import { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { showResponse } from 'store/actions/response';
import { modifyComments } from 'store/reducers/commentsSlice';
import { modifyPost } from 'store/reducers/news';
import { cn } from 'utilities/utils';
const defaultImg = require('../../../assets/default_avatar.png');

const SurveyPost: FC<{ question: TEventSurvey; type: 'post' | 'comment'; postId: string; showProgress: boolean }> = ({
  question,
  type,
  postId,
  showProgress,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const { surveys: lSurveys, eventLabels } = useLanguage();

  const [votesModal, setVotesModal] = useState(false);

  const userId = user?._id;

  const votersNumber = question.options.reduce((acc, opt) => acc + opt.voters?.length, 0);

  const vote = question?.options?.find((opt) =>
    //@ts-ignore
    opt.voters.some((vote) => vote.user === userId || vote.user?._id === userId),
  )?._id;

  const isQuizVoted = question.type === 'quiz' && !!vote;

  const handleModal = () => {
    if (votesModal) {
      setVotesModal(false);
    }
    if (!votesModal) {
      setVotesModal(true);
    }
  };

  const handleAddVote = async (optionId) => {
    if (type === 'post') return await addVote(postId, question._id, optionId);
    if (type === 'comment') return await addVoteComments(postId, question._id, optionId);
  };
  const handleRemoveVote = async (optionId) => {
    if (type === 'post') return await removeVote(postId, optionId);
    if (type === 'comment') return await removeVoteComments(postId, question._id, optionId);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <span className="font-medium">{question.text}</span>
          {question.isAnonym && (
            <span className="text-xs text-gray-400">
              {question.type === 'quiz' ? eventLabels.parts.isAnonymQuiz : eventLabels.parts.isAnonym}
            </span>
          )}
        </div>
      </div>
      {question.options.map((option) => {
        if (question.type === 'freeText') return <></>;
        // @ts-ignore
        const checked = option.voters.some((el) => el.user === userId || el.user?._id === userId);

        const quantity = option.voters?.length;
        const percentage = (100 / votersNumber) * quantity || 0;

        const isQuizAnswered = question.type === 'quiz' && checked;

        const quizAnsweredCorrect = isQuizAnswered && option.correctAnswer;

        const quizAnsweredWrong = isQuizAnswered && !option.correctAnswer;

        return (
          <Fragment key={option._id}>
            <button
              disabled={isQuizVoted}
              onClick={async () => {
                try {
                  let res;
                  if (vote && !checked) {
                    await handleRemoveVote(vote);
                  }
                  if (checked) res = await handleRemoveVote(option._id);
                  else res = await handleAddVote(option._id);

                  delete res.survey;
                  if (type === 'post') {
                    dispatch(modifyPost({ type: 'news', post: res }));
                    dispatch(modifyPost({ type: 'kreiseNews', post: res }));
                  }
                  if (type === 'comment') {
                    dispatch(modifyComments({ type: 'comments', comment: res }));
                    dispatch(modifyComments({ type: 'eventComments', comment: res }));
                  }
                } catch (error) {
                  if (error.Status) dispatch(showResponse({ message: error.Status }));
                  else dispatch(showResponse({ message: error }));
                }
              }}
              className={cn(
                'mb-2 w-full text-left hover:opacity-50 flex flex-row flex-1',
                isQuizVoted && 'hover:opacity-100',
              )}
            >
              <div className={cn('w-8 h-[36px] flex flex-col justify-end mr-1', vote && 'justify-between')}>
                <span className="text-xs self-center">{percentage.toFixed(0)}%</span>
                <div
                  className={cn(
                    'w-[18px] h-[18px] bg-slate-300 rounded-full mr-2 self-end border border-slate-300 items-center justify-center flex',
                    checked && 'bg-primary-btn',
                    isQuizAnswered && quizAnsweredWrong && 'bg-red-500',
                    isQuizAnswered && quizAnsweredCorrect && 'bg-[#4BB543]',
                  )}
                >
                  {isQuizAnswered && quizAnsweredCorrect ? (
                    <IonIcon name="checkmark" color="white" size={14} />
                  ) : isQuizAnswered && quizAnsweredWrong ? (
                    <IonIcon name="close" color="white" size={14} />
                  ) : isQuizVoted && option.correctAnswer ? (
                    <IonIcon name="checkmark" color="white" size={14} />
                  ) : (
                    checked && <IonIcon name="checkmark" color="white" size={14} />
                  )}
                </div>
              </div>
              <div className="flex-1">
                <span>{option.title}</span>

                {showProgress && (
                  <div className="relative w-full h-2 rounded-lg bg-slate-100">
                    <div
                      className={cn(
                        `absolute h-2 rounded-lg duration-300 bg-[#cbd5e1]`,
                        checked && 'bg-primary-btn',
                        quizAnsweredWrong && 'bg-red-500',
                        quizAnsweredCorrect && 'bg-[#4BB543]',
                      )}
                      style={{ width: `${percentage}%` }}
                    />
                  </div>
                )}
              </div>
            </button>
          </Fragment>
        );
      })}
      <Button
        className={cn('text-sm rounded-md px-2 py-1 mt-2 w-fit h-fit')}
        onClick={handleModal}
        disabled={question?.isAnonym}
        variant={!question?.isAnonym ? 'default' : 'ghost'}
      >
        {votersNumber} {votersNumber === 1 ? lSurveys.votes.single : lSurveys.votes.multiple}
      </Button>
      <Modal modalTitle={lSurveys.votesModalTitle} isOpen={votesModal} handleClose={handleModal}>
        <div className="px-4 py-2 w-96 max-h-[90vh] overflow-y-auto">
          {question?.options?.map((opt) => (
            <VotesModalPart key={opt._id} option={opt} postId={postId} type={type} />
          ))}
        </div>
      </Modal>
    </div>
  );
};

const VotesModalPart = ({
  option,
  postId,
  type,
}: {
  option: TEventSurvey['options'][0];
  postId: string;
  type: 'post' | 'comment';
}) => {
  const { surveys: lSurveys } = useLanguage();

  const { data, isFetching } = useQuery({
    queryKey: [option._id],
    queryFn: async () => {
      if (type === 'comment') return await listVotesComments(postId, option._id);
      return await getVotes(postId, option._id);
    },
    placeholderData: keepPreviousData,
    refetchOnMount: true,
  });

  return (
    <div className="gap-y-2 flex flex-col">
      <span>
        <span className="font-medium">{option.title}</span>: {data?.length}{' '}
        {data?.length === 1 ? lSurveys.votes.single : lSurveys.votes.multiple}
      </span>
      {isFetching && <Loader showLoader={true} />}
      {data?.map((user) => (
        <Link key={user._id} to={`/profile-details/${user._id}`} target="_blank" rel="noopener noreferrer">
          <div className="flex flex-row gap-x-2">
            <img className="w-12 h-12 rounded-full border" src={user.photo || defaultImg} alt="avatar" />
            <h2>
              {user.name} {user.lastname}
            </h2>
          </div>
        </Link>
      ))}
      <div className="mt-2">{!data?.length && lSurveys.noVotes}</div>
    </div>
  );
};

export default SurveyPost;

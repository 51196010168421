export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SIGN_UP_MANUAL_REQUEST = 'SIGN_UP_MANUAL_REQUEST';
export const SIGN_UP_MANUAL_SUCCESS = 'SIGN_UP_MANUAL_SUCCESS';
export const SIGN_UP_MANUAL_FAILURE = 'SIGN_UP_MANUAL_FAILURE';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

export const SIGN_IN_GOOGLE_REQUEST = 'SIGN_IN_GOOGLE_REQUEST';
export const SIGN_IN_GOOGLE_SUCCESS = 'SIGN_IN_GOOGLE_SUCCESS';
export const SIGN_IN_GOOGLE_FAILURE = 'SIGN_IN_GOOGLE_FAILURE';

export const GET_ACCOUNT_INFO_GOOGLE_REQUEST = 'GET_ACCOUNT_INFO_GOOGLE_REQUEST';
export const GET_ACCOUNT_INFO_GOOGLE_SUCCESS = 'GET_ACCOUNT_INFO_GOOGLE_SUCCESS';
export const GET_ACCOUNT_INFO_GOOGLE_FAILURE = 'GET_ACCOUNT_INFO_GOOGLE_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const DELETE_OWNER_REQUEST = 'DELETE_OWNER_REQUEST'
export const DELETE_OWNER_SUCCESS = 'DELETE_OWNER_SUCCESS'
export const DELETE_OWNER_FAILURE = 'DELETE_OWNER_FAILURE'
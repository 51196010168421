import React, { useEffect, useState } from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { closeMenu } from '../../store/actions/layout';
import { LayoutHook } from '../../hooks';
import Drawer from '../../scenes/Drawer/';
import { LayoutProps } from '../model';
import DrawerSignIn from '../../scenes/Drawer/components/DrawerSignIn';
import { useAppDispatch, useAppSelector } from 'store';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { clearKreise, clearUserKreise } from 'store/reducers/kreise';
import { clearAds } from 'store/reducers/news';
import { clearResults } from 'store/reducers/globalSearch';
import Button from 'components/UI/Button';
import { clearChatUsers, clearUnreadMessages, getChatUsersRequest } from 'store/actions/chat';
import { signOutRequest } from 'store/actions/auth';
import { clearUserRequest } from 'store/actions/users';
import { clearUserContacts } from 'store/actions/contacts';
import { clearUserRequests } from 'store/actions/requests';
import { useHistory } from 'react-router';
import AlertModal from 'components/Shared/AlertModal';
import Loader from './Loader';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    overflowY: 'auto',
  },
  main: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  wrapper: {
    padding: '0 10px',
  },
  childrenContainer: {
    // flexGrow: 1,
    borderRadius: 10,
    position: 'relative',
    zIndex: 0,
    width: '600px',
    minHeight: '99vh',
  },
  drawerWrapper: {
    position: 'sticky',
    top: 0,
    width: '25%',
    boxShadow: '0px 0px 5px 0 #333',
    zIndex: 1,
    overflowY: 'auto',
  },
  mobileDrawerWrapper: {
    width: '70%',
    top: 0,
    position: 'fixed',
    boxSizing: 'border-box',
    transition: 'margin .5s',
    zIndex: 4,
    boxShadow: '0px 0px 5px 0px #333',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  backdrop: {
    opacity: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, .5)',
    zIndex: 2,
    outline: 'none',
    border: 'none',
    transition: 'all .5s',
  },
  drawerContainer: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    width: '300px',
    // zIndex: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '@media(max-width: 1024px)': {
    main: {},
    wrapper: {},
    childrenContainer: {
      width: '450px',
    },
    drawerContainer: {
      width: '250px',
    },
  },
  '@media(max-width: 650px)': {
    mobileDrawerWrapper: {
      marginTop: 0,
    },
    main: {
      width: '100%',
      paddingTop: 55,
    },
    drawerContainer: {
      width: 0,
      zIndex: 10,
    },
    menuButtonWrapper: {
      display: 'block',
    },
    childrenContainer: {
      width: '100%',
    },
  },
});

interface Props extends WithStyles<typeof styles>, LayoutProps {
  headerPadding?: number;
  children: React.ReactNode;
  isLoading?: boolean;
}

const Layout: React.FC<Props> = ({ children, classes, backgroundColor, headerPadding, isLoading, ...props }) => {
  const [width] = LayoutHook();
  const dispatch = useAppDispatch();
  const {
    widget: { isWidget },
  } = useAppSelector((state) => state.common);
  const isOpen = useAppSelector((state) => state.layout.menuIsOpen);
  const userId = getLocalStorage('userId');

  const history = useHistory();
  const { logoutAlert } = useLanguage();

  const [showModal, setShowModal] = useState(false);

  let headerHeight: undefined | number = undefined;
  if (headerPadding) {
    headerHeight = width > 650 ? headerPadding + 20 : headerPadding;
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const logoutHandler = () => {
    dispatch(clearChatUsers());
    dispatch(getChatUsersRequest(false));
    dispatch(clearUnreadMessages());
    dispatch(signOutRequest());
    dispatch(clearUserRequest());
    dispatch(clearKreise());
    dispatch(clearUserContacts());
    dispatch(clearUserRequests());
    dispatch(clearUserKreise());
    dispatch(clearAds());
    dispatch(clearResults());
    history.push('/sign-in');
  };

  return (
    <>
      <div style={{ maxWidth: 1440, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 10 }}>
        <div className={classes.drawerContainer}>
          {width > 650 && (
            <div
              style={{
                display: isWidget ? 'none' : undefined,
                width: '100%',
              }}
            >
              {userId ? <Drawer handleLogOut={() => setShowModal(true)} /> : <DrawerSignIn />}
            </div>
          )}
          {width < 650 && (
            <div className={classes.mobileDrawerWrapper} style={{ marginLeft: isOpen ? '0%' : '-100%' }}>
              {userId ? <Drawer handleLogOut={() => setShowModal(true)} /> : <DrawerSignIn />}
            </div>
          )}
        </div>
        <div
          className={classes.main}
          style={{
            background: theme.BACKGROUND_SECONDARY,
            // borderColor: theme.NAV_BAR,
            marginLeft: isWidget ? 0 : undefined,
          }}
        >
          <div
            className={classes.wrapper}
            style={{
              background: theme.BACKGROUND_SECONDARY,
              // paddingTop: headerHeight + 10,
              width: '100%',
            }}
          >
            <div
              className={classes.childrenContainer}
              style={{
                background: backgroundColor || theme.BACKGROUND,
              }}
              {...props}
            >
              {children}
            </div>
          </div>
        </div>
        {width < 650 && (
          <div
            className={classes.backdrop}
            onClick={() => {
              dispatch(closeMenu());
            }}
            style={{
              opacity: isOpen ? 1 : 0,
              visibility: isOpen ? 'visible' : 'hidden',
            }}
          ></div>
        )}
      </div>
      <AlertModal showModal={showModal} message={logoutAlert.text} title={logoutAlert.title}>
        <Button label={logoutAlert.confirm} onClick={logoutHandler} width={120} />
        <Button label={logoutAlert.dismiss} onClick={() => setShowModal(false)} width={120} />
      </AlertModal>
    </>
  );
};

export default withStyles(styles)(Layout);

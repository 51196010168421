export const getAge = (date: string) => {
  const myAge = new Date(date);

  const today = new Date();
  const birthDate = new Date(myAge);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return +age;
};

import React, { useRef, useState, useEffect } from 'react';
import { Link, Switch, Route, RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, Tab, Tabs } from '@material-ui/core';

import { withConfig, withAuth, Config } from 'config/';
import Layout from 'components/UI/Layout';
import ScreenTitle from 'components/Shared/ScreenTitle';
import { handleFilterSearchParams } from 'utilities';

import { ProfileItemRedirectTypes } from '../ProfileDetails/model';
import { SucheProps } from './model';
import { GlobalSearchTab } from './components/GlobalSearch/GlobalSearchTab';
import SearchTab from './components/SearchTab/SearchTab';
import FilteredTab from './components/SearchTab/FilteredTab';
import { useAppDispatch } from 'store';
import { TSearchFilter, searchActions } from 'store/reducers/searchSlice';
import { useLanguage } from 'languages/languageContext';

const borderColor = process.env.REACT_APP_BUTTON_PRIMARY;

const styles = createStyles({
  container: {
    flexGrow: 1,
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  // title: {
  //     textAlign: 'center'
  // },
  wrapper: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabsWrapper: {
    position: 'fixed',
    top: 50,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: 10,
    zIndex: 10,
    boxShadow: '0 2px 5px 0 #ccc',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  indicator: {
    background: borderColor,
    height: 4,
  },
  link: {
    padding: '10px 30px',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 20,
  },
  '@media(max-width: 650px)': {
    link: {
      padding: '10px 15px',
      fontSize: 14,
    },
  },
});

function tabProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, SucheProps {
  config: Config;
}

const Suche: React.FC<Props> = (props) => {
  const { search } = useLanguage();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [value, setValue] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const { classes, theme, config } = props;
  const { path } = props.match;

  const setContainerWidthHandler = () => {
    window.addEventListener('resize', setContainerWidthHandler, { passive: false });
    if (container && container.current) {
      setContainerWidth(container.current.offsetWidth);
    }
    return () => window.removeEventListener('resize', setContainerWidthHandler);
  };

  const handleFilterTabChange = ({ pathname }) => {
    const routeToTabIndex = tabs.find((el) => el?.props?.label?.props?.to === pathname.replace('/users', ''))?.props
      ?.tabIndex;
    const isStudyType =
      pathname === ProfileItemRedirectTypes.Hochschule || pathname === ProfileItemRedirectTypes.Studiengang;
    if (routeToTabIndex !== -1) {
      setValue(isStudyType ? routeToTabIndex + 1 : routeToTabIndex);
    }
  };

  const handleChange = (e: any, value: any) => {
    setValue(value);
  };

  const saveScrollPos = () => {
    const { pathname } = history.location;
    if (pathname.includes('/users') || pathname.includes('/global')) return;
    // @ts-ignore
    const key: TSearchFilter = pathname.replace('/suche/', '').split('/')[0];
    dispatch(searchActions.setScrollPos({ value: window.scrollY, key }));
  };

  const linkComponent = (route: string, title: string) => (
    <Link to={`${path}${route}`} onClick={saveScrollPos} className={classes.link} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </Link>
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = props;
    handleFilterSearchParams(props.location.search, () => handleFilterTabChange({ pathname }));
    setContainerWidthHandler();
  }, []);

  const tabs = [
    <Tab label={linkComponent('/global', search.global)} {...tabProps(0)} tabIndex={0} key={0} />,
    <Tab label={linkComponent('/name', search.name)} {...tabProps(1)} tabIndex={1} key={1} />,
    // <Tab label={linkComponent('/corpsname', search.corps)} {...tabProps(2)} tabIndex={2} key={2} />,
    <Tab label={linkComponent('/corpsid', search.corps)} {...tabProps(2)} tabIndex={2} />,
    <Tab label={linkComponent('/funktion', search.position)} {...tabProps(3)} tabIndex={3} key={3} />,
    <Tab label={linkComponent('/stadt', search.city)} {...tabProps(4)} tabIndex={4} key={4} />,
    <Tab label={linkComponent('/postcode', search.postCode)} {...tabProps(5)} tabIndex={5} key={5} />,
    <Tab label={linkComponent('/land', search.country)} {...tabProps(6)} tabIndex={6} key={6} />,
    config.WITH_WORK && config.APP_NAME !== 'gdl' && (
      <Tab label={linkComponent('/industry', search.industry)} {...tabProps(7)} tabIndex={7} key={7} />
    ),
    config.WITH_WORK && <Tab label={linkComponent('/company', search.company)} {...tabProps(8)} tabIndex={8} key={8} />,
    config.WITH_WORK && (
      <Tab label={linkComponent('/beruf', search.profession)} {...tabProps(9)} tabIndex={9} key={9} />
    ),
    config.WITH_WORK && config.APP_NAME === 'dphv' && (
      <Tab label={linkComponent('/activity', search.activity)} {...tabProps(10)} tabIndex={10} key={10} />
    ),
    <Tab label={linkComponent('/interessen', search.hobby)} {...tabProps(11)} tabIndex={11} key={11} />,
    config.WITH_EDUCATION && (
      <Tab label={linkComponent('/studiengang', search.study)} {...tabProps(12)} tabIndex={12} key={12} />
    ),
    config.WITH_EDUCATION && (
      <Tab label={linkComponent('/hochschule', search.university)} {...tabProps(13)} tabIndex={13} key={13} />
    ),
  ];

  return (
    <Layout>
      <ScreenTitle title="Suche" />
      <div>
        <div>
          <Switch>
            <Route exact path={`${props.match.path}`} render={() => <Redirect to={`${props.match.path}/global`} />} />
            <Route key={'global'} path={`${path}/global`} exact component={GlobalSearchTab} />
            {/* <Route key={'corpsname'} path={`${path}/corpsname`} exact component={SearchTab} />
            <Route key={'corpsname/users'} path={`${path}/corpsname/users/:id`} exact component={FilteredTab} /> */}
            <Route key={'corpsid'} path={`${path}/corpsid`} exact component={SearchTab} />
            <Route key={'corpsid/users'} path={`${path}/corpsid/:id`} exact component={FilteredTab} />
            <Route key={'funktion'} path={`${path}/funktion`} exact component={SearchTab} />
            <Route key={'funktion/users'} path={`${path}/funktion/users`} exact component={FilteredTab} />
            <Route key={'stadt'} path={`${path}/stadt`} exact component={SearchTab} />
            <Route key={'stadt/users'} path={`${path}/stadt/users`} exact component={FilteredTab} />
            <Route key={'land'} path={`${path}/land`} exact component={SearchTab} />
            <Route key={'land/users'} path={`${path}/land/users`} exact component={FilteredTab} />
            <Route key={'postcode'} path={`${path}/postcode`} exact component={SearchTab} />
            <Route key={'name'} path={`${path}/name`} exact component={SearchTab} />
            <Route key={'industry'} path={`${path}/industry`} exact component={SearchTab} />
            <Route key={'industry/users'} path={`${path}/industry/users`} exact component={FilteredTab} />
            <Route key={'company'} path={`${path}/company`} exact component={SearchTab} />
            <Route key={'company/users'} path={`${path}/company/users`} exact component={FilteredTab} />
            <Route key={'beruf'} path={`${path}/beruf`} exact component={SearchTab} />
            <Route key={'beruf/users'} path={`${path}/beruf/users`} exact component={FilteredTab} />
            <Route key={'activity'} path={`${path}/activity`} exact component={SearchTab} />
            <Route key={'activity/users'} path={`${path}/activity/users`} exact component={FilteredTab} />
            <Route key={'interessen'} path={`${path}/interessen`} exact component={SearchTab} />
            <Route key={'interessen/users'} path={`${path}/interessen/users`} exact component={FilteredTab} />
            <Route key={'studiengang'} path={`${path}/studiengang`} exact component={SearchTab} />
            <Route key={'studiengang/users'} path={`${path}/studiengang/users`} exact component={FilteredTab} />
            <Route key={'hochschule'} path={`${path}/hochschule`} exact component={SearchTab} />
            <Route key={'hochschule/users'} path={`${path}/hochschule/users`} exact component={FilteredTab} />
          </Switch>
        </div>
      </div>
      <div className={classes.container} ref={container}>
        {containerWidth && (
          <div style={{ width: containerWidth }}>
            <div className={classes.tabsWrapper} style={{ background: theme.BACKGROUND, width: containerWidth }}>
              <Tabs
                style={{ padding: '5px 0 0' }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                {tabs}
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withStyles(styles)(withAuth(withConfig(Suche)));

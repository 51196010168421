import React, { useContext, useState, useEffect, createContext } from 'react';
import { useSelector } from 'react-redux';
import languages from './languages';
import { useAppSelector } from 'store';
// import React, { useContext, useState, useEffect, createContext } from 'react'
// import { ActivityIndicator } from 'react-native'
// import languages from './languages'
// import { useSelector } from 'react-redux'
// import { RootState } from '../store'
// import { useConfig } from '../config/config'

const langs = languages()[0];

export type translationsType = typeof langs;

type languageContextType = {
  langId: string;
  setLangId: (c: string) => void;
  language: translationsType;
};

const LanguageContext = createContext({} as languageContextType);

interface Props {
  children: any;
}

export const LanguageContextProvider: React.FC<Props> = ({ children }) => {
  const defaultLanguage =
    process.env.REACT_APP_NAME === 'ambassador' ||
    process.env.REACT_APP_NAME === 'jmg' ||
    process.env.APP_NAME === 'kiwanisnl'
      ? 'ENG'
      : 'GER';
  const common = useAppSelector((state) => state.common);

  const fullfilledLang = languages(common);
  const [langId, setLangId] = useState<string>(defaultLanguage);
  const user = useSelector((state: any) => state.users.user);

  const getLanguage = (langId: string) => fullfilledLang.find((theme: any) => theme.key === langId) || defaultLanguage;

  useEffect(() => {
    async function getLang() {
      if (user && user.language) {
        setLangId(user.language);
      } else {
        setLangId(defaultLanguage);
      }
    }
    getLang();
  }, [user]);

  // @ts-ignore
  const language: translationsType = getLanguage(langId);

  return (
    <LanguageContext.Provider value={{ langId, setLangId, language }}>
      {langId ? children : <span>Preloader</span>}
    </LanguageContext.Provider>
  );
};

export const withLanguage = (Component: any) => (props: any) => {
  const { setLangId, language } = useContext(LanguageContext);

  const setlanguage = (langId: string) => {
    setLangId(langId);
  };

  return <Component {...props} languages={languages} language={language} setlanguage={setlanguage} />;
};

export const useLanguage = (): translationsType => {
  const { language } = React.useContext(LanguageContext);
  return language;
};

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig, Theme } from '../../../config';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 30px',
  },
  text: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
  contentWrapper: {
    padding: '10px 0',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  title: string;
  children: JSX.Element;
}

const InfoSection: React.FC<Props> = ({ theme, classes, title, children }) => (
  <div className={classes.container}>
    <span className={classes.text} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </span>
    <div className={classes.contentWrapper}>{children}</div>
  </div>
);

export default withStyles(styles)(withConfig(InfoSection));

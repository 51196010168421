import React from 'react';
import { TextareaAutosize, makeStyles } from '@material-ui/core';

import { getConfig } from 'config/config';

import { IonIcon } from 'components/UI/IonIcon';

const { theme } = getConfig();

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '75%',
    padding: '10px',
    zIndex: 10,
    boxSizing: 'border-box',
    boxShadow: '1px 0 5px 0 #ccc',
    background: theme.BACKGROUND_PRIMARY,
  },
  inputWrapper: {
    position: 'relative',
    flexGrow: 1,
    marginRight: 10,
    boxSizing: 'border-box',
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    fontFamily: 'Poppins',
    padding: '5px 0',
    border: 'none',
    outline: 'none',
    fontSize: 15,
    resize: 'none',
    width: '100%',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      paddingTop: 2,
    },
    background: theme.BACKGROUND_PRIMARY,
  },
  '@media(max-width: 1024px)': {
    container: {
      right: 0,
      padding: '10px',
      left: '30.2%',
      width: '70%',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
    inputWrapper: {
      paddingLeft: 10,
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

interface Props {
  message: string;
}

export const LockedPostToolbar: React.FC<Props> = ({ message }) => {
  const classes = useStyles({});

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.inputWrapper}>
          <IonIcon name="information-circle-outline" size={24} />
          <TextareaAutosize
            rowsMax={6}
            style={{ background: theme.BACKGROUND_PRIMARY, pointerEvents: 'none' }}
            className={classes.input}
            placeholder={message}
          />
        </div>
      </div>
    </div>
  );
};

import { getConfig } from "config/config";
import React, { FC } from "react";

const { theme } = getConfig()
/**
* @name icons can be found here https://ionic.io/ionicons
*/

export const IonIcon: FC<{ name: string, size?: number, color?: string, style?: React.CSSProperties, strokeWidth?: number }> = (props) => {
  const { name, size = 16, color = theme.ACTIVE_INPUT, strokeWidth = 'unset', style = {} } = props
  //@ts-ignore
  return <ion-icon
    name={name}
    style={{
      width: size,
      height: size,
      color,
      '--ionicon-stroke-width': strokeWidth,
      ...style
    }}
  />
}


import * as React from 'react'

const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 118 79" {...props}>
    <path d="M2 2.5c-1 1.2-.8 5.4.9 21.2C5.3 45.9 5.4 51.2 4 59.1c-.6 3.1-1.4 8.6-1.8 12.3l-.9 6.7 4.1-.3c3.9-.3 4.2-.5 5.5-5.1l1.5-4.7H31v3.4c0 5.6.8 6.6 5.1 6.6 2.4 0 3.9-.5 3.9-1.3.1-.6.2-5.5.3-10.7.2-6.6.8-10.5 2-12.9.9-1.9 1.7-4.2 1.7-5.2 0-2.7-5.8-3.9-18.3-3.9-10.2 0-10.8-.1-11.7-2.3-.5-1.2-1.6-10.3-2.5-20.2C10.6 11.6 9.4 3 8.8 2.3 7.4.5 3.5.7 2 2.5zm25.8 51.1c3.5.7 4.2 1.9 3.5 5.7-.5 2.7-.6 2.7-9.4 2.7H13v-2.9c0-5.4.8-6.1 6.5-6.1 2.9 0 6.6.3 8.3.6zM110 2.5c-1.1 1.3-4.9 31.4-5 39.2 0 2-.5 2.1-13.8 2.5-15 .3-17.8 1.2-16.6 5.2.3 1.2 1.2 2.8 2 3.6.9 1.1 1.4 5.2 1.6 13l.3 11.5h4c3.5 0 4-.3 4.3-2.5.8-7.4.3-7.1 10.3-6.8l9.1.3 1.7 4.5c1.4 3.7 2.1 4.6 4.5 4.8 1.6.2 3.2 0 3.7-.5s.3-5.3-.6-11.4c-1.9-13.2-1.9-28.9 0-40.2 2-12.3 2.2-22.7.4-23.8-2.1-1.3-4.5-1.1-5.9.6zm-5 52.7c0 .7.3 2.3.6 3.5.6 2.3.5 2.3-8.5 2.3H88v-7h8.5c6.1 0 8.5.3 8.5 1.2z" />
    <path d="M24.4 26.5c-1.6 2.4-1.6 2.7.1 4.5 1.6 1.8 3.1 2 16.2 2H55v35.9l-4.2.3c-4.3.3-4.3.3-4.3 4.3v4l11.9.3c12.6.3 14.6-.3 14.6-4.2 0-3.1-1.9-4.6-5.7-4.6H64V33h15.8c11 0 16.2-.4 17-1.2 1.7-1.7 1.5-6.5-.4-7.2-.9-.3-17-.6-36-.6H26l-1.6 2.5z" />
  </svg>
)

export default SvgComponent

import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { updatePasswordRequest } from '../../store/actions/auth';
import { RouteComponentProps } from 'react-router-dom';
import { withConfig, Theme } from '../../config/';
import { validateInput, patterns } from '../../utilities/validation';
import Logo from '../../components/UI/TextLogo';
import IconUI from '../../components/UI/IconUI';
import Input from '../../components/UI/Input';
import Heading from '../../components/Shared/Heading';
import Button from '../../components/UI/Button';
import Loader from '../../components/UI/Loader';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 0 60px 0',
  },
  wrapper: {
    width: 360,
  },
  inputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  authIsLoading: boolean;
  updatePasswordRequest: (token: string, newPassword: string, email: string) => void;
  language: translationsType;
}

interface State {
  password: {
    value: string;
    error: string;
    isValid: boolean;
    isFocused: boolean;
  };
}

class ChangePassword extends React.Component<Props, State> {
  state: State = {
    password: {
      value: '',
      error: '',
      isValid: false,
      isFocused: false,
    },
  };

  changeTextHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { invalidInputs } = this.props.language;
    let { value } = e.target;
    value = value.replace(/(\s)/g, '');

    const error = invalidInputs.passwordChars;
    const isValid = validateInput(value, patterns.password);

    this.setState({
      password: {
        value,
        error: isValid ? '' : error,
        isValid,
        isFocused: true,
      },
    });
  };

  focusHandler = (): void => {
    this.setState({
      password: {
        ...this.state.password,
        isFocused: !this.state.password.isFocused,
      },
    });
  };

  toLoginHandler = (): void => {
    const { history } = this.props;

    history.push('/sign-in');
  };

  updateHandler = () => {
    const { history } = this.props;
    const url = history.location.search;
    let email;
    if (url.includes('email=')) {
      let specificIndex = url.indexOf('email=');
      email = url.substring(specificIndex + 'email='.length);
    }
    const { updatePasswordRequest } = this.props;
    const { token } = this.props.match.params;
    const { password } = this.state;

    updatePasswordRequest(token, password.value, email);
  };

  render() {
    const { classes, theme, authIsLoading } = this.props;
    const { password } = this.state;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Logo />
            <IconUI />
            <Heading title={this.props.language.password.resetLabel} />
            <div className={classes.inputsWrapper}>
              <Input
                placeholder={this.props.language.password.inputLabel}
                onChange={this.changeTextHandler}
                autoCapitalize="none"
                error={password.error}
                value={password.value}
                isFocused={password.isFocused}
                onBlur={this.focusHandler}
                onFocus={this.focusHandler}
                security={true}
              />
            </div>
            <Button label="Aktualisieren" disabled={password.isValid} onClick={this.updateHandler} />
            <Button
              label="Abbrechen"
              background={theme.BUTTON_SECONDARY}
              disabled={true}
              onClick={this.toLoginHandler}
            />
          </div>
        </div>
        <Loader showLoader={authIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  authIsLoading: auth.authIsLoading,
});

const mapDispatchToProps = {
  updatePasswordRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLanguage(withStyles(styles)(withConfig(ChangePassword))));

// GET POST LIKES
export const GET_POST_LIKES_REQUEST = 'GET_POST_LIKES_REQUEST';
export const GET_POST_LIKES_SUCCESS = 'GET_POST_LIKES_SUCCESS';
export const GET_POST_LIKES_FAILURE = 'GET_POST_LIKES_FAILURE';

// GET COMMENTS LIKES
export const GET_COMMENTS_LIKES_REQUEST = 'GET_COMMENTS_LIKES_REQUEST';
export const GET_COMMENTS_LIKES_SUCCESS = 'GET_COMMENTS_LIKES_SUCCESS';
export const GET_COMMENTS_LIKES_FAILURE = 'GET_COMMENTS_LIKES_FAILURE';

// CLEAR LIKES
export const CLEAR_POST_LIKES = 'CLEAR_POST_LIKES';
export const CLEAR_COMMENTS_LIKES = 'CLEAR_COMMENTS_LIKES';

// LIKE POST
export const LIKE_POST_REQUEST = 'LIKE_POST_REQUEST';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_FAILURE = 'LIKE_POST_FAILURE';

// LIKE POST
export const UNLIKE_POST_REQUEST = 'UNLIKE_POST_REQUEST';
export const UNLIKE_POST_SUCCESS = 'UNLIKE_POST_SUCCESS';
export const UNLIKE_POST_FAILURE = 'UNLIKE_POST_FAILURE';

// LIKE COMMENT
export const LIKE_COMMENT_REQUEST = 'LIKE_COMMENT_REQUEST';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAILURE = 'LIKE_COMMENT_FAILURE';

// LIKE POST
export const UNLIKE_COMMENT_REQUEST = 'UNLIKE_COMMENT_REQUEST';
export const UNLIKE_COMMENT_SUCCESS = 'UNLIKE_COMMENT_SUCCESS';
export const UNLIKE_COMMENT_FAILURE = 'UNLIKE_COMMENT_FAILURE';

// GET EVENT LIKES
export const GET_EVENT_LIKES_REQUEST = 'GET_EVENT_LIKES_REQUEST';
export const GET_EVENT_LIKES_SUCCESS = 'GET_EVENT_LIKES_SUCCESS';
export const GET_EVENT_LIKES_FAILURE = 'GET_EVENT_LIKES_FAILURE';

export const CLEAR_EVENT_LIKES = 'CLEAR_EVENT_LIKES';

// LIKE EVENT
export const LIKE_EVENT_REQUEST = 'LIKE_EVENT_REQUEST';
export const LIKE_EVENT_SUCCESS = 'LIKE_EVENT_SUCCESS';
export const LIKE_EVENT_FAILURE = 'LIKE_EVENT_FAILURE';

// UNLIKE EVENT
export const UNLIKE_EVENT_REQUEST = 'UNLIKE_EVENT_REQUEST';
export const UNLIKE_EVENT_SUCCESS = 'UNLIKE_EVENT_SUCCESS';
export const UNLIKE_EVENT_FAILURE = 'UNLIKE_EVENT_FAILURE';

// LIKE EVENT COMMENT
export const LIKE_EVENT_COMMENT_REQUEST = 'LIKE_EVENT_COMMENT_REQUEST';
export const LIKE_EVENT_COMMENT_SUCCESS = 'LIKE_EVENT_COMMENT_SUCCESS';
export const LIKE_EVENT_COMMENT_FAILURE = 'LIKE_EVENT_COMMENT_FAILURE';

// UNLIKE EVENT COMMENT
export const UNLIKE_EVENT_COMMENT_REQUEST = 'UNLIKE_EVENT_COMMENT_REQUEST';
export const UNLIKE_EVENT_COMMENT_SUCCESS = 'UNLIKE_EVENT_COMMENT_SUCCESS';
export const UNLIKE_EVENT_COMMENT_FAILURE = 'UNLIKE_EVENT_COMMENT_FAILURE';

// GET EVENT COMMENT LIKES
export const GET_EVENT_COMMENT_LIKES_REQUEST = 'GET_EVENT_COMMENT_LIKES_REQUEST';
export const GET_EVENT_COMMENT_LIKES_SUCCESS = 'GET_EVENT_COMMENT_LIKES_SUCCESS';
export const GET_EVENT_COMMENT_LIKES_FAILURE = 'GET_EVENT_COMMENT_LIKES_FAILURE';

export const CLEAR_EVENT_COMMENT_LIKES = 'CLEAR_EVENT_COMMENT_LIKES';

import { makeStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import React, { FC, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { globalSearchResponseType } from 'store/reducers/globalSearch';
import { IonIcon } from 'components/UI/IonIcon';
import { findOccurrences } from 'utilities/findOccurences';
import { useLanguage } from 'languages/languageContext';

type Props = {
  post?: globalSearchResponseType['posts']['data'][0];
  query: string;
};

export const PostItem: FC<Props> = memo((props) => {
  const { post, query } = props;

  const { globalSearch } = useLanguage();

  const { _id, content, kreise } = post;
  const styles = useStyles({});
  const [occurences, setOccurences] = useState({ content: [] as string[], poster: '' });

  useEffect(() => {
    if (query.length >= 3 && !!content?.length) {
      const contentMathes: string[] = findOccurrences(query, content);
      const poster = post.sender.name + ' ' + post.sender.lastname;
      const isPosterFound = poster.toLowerCase().includes(query.toLowerCase());
      const postedBy: string = isPosterFound && globalSearch.postedBy + ' ' + poster;

      setOccurences({ content: contentMathes, poster: postedBy });
    }
  }, []);

  return (
    <Link className={styles.container} to={{ pathname: `/posts-details/${_id}` }}>
      <div className={styles.imageWrapper}>
        <div className={styles.scaledImaged}>
          <IonIcon name="megaphone-outline" color={theme.ICON_BORDER} size={22} />
        </div>
      </div>
      <div className={styles.infoWrapper}>
        {!!kreise?.name?.length && <span className={styles.name}>{kreise?.name || ''}</span>}
        <span className={styles.occurences}>{occurences.poster}</span>
        {occurences?.content?.length ? (
          occurences?.content?.map((el) => <span className={styles.occurences}>...{el}...</span>)
        ) : (
          <span className={styles.occurences}>{content.length ? content.slice(0, 30) + '...' : content}</span>
        )}
      </div>
    </Link>
  );
});

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
    color: theme.ICON_BORDER,
  },
  scaledImaged: {
    scale: 1.5,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1,
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  field: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  occurences: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    color: theme.TEXT_PRIMARY,
  },
});

import React, { FC, useEffect, useState } from 'react'

import { mentoringGetData } from '../../api/mentoring'
import KreisePosts from '../KreisePosts'
import { EmptyProfile } from './components/EmptyProfile'
import { useHistory, useParams } from 'react-router'

export const MentoringPostsScreen: FC = () => {
	const { kreiseId } = useParams<{ kreiseId?: string }>()
	const router = useHistory()

	async function getData() {
		const data = await mentoringGetData()
		//@ts-ignore
		router.replace(router.location.pathname + '/' + data.newsKreise)
	}

	useEffect(() => {
		getData()
	}, [])

	if (!kreiseId?.length) return <></>

	return <EmptyProfile>
		<KreisePosts params={{ mentoring: true }} />
	</EmptyProfile>
}

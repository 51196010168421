import React, { FC } from "react";
import { getConfig, withConfig } from "../../../config/config";
import { useLanguage } from "../../../languages/languageContext";
import { WithStyles, createStyles, withStyles } from "@material-ui/styles";
import { IonIcon } from 'components/UI/IonIcon'
import linkDecorator from "components/UI/linkDecorator";
import Linkify from 'react-linkify';

const { theme } = getConfig()

interface ISocialMediaProps extends WithStyles<typeof styles> {
    items: {
        label: string;
        value: string;
    }[];
}

const styles = createStyles({
    title: {
        fontSize: 15,
        marginBottom: '5px',
        color: theme.TEXT_SECONDARY,
    },
    link: {
        textDecorationLine: 'underline',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 500,
        letterSpacing: '.5px',
    },
    linkContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        color: '#2671D1',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 500,
    }
})

const SocialMediaLink: FC<ISocialMediaProps> = ({ items, classes }) => {
    const { profileOther } = useLanguage();

    if (!items?.length) return null;

    return (
        <>
            <div className={classes.title}>
                {profileOther.socialMediaLabel}:
            </div>
            {items.map(({ label, value }) => (
                <Linkify key={label} componentDecorator={linkDecorator} textDecorator={() => label}>
                    <div className={classes.linkContainer}>
                        <IonIcon name={'logo-' + label.split(' ')?.[0]} size={15} style={{ marginRight: '5px' }} />
                        {value}
                    </div>
                </Linkify>
            ))}
        </>
    )
}

export default withStyles(styles)(withConfig(SocialMediaLink));
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig, Theme } from '../../config/';
import Autocomplete from 'react-autocomplete';

const styles = createStyles({
  container: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  },
  listItem: {
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  label: {
    marginBottom: 8,
    padding: '0 20px',
    display: 'block',
    fontSize: 14,
    fontFamily: 'Roboto',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  value: string;
  label: string;
  onChange: () => void;
  onSelect: (value: string) => void;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  items: string[];
  placeholder: string;
}

const AutocomleteInput: React.FC<Props> = ({
  classes,
  theme,
  value = '',
  label,
  onChange,
  onSelect,
  onFocus,
  onBlur,
  isFocused,
  items,
  placeholder = '',
}) => {
  const filteredItems = items?.filter((item: any) => item.label.toLowerCase().includes(value.toLowerCase()));

  return (
    <div className={classes.container}>
      <span className={classes.label} style={{ color: theme.TEXT_SECONDARY }}>
        {label}
      </span>
      <Autocomplete
        wrapperStyle={{ width: '100%', zIndex: 9999, boxSizing: 'border-box' }}
        menuStyle={{
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          fontSize: '90%',
          position: 'fixed',
          overflow: 'auto',
          maxHeight: '30%',
          padding: '2px 20px',
          boxSizing: 'border-box',
          zIndex: 9999,
        }}
        inputProps={{
          onFocus,
          onBlur,
          style: {
            width: '100%',
            border: '1px solid',
            borderColor: isFocused ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY,
            background: theme.BACKGROUND_PRIMARY,
            padding: '12px 20px',
            borderRadius: 50,
            boxSizing: 'border-box',
            color: theme.TEXT_PRIMARY,
            fontFamily: 'Roboto',
            fontSize: 14,
            outline: 'none',
          },
          placeholder,
        }}
        getItemValue={(item) => item.label}
        items={filteredItems}
        renderItem={(item, isHighlighted) => (
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.id}>
            <span className={classes.listItem} style={{ color: theme.TEXT_PRIMARY }}>
              {item.label}
            </span>
          </div>
        )}
        value={value}
        onChange={onChange}
        onSelect={(val) => onSelect(val)}
      />
    </div>
  );
};

export default withStyles(styles)(withConfig(AutocomleteInput));

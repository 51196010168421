import {
  GET_POST_LIKES_REQUEST,
  GET_POST_LIKES_SUCCESS,
  GET_POST_LIKES_FAILURE,
  GET_COMMENTS_LIKES_REQUEST,
  GET_COMMENTS_LIKES_SUCCESS,
  GET_COMMENTS_LIKES_FAILURE,
  CLEAR_POST_LIKES,
  CLEAR_COMMENTS_LIKES,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  LIKE_POST_FAILURE,
  UNLIKE_POST_REQUEST,
  UNLIKE_POST_SUCCESS,
  UNLIKE_POST_FAILURE,
  LIKE_COMMENT_REQUEST,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  UNLIKE_COMMENT_REQUEST,
  UNLIKE_COMMENT_SUCCESS,
  UNLIKE_COMMENT_FAILURE,
  GET_EVENT_LIKES_REQUEST,
  GET_EVENT_LIKES_SUCCESS,
  GET_EVENT_LIKES_FAILURE,
  CLEAR_EVENT_LIKES,
  LIKE_EVENT_REQUEST,
  LIKE_EVENT_SUCCESS,
  LIKE_EVENT_FAILURE,
  UNLIKE_EVENT_REQUEST,
  UNLIKE_EVENT_SUCCESS,
  UNLIKE_EVENT_FAILURE,
  LIKE_EVENT_COMMENT_REQUEST,
  LIKE_EVENT_COMMENT_SUCCESS,
  LIKE_EVENT_COMMENT_FAILURE,
  UNLIKE_EVENT_COMMENT_REQUEST,
  UNLIKE_EVENT_COMMENT_SUCCESS,
  UNLIKE_EVENT_COMMENT_FAILURE,
  GET_EVENT_COMMENT_LIKES_REQUEST,
  GET_EVENT_COMMENT_LIKES_SUCCESS,
  GET_EVENT_COMMENT_LIKES_FAILURE,
  CLEAR_EVENT_COMMENT_LIKES,
} from '../../constants';

const initialState = {
  postLikes: [],
  commentsLikes: [],
  eventLikes: [],
  eventCommentLikes: [],
  eventCommentLikesIsLoading: false,
  postLikesIsLoading: false,
  eventLikesIsLoading: false,
  commentsLikesIsLoading: false,
  likeEventIsLoading: false,
  likeEventCommentIsLoading: false,
  postLikesCount: 0,
  commentsLikesCount: 0,
  eventLikesTotal: 0,
  eventCommentLikesTotal: 0,
};

export const likes = (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_LIKES_REQUEST:
      return {
        ...state,
        postLikesIsLoading: true,
      };
    case GET_POST_LIKES_SUCCESS:
      return {
        ...state,
        postLikes: state.postLikes.concat(action.likes),
        postLikesIsLoading: false,
        postLikesCount: action.total,
      };
    case GET_POST_LIKES_FAILURE:
      return {
        ...state,
        error: action.error,
        postLikesIsLoading: false,
      };
    case GET_COMMENTS_LIKES_REQUEST:
      return {
        ...state,
        commentsLikesIsLoading: true,
      };
    case GET_COMMENTS_LIKES_SUCCESS:
      return {
        ...state,
        commentsLikes: state.commentsLikes.concat(action.likes),
        commentsLikesIsLoading: false,
        commentsLikesCount: action.total,
      };
    case GET_COMMENTS_LIKES_FAILURE:
      return {
        ...state,
        error: action.error,
        commentsLikesIsLoading: false,
      };
    case CLEAR_POST_LIKES:
      return {
        ...state,
        postLikes: [],
        postLikesCount: 0,
      };
    case CLEAR_COMMENTS_LIKES:
      return {
        ...state,
        commentsLikes: [],
        commentsLikesCount: 0,
      };

    case LIKE_POST_REQUEST:
      return {
        ...state,
        postLikesIsLoading: true,
      };
    case LIKE_POST_SUCCESS:
      return {
        ...state,
        postLikesIsLoading: false,
      };
    case LIKE_POST_FAILURE:
      return {
        ...state,
        postLikesIsLoading: false,
      };
    case UNLIKE_POST_REQUEST:
      return {
        ...state,
        postLikesIsLoading: true,
      };
    case UNLIKE_POST_SUCCESS:
      return {
        ...state,
        postLikesIsLoading: false,
      };
    case UNLIKE_POST_FAILURE:
      return {
        ...state,
        postLikesIsLoading: false,
      };
    case LIKE_COMMENT_REQUEST:
      return {
        ...state,
        commentsLikesIsLoading: true,
      };
    case LIKE_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLikesIsLoading: false,
      };
    case LIKE_COMMENT_FAILURE:
      return {
        ...state,
        commentsLikesIsLoading: false,
      };
    case UNLIKE_COMMENT_REQUEST:
      return {
        ...state,
        commentsLikesIsLoading: true,
      };
    case UNLIKE_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLikesIsLoading: false,
      };
    case UNLIKE_COMMENT_FAILURE:
      return {
        ...state,
        commentsLikesIsLoading: false,
      };
    // EVENT LIKES
    case GET_EVENT_LIKES_REQUEST:
      return {
        ...state,
        eventLikesIsLoading: true,
      };
    case GET_EVENT_LIKES_SUCCESS:
      return {
        ...state,
        eventLikesIsLoading: false,
        eventLikes: state.eventLikes.concat(action.eventLikes),
        eventLikesTotal: action.total,
      };
    case GET_EVENT_LIKES_FAILURE:
      return {
        ...state,
        eventLikesIsLoading: false,
      };
    case CLEAR_EVENT_LIKES:
      return {
        ...state,
        eventLikes: [],
        eventLikesTotal: 0,
      };
    // LIKE EVENT
    case LIKE_EVENT_REQUEST:
      return {
        ...state,
        likeEventIsLoading: true,
      };
    case LIKE_EVENT_SUCCESS:
      return {
        ...state,
        likeEventIsLoading: false,
      };
    case LIKE_EVENT_FAILURE:
      return {
        ...state,
        likeEventIsLoading: false,
      };
    // UNLIKE EVENT
    case UNLIKE_EVENT_REQUEST:
      return {
        ...state,
        likeEventIsLoading: true,
      };
    case UNLIKE_EVENT_SUCCESS:
      return {
        ...state,
        likeEventIsLoading: false,
      };
    case UNLIKE_EVENT_FAILURE:
      return {
        ...state,
        likeEventIsLoading: false,
      };
    // LIKE EVENT COMMENT
    case LIKE_EVENT_COMMENT_REQUEST:
      return {
        ...state,
        likeEventCommentIsLoading: true,
      };
    case LIKE_EVENT_COMMENT_SUCCESS:
      return {
        ...state,
        likeEventCommentIsLoading: false,
      };
    case LIKE_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        likeEventCommentIsLoading: false,
      };
    // UNLIKE EVENT COMMENT
    case UNLIKE_EVENT_COMMENT_REQUEST:
      return {
        ...state,
        likeEventCommentIsLoading: true,
      };
    case UNLIKE_EVENT_COMMENT_SUCCESS:
      return {
        ...state,
        likeEventCommentIsLoading: false,
      };
    case UNLIKE_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        likeEventCommentIsLoading: false,
      };
    // GET EVENT COMMENT LIKES
    case GET_EVENT_COMMENT_LIKES_REQUEST:
      return {
        ...state,
        eventCommentLikesIsLoading: true,
      };
    case GET_EVENT_COMMENT_LIKES_SUCCESS:
      return {
        ...state,
        eventCommentLikesIsLoading: false,
        eventCommentLikes: state.eventCommentLikes.concat(action.likes),
        eventCommentLikesTotal: action.total,
      };
    case GET_EVENT_COMMENT_LIKES_FAILURE:
      return {
        ...state,
        eventCommentLikesIsLoading: false,
      };
    case CLEAR_EVENT_COMMENT_LIKES:
      return {
        ...state,
        eventCommentLikes: [],
        eventCommentLikesTotal: 0,
      };
    default:
      return {
        ...state,
      };
  }
};

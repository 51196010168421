import * as api from 'api/attachments';

export const setS3Prefix = async () => {
  try {
    // @ts-ignore
    const { url } = await api.getS3Prefix();
    localStorage.setItem('s3Prefix', url);
  } catch (error) {
    console.error('Cant set s3Prefix', error);
  }
};

export const getS3Link = (url: string) => {
  const s3Prefix = localStorage.getItem('s3Prefix');
  return `${s3Prefix}/${url}`;
};

import React, { useEffect, useState } from 'react';
import { withLanguage } from '../../languages/';
import SelectModal from '../../components/Shared/SelectModal';
import TouchableInput from '../../components/UI/TouchableInput';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserRequest } from '../../store/actions/users';

interface Props {
  language: any;
  setlanguage: (langId: string) => void;
  offlineMode: boolean | undefined;
}

const LanguageSwitch: React.FC<Props> = ({ language, setlanguage, offlineMode }) => {
  const { switchLanguage } = language;
  const [value, setValue] = useState<any>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const user = useSelector((state: any) => state.users.user);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getLang() {
      if (user && user.language) {
        setValue(user.language);
      }
    }
    getLang();
  }, [user]);

  const onItemSelect = (label: string, name: string) => {
    let language = label;

    if (label === switchLanguage.GER) language = 'GER';
    else if (label === switchLanguage.ENG) language = 'ENG';
    else if (label === switchLanguage.SP) language = 'SP';
    else if (label === switchLanguage.FRA) language = 'FRA';
    else if (label === switchLanguage.ITA) language = 'ITA';
    else if (label === switchLanguage.CIN) language = 'CIN';
    else if (label === switchLanguage.JAP) language = 'JAP';
    else if (label === switchLanguage.KOR) language = 'KOR';
    else if (label === switchLanguage.HNG) language = 'HNG';
    else if (label === switchLanguage.RUS) language = 'RUS';

    localStorage.setItem('userLang', language);

    if (language) {
      const updatedUser = { ...user, language };

      setlanguage(language);
      !offlineMode && dispatch(updateUserRequest(updatedUser));
      setShowModal(false);

      setValue(language);
    }
  };

  return (
    <React.Fragment>
      <TouchableInput
        onClick={() => setShowModal(true)}
        value={switchLanguage[value]}
        placeholder={switchLanguage.placeholder}
      />
      <SelectModal
        modalIsOpen={showModal}
        modalHandler={() => setShowModal(false)}
        modalTitle={switchLanguage.title}
        data={[
          { label: switchLanguage.GER },
          { label: switchLanguage.ENG },
          { label: switchLanguage.SP },
          { label: switchLanguage.FRA },
          { label: switchLanguage.ITA },
          { label: switchLanguage.CIN },
          { label: switchLanguage.JAP },
          { label: switchLanguage.KOR },
          { label: switchLanguage.HNG },
          { label: switchLanguage.RUS },
        ]}
        modalName="language"
        selectItemHandler={onItemSelect}
        currentLanguage={switchLanguage[value]}
      />
    </React.Fragment>
  );
};

export default withLanguage(LanguageSwitch);

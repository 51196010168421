import * as React from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button } from './button';
import { Calendar } from './calendar';
import { cn } from 'utilities/utils';
import moment, { Moment } from 'moment';
import { Label } from './label';
import { useLanguage } from 'languages/languageContext';
import { de } from 'date-fns/locale';

type Props = {
  name: string;
  placeholder?: string;
  date: Date | Moment;
  setDate: (c: Date) => any;
  touched?: boolean;
  error?: string;
  fromYear?: number;
  toYear?: number;
};

export const NewDatePicker: React.FC<Props> = ({
  placeholder,
  name,
  date,
  setDate,
  touched,
  error,
  fromYear = 1940,
  toYear = +moment().format('YYYY'),
}) => {
  const { datePicker } = useLanguage();

  // dumbass
  date = moment(date).toDate();

  return (
    <Popover>
      <Label className="ml-3" htmlFor={name}>
        {placeholder}
      </Label>
      <PopoverTrigger asChild>
        <Button id={name} variant={'input'} size={'input'}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            moment(date).format('DD. MMMM YYYY')
          ) : (
            <span className={cn(date ? '' : 'text-muted-foreground')}>{datePicker.selectDateLabel}</span>
          )}
        </Button>
      </PopoverTrigger>
      <div className={cn('min-h-4 w-full ml-3 text-left text-xs text-red-500')}>{error}</div>
      <PopoverContent align="start" className=" w-auto p-0">
        <Calendar
          mode="single"
          captionLayout="dropdown-buttons"
          selected={date}
          onSelect={setDate}
          fromYear={fromYear}
          toYear={toYear}
          locale={de}
        />
      </PopoverContent>
    </Popover>
  );
};

import CircularProgress from '@material-ui/core/CircularProgress';
import { unvoteFreeText, voteFreeText } from 'api/events';
import { FormInput } from 'components/UI/FormInput';
import { useLanguage } from 'languages/languageContext';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store';
import { getEventByIdSuccess } from 'store/actions/events';
import { cn } from 'utilities/utils';

type Props = {
  answerId?: string;
  event: any;
  partId: string;
  value?: string;
};

const FreeTextInput: FC<Props> = (props) => {
  const { answerId, event, partId, value = '' } = props;

  const { eventLabels, btn } = useLanguage();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState(value);

  return (
    <div className="flex flex-row gap-2">
      <div className="w-5/6">
        <FormInput
          placeholder={eventLabels.parts.yourAnswer}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          className="rounded-lg"
          disabled={isLoading || !!answerId}
        />
      </div>
      <button
        className={cn(
          'bg-primary-btn text-white relative top-6 h-10 rounded-lg right-0 z-0 w-1/6 duration-75',
          !answer?.length ? 'bg-gray-300 cursor-default' : 'hover:opacity-50',
          answerId && 'bg-red-500',
        )}
        onClick={async () => {
          setIsLoading(true);
          let res;
          if (answerId) res = await unvoteFreeText(event._id, partId, answerId);
          else res = await voteFreeText(event._id, partId, answer);
          delete res?.organizers;
          dispatch(getEventByIdSuccess({ ...event, ...res }));
          setIsLoading(false);
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress color="inherit" className="mt-1" size={20} />
        ) : answerId ? (
          btn.clearBtn
        ) : (
          btn.sendBtn
        )}
      </button>
    </div>
  );
};

export default FreeTextInput;
